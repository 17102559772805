import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import ChangeUserDetails from '../../components/ChangeUserDetails'

function Profile() {
    const user = useSelector(state => state?.user?.user)
    const dispatch = useDispatch(); // Add dispatch to update user in Redux
    const [updateUserProfile, setUpdateUserProfile] = useState(user);
    const [openEditProfile, setOpenEditProfile] = useState(false); // State to control modal visibility

    const handleUserUpdate = (updatedUser) => {
        // Update the user state in Redux
        dispatch({ type: 'UPDATE_USER', payload: updatedUser });
        setUpdateUserProfile(updatedUser); // Update local state
    };

    useEffect(() => {
       
    }, [user]);

    return (
        <div>
            <div className='mr-9 lg:-ml-64 lg:max-w-7xl px-4 md:col-span-1'>
                <div className='bg-white  h-full  py-2 px-4 flex justify-between items-center'>
                    {/* <h2 className='font-bold text-lg'>All Products</h2> */}
                    <button className='ml-10px p-8px border-red-600 border-2 bg-color-red text-red-600 hover:bg-red-600 hover:text-white transition-all duration-300 ease-in-out h px-3 py-1 rounded-md '
                     onClick={() => {
                        setUpdateUserProfile(user); // Set user details for editing
                        setOpenEditProfile(true); // Open modal
                     }}>
                    Edit My Profile
                </button>
                </div>

                <div className='bg-white  h-full  py-2 px-4 flex justify-between items-center'>
                    <p className='border-b-4 font-bold text-2xl w-full'>Account Overview</p>
                </div>
                <div className='bg-white  h-full  py-2 px-4 flex justify-between items-center'>
                    <div>
                        <div>
                            {/* Removed unnecessary divs and fixed nesting */}
                            <div className='h-80'>
                                <p className='p-6 border text-xl font-semibold '>Account details</p>
                                <div className='border h-36 w-80 p-4'>
                                    <p className='capitalize text-lg font-semibold'>{user?.name}</p>
                                    <p className='text-sm'>{user?.email}</p>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {
        openEditProfile && (
          <ChangeUserDetails 
          onClose={() => setOpenEditProfile(false)} 
          name={updateUserProfile.name}
          email={updateUserProfile.email}
          number={updateUserProfile.number}
          role={updateUserProfile.role}
          userId={updateUserProfile._id}
          callFunc={handleUserUpdate} // Pass the update function
          />
        )
      }
        </div>      
    );
       
}
export default Profile