import React, { useCallback, useContext, useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import SummaryApi from '../common'
import { FaStar } from "react-icons/fa";
import { FaStarHalfAlt } from "react-icons/fa";
import displayGHSCurrency from '../helpers/displayCurrency'
import addToCart from '../helpers/addToCart';
import Context from '../context';
import DOMPurify from 'dompurify'; // Import dompurify
import { FiAlertCircle } from "react-icons/fi";
import ProductReviews from '../components/ProductReviews';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import recordProductView from '../helpers/productService';
import VerticalCardRecomendation from '../components/VerticalCardRecomendation';

const ProductDetails = ({ userId }) => {
  const [data, setData] = useState({
    productName : "",
    brandName : "",
    category : "",
    productImage : [],
    description : "",
    price : "",
    sellingPrice : "",
    colors: [], // Add colors to the state
    reviews: [] // Initialize reviews in the product data
  })
  const [selectedColors, setSelectedColors] = useState([]); // State for selected colors
  const params = useParams()
  const user = useSelector(state => state?.user?.user);
  const [loading, setLoading] = useState(true)
  const productImageLoading = new Array(4).fill(null)
  const [activeImage, setActiveImage] = useState("")

  const [zoomImageCordinates, setZoomImageCordinates] = useState({
    x : 0,
    y : 0,
  })
  const [zoomImage, setZoomImage] = useState(false)

  const {  fetchUserAddToCart } = useContext(Context)

  const navigate = useNavigate()

  const fetchProductDetails = async () => {
    setLoading(true);
    const response = await fetch(SummaryApi.productDetails.url, {
        method: SummaryApi.productDetails.method,
        headers: {
            "content-type": "application/json",
        },
        body: JSON.stringify({
            productId: params?.id // Ensure you're sending the correct product ID
        })
    });
    const dataResponse = await response.json();
    if (dataResponse.success) {
        setData(dataResponse.data); // Set the product data, including reviews
    } else {
        toast.error(dataResponse.message); // Handle error
    }
    setLoading(false);
  }

  

  const calculateAverageRating = (reviews) => {
    if (reviews.length === 0) return 0; // No reviews, return 0
    const totalRating = reviews.reduce((acc, review) => acc + review.rating, 0);
    return totalRating / reviews.length; // Calculate average
  };

  useEffect(() => {
    fetchProductDetails()
  }, [params])

  const handleMouseEnterProduct = (imgURL) => {
    setActiveImage(imgURL)
  }

  const handleZoomImage = useCallback((e) => {
    setZoomImage(true)
    const {left, top, width, height} = e.target.getBoundingClientRect()

    const x = (e.clientX - left) / width
    const y = (e.clientY -top) /height

    setZoomImageCordinates({
      x,
      y,
    })
  },[setZoomImageCordinates])

  const handleLeaveImageZone = () => {
    setZoomImage(false)
  }

  const handleColorChange = (color) => {
    setSelectedColors((prev) => 
      prev.includes(color) ? prev.filter(c => c !== color) : [...prev, color] // Add or remove the clicked color
    );
  };

  const handleAddToCart = async (e) => {
    e.preventDefault();
    fetchUserAddToCart();

    // Check if the product is in stock
    if (!data || !data.stock || data.stock <= 0) { // Check if data is null or stock is not available
        toast.error("This product is currently out of stock.");
        return;
    }

    // Check if colors are required and selected
    if (Array.isArray(data.colors) && data.colors.length > 0) {
        if (selectedColors.length === 0) {
            toast.error("Please select a color before adding to cart.");
            return;
        }
    }

   
    // Call addToCart with the required parameters
    await addToCart(e, data?._id, data?.productName, data?.sellingPrice, data?.productImage[0], selectedColors);
  };

  
  // Ensure productColor is initialized correctly
  const productColor = Array.isArray(data.productColor) ? data.productColor : []; // Ensure productColor is an array

  const handleOnChange = (event) => {
    // Handle the change event here
    // Example: update state or perform an action
  };

  const handleAddReview = async (rating, comment) => {
    const response = await fetch(SummaryApi.addReview.url, {
      credentials: 'include',
      method: 'POST',
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({
        productId: data._id,
        userId: user._id, 
        rating,
        comment
      })
    });
    const result = await response.json();
    if (result.success) {
      // Update the reviews in the state
      setData(prevData => ({
        ...prevData,
        reviews: [...prevData.reviews, result.review] // Add the new review to the existing reviews
      }));
    } else {
      toast.error(result.message);
    }
  };

  const averageRating = calculateAverageRating(data.reviews); // Calculate average rating

  useEffect(() => {
    const handleProductView = async () => {
      try {
        const response = await fetch(SummaryApi.viewProduct.url, {
          method: SummaryApi.viewProduct.method,
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ userId: user._id, productId: params?.id }),
        });

        const result = await response.json();
        if (result.success) {
        } else {
          console.error('Failed to record product view:', result.message);
        }
      } catch (error) {
        console.error('Error recording product view:', error);
      }
    };

    handleProductView(); // Call the function to record the view
  }, [user?._id, params?.id]); // Dependencies to re-run the effect if userId or productId changes

    return (
    <div className='container mx-auto p-4'>

      <div className='min-h-[200px] flex flex-col lg:flex-row gap-4'>
        {/* product image */}
        <div className='h-96 flex flex-col lg:flex-row-reverse gap-4'>

          <div className='h-[300px] w-[300px] lg:h-96 lg:w-96 bg-slate-200 relative p-2'>
            <img src={activeImage} alt="product" className='h-full w-full object-scale-down mix-blend-multiply' onMouseMove={handleZoomImage} onMouseLeave={handleLeaveImageZone}/>

            {/* product image zoom */}
            {
              zoomImage && (
                <div className='hidden lg:block absolute min-w-[500px] overflow-hidden min-h-[400px] bg-slate-200 p-1 -right-[510px] top-0'>
                  <div
                    className='h-full w-full min-h-[400px] min-w-[500px] mix-blend-multiply scale-125'
                    style={{
                      backgroundImage : `url(${activeImage})`,
                      backgroundRepeat : "no-repeat",
                      backgroundPosition : `${zoomImageCordinates.x * 100}% ${zoomImageCordinates.y * 100}%`,
                    }}
                  >

                  </div>
                  
                </div>
              )   
            }
                
          </div>

          <div className='h-full'>
            {
              loading ?(
                <div className='flex gap-2 lg:flex-col overflow-scroll scrollbar-none h-full'>
                  {
                    productImageLoading.map((el,index) => {
                      return(
                        <div className='h-20 w-20 bg-slate-200 rounded animate-pulse' key={"loading image"+index}>
    
                        </div>
                      )
                    })
                  }
                </div>
              ) : (
                <div className='flex gap-2 lg:flex-col overflow-scroll scrollbar-none h-full'>
                  {
                    data?.productImage?.map((imgURL,index) => {
                      return(
                        <div className='h-20 w-20 bg-slate-200 rounded p-1' key={imgURL}>
                          <img src={imgURL} alt="product" className='h-full w-full object-scale-down mix-blend-multiply cursor-pointer' onMouseEnter={() => handleMouseEnterProduct(imgURL)} onClick={() => handleMouseEnterProduct(imgURL)}/>
    
                        </div>
                      )
                    })
                  }
                </div>
              )
            }
          </div>

        </div>

        {/* product details */}
        {
          loading ? (
                <div className='grid w-full gap-1'>
            <p className='bg-slate-200 animate-pulse h-6 lg:h-8 w-full rounded-full inline-block '></p>
            <h2 className='text-2xl lg:text-4xl  w-full font-medium bg-slate-200 animate-pulse h-6 lg:h-8'> </h2>
            <p className='capitalize w-full text-gray-500 bg-slate-200 animate-pulse h-6 lg:h-8  min-w-[100px]'></p>

            <div className='flex items-center w-full bg-slate-200 animate-pulse h-6 lg:h-8 '></div>

            <div className='flex items-center w-full gap-2 text-2xl lg:text-3xl h-6 lg:h-8 animate-pulse font-medium my-1'>
              <p className='text-red-500 w-full font-bold bg-slate-200'></p>
              <p className='text-xl line-through w-full bg-slate-200'></p>
            </div>

            <div className='flex items-center gap-3'>
              <buton className='h-6 lg:h-8 w-full bg-slate-200 rounded animate-pulse'></buton>
              <buton className='h-6 lg:h-8 w-full bg-slate-200 rounded animate-pulse'></buton>
            </div>

            <div>
              <p className='text-slate-600 font-medium my-1 h-6 lg:h-8 bg-slate-200 rounded animate-pulse w-full'></p>
              <p className='h-10 lg:h-12 w-full bg-slate-200 rounded animate-pulse'></p>
            </div>
        </div>
          ) : (
            <div className='flex flex-col gap-1'>
            <p className='bg-red-200 text-red-500 px-2 rounded-full inline-block w-fit'>{data?.brandName}</p>
            <h2 className='text-2xl lg:text-4xl font-medium'>{data?.productName}</h2>
            <p className='capitalize text-gray-500'>{data?.category}</p>
           

            <div className='flex items-center gap-2'>
              {[...Array(5)].map((_, i) => {
                if (i < Math.floor(averageRating)) {
                  return <FaStar key={i} className='text-red-500' />;
                } else if (i === Math.floor(averageRating) && averageRating % 1 !== 0) {
                  return <FaStarHalfAlt key={i} className='text-red-500' />;
                } else {
                  return <FaStar key={i} className='text-gray-300' />;
                }
              })}
              <span className='text-gray-600 ml-2'>({data.reviews.length} reviews)</span>
            </div>

            <div className='flex items-center gap-2 text-2xl lg:text-3xl font-medium my-1'>
              {data?.price && (
                        <p className='text-sm text-slate-500 line-through'>{displayGHSCurrency(data?.price)}</p>
                      )}
                      {data?.sellingPrice && (
                        <p className='text-red-500 font-medium'>{displayGHSCurrency(data?.sellingPrice)}</p>
                      )}
            </div>
             
             <div className='flex items-center gap-2 text-md lg:text-xl font-sm my-1'>
              {
                data?.stock > 0 ? (
                  <p>Available : {data?.stock}</p>
                ) : (
                  <p className='flex items-center gap-2 text-sm'><FiAlertCircle className='text-red-500'/> Product Out of stock</p>
                )
              }
             </div>

             <div className='flex flex-col'>
              <div className='flex flex-wrap gap-2'> {/* Flexbox for horizontal display */}
                {productColor && productColor.length > 0 ? ( // Check if colors exist
                    productColor.map((color) => (
                        <div key={color} className='flex items-center gap-2'>
                            <span
                                onClick={() => handleColorChange(color)}
                                className={`cursor-pointer p-2 rounded ${selectedColors.includes(color) ? 'border-2 border-red-500 bg-gray-200 text-red-500' : 'hover:border-2 hover:border-red-500 bg-gray-200 text-black'}`}
                            >
                                {color}
                            </span>
                        </div>
                    ))
                ) : (
                    <p></p> // Fallback message if no colors
                )}
              </div>
            </div>

            <div className='flex items-center gap-3'>
             <buton className='border-2 border-red-600 rounded px-3 py-1 min-w-[120px] cursor-pointer hover:bg-red-900 hover:text-white transition-all duration-300 text-white bg-red-500 font-medium flex items-center justify-center' onClick={handleAddToCart}>Add to cart</buton>
            </div>

            <div>
              <p className='text-slate-600 font-medium my-1'>Product Description :</p>
              <div className='text-ellipsis line-clamp-3' dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(data?.description)}}></div>
            </div>
        </div>
          )
        }

      </div>
        {/* product details and images */}
      <div className='container mx-auto p-4 mt-4'>
        <div className='lg:grid grid-cols-[600px,1fr]'>
          {/* product details */}
          
          <div className='bg-white p-2 min-h-[calc(100vh-120px)] overflow-y-scroll max-h-[calc(100vh-120px)]'>
            <div className='border p-4 border-slate-400'>
            <p className='font-bold top-0 text-slate-800 text-lg border p-4 border-slate-400'>Product details</p>
              <div dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(data?.productDetailes)}}></div>
            </div>
          </div>

            {/* product images */}
          <div className='px-6 ml-2 border mt-4 lg:mt-0'>
            <div className='min-h-[calc(100vh-120px)] overflow-y-scroll max-h-[calc(100vh-120px)]'>
              {data?.productDetailesImage && data.productDetailesImage.length > 0 ? (
                data.productDetailesImage.map((image, index) => (
                  <img key={index} src={image} alt={`Product Detail ${index + 1}`} className='w-50 h-full justify-center object-cover mb-2' />
                ))
              ) : (
                <p>No image available</p>
              )}
            </div>
          </div>
        </div>
      </div>


      <div className='container mx-auto p-4 mt-4'>
        <ProductReviews reviews={data.reviews.slice(0, 3)} onAddReview={handleAddReview} />
      </div>
      {
        data.category && (
          <VerticalCardRecomendation category={data.category} heading="Recomended Products"/>
        )
      }

    </div>
  )
}

export default ProductDetails
