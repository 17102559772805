import React, { useEffect, useState } from 'react';
import axios from 'axios';
import SummaryApi from '../common';
import './FAQAssistant.css'; // Import CSS for styling
import scrollToBottom from '../helpers/scrollToBottom';

const FAQAssistant = () => {
    const [faqs, setFaqs] = useState([]);
    const [userInput, setUserInput] = useState('');
    const [chatHistory, setChatHistory] = useState([]);
    const [suggestions, setSuggestions] = useState([]);

    useEffect(() => {
        const fetchFAQs = async () => {
            try {
                const response = await axios.get(SummaryApi.getFAQs.url);
                setFaqs(response.data);
            } catch (error) {
                console.error('Error fetching FAQs:', error);
            }
        };

        fetchFAQs();
    }, []);

    const handleUserInput = (e) => {
        const input = e.target.value;
        setUserInput(input);

        // Provide suggestions based on user input
        if (input.trim()) {
            const filteredSuggestions = faqs.filter(faq =>
                faq.question.toLowerCase().includes(input.toLowerCase())
            ).slice(0, 5); // Limit to 5 suggestions
            setSuggestions(filteredSuggestions);
        } else {
            setSuggestions([]);
        }
    };

    const handleSend = () => {
        if (userInput.trim()) {
            const timestamp = new Date().toLocaleTimeString(); // Get current time
            const userMessage = { text: userInput, sender: 'user', time: timestamp }; // Include time
            setChatHistory((prev) => [...prev, userMessage]);

            const matchedFAQ = faqs.find(faq => faq.question.toLowerCase() === userInput.toLowerCase());
            const botMessage = matchedFAQ 
                ? { text: matchedFAQ.answer, sender: 'bot', time: timestamp } // Include time
                : { text: "I'm sorry, I don't have an answer for that.", sender: 'bot', time: timestamp }; // Include time

            setChatHistory((prev) => [...prev, botMessage]);
            setUserInput(''); // Clear input field
            setSuggestions([]); // Clear suggestions
        }
    };

    const handleSuggestionClick = (faq) => {
        const timestamp = new Date().toLocaleTimeString(); // Get current time
        const userMessage = { text: faq.question, sender: 'user', time: timestamp }; // Include time
        setChatHistory((prev) => [...prev, userMessage]);

        const botMessage = { text: faq.answer, sender: 'bot', time: timestamp }; // Include time
        setChatHistory((prev) => [...prev, botMessage]);
        setUserInput(''); // Clear input field
        setSuggestions([]); // Clear suggestions
    };

    return (
        <div className="chat-container">
            <h2>Welcome to the FAQ Assistant!</h2>
            <div className="chat-box">

                <div className="faq-quick-questions">
                    <h4>Quick Questions:</h4>
                    {faqs.slice(0, 5).map((faq, index) => (
                        <div className='' onClick={() => scrollToBottom()}>
                            <div key={index} className="quick-question" onClick={() => handleSuggestionClick(faq)}>
                                {faq.question}
                            </div>
                        </div>
                    ))}
                </div>

                {chatHistory.map((msg, index) => (
                    <>
                    <div key={index} className={`chat-message ${msg.sender}`}>
                        <strong>{msg.sender === 'user' ? 'You' : 'Assistant'}:</strong> {msg.text}
                         
                    </div>
                    <div className={`timestamp ${msg.sender === 'user' ? 'text-blue-500 flex justify-end' : 'text-green-500 flex justify-start'}`}>{msg.time}</div>
                    </>
                ))}
                {suggestions.length > 0 && (
                    <div className="suggestions">
                        <h4>Suggestions:</h4>
                        {suggestions.map((faq, index) => (
                            <div key={index} className="suggestion" onClick={() => handleSuggestionClick(faq)}>
                                {faq.question}
                            </div>
                        ))}
                    </div>
                )}
            </div>

            
            <div className="input-container ">
                <input
                    type="text"
                    value={userInput}
                    onChange={handleUserInput}
                    onKeyDown={(e) => e.key === 'Enter' && handleSend()} // Updated from onKeyPress to onKeyDown
                    placeholder="Ask a question..."
                />
                <button onClick={handleSend}>Send</button>
            </div>
            
        </div>
    );
};

export default FAQAssistant;
