import React, { useState } from 'react';

const FilterSidebar = ({ categoryFilter, setCategoryFilter, sortOption, setSortOption, productCategory, minPrice, setMinPrice, maxPrice, setMaxPrice }) => {
    return (
        <div className="w-full mr-2 p-4 border-r bg-white">
            <h3 className="font-medium mb-1">Sort By</h3>
            <div className="flex items-center mb-2">
                <input
                    type="radio"
                    value="priceAsc"
                    checked={sortOption === 'priceAsc'}
                    onChange={(e) => setSortOption(e.target.value)}
                    className="mr-2"
                />
                <label>Price: Low to High</label>
            </div>
            <div className="flex items-center mb-2">
                <input
                    type="radio"
                    value="priceDesc"
                    checked={sortOption === 'priceDesc'}
                    onChange={(e) => setSortOption(e.target.value)}
                    className="mr-2"
                />
                <label>Price: High to Low</label>
            </div>
            <div className="flex items-center mb-2">
                <input
                    type="radio"
                    value="newest"
                    checked={sortOption === 'newest'}
                    onChange={(e) => setSortOption(e.target.value)}
                    className="mr-2"
                />
                <label>Newest First</label>
            </div>
            <div className="flex items-center mb-2">
                <input
                    type="radio"
                    value="alphabetical"
                    checked={sortOption === 'alphabetical'}
                    onChange={(e) => setSortOption(e.target.value)}
                    className="mr-2"
                />
                <label>Alphabetical Order</label>
            </div>

            <h2 className="font-semibold text-lg mb-2">Filter By Price Range</h2>
            <div className="flex mb-2">
                <input
                    type="number"
                    placeholder="Min Price"
                    value={minPrice}
                    onChange={(e) => setMinPrice(e.target.value)}
                    className="border p-1 mr-2 w-24"
                />
                <input
                    type="number"
                    placeholder="Max Price"
                    value={maxPrice}
                    onChange={(e) => setMaxPrice(e.target.value)}
                    className="border p-1 w-24"
                />
            </div>

            <h2 className="font-semibold text-lg mb-2">Filter By Categories</h2>
            {productCategory.map((category) => (
                <div key={category.id} className="flex items-center w-full mb-2">
                    <input
                        type="checkbox"
                        value={category.value}
                        checked={categoryFilter === category.value}
                        onChange={(e) => setCategoryFilter(e.target.checked ? category.value : '')}
                        className="mr-2"
                    />
                    <label>{category.label}</label>
                </div>
            ))}
        </div>
    );
};

export default FilterSidebar;
