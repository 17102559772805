import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SummaryApi from '../common';
import { MdEdit } from "react-icons/md";
import { motion } from 'framer-motion';
import displayGHSCurrency from '../helpers/displayCurrency';
import loadingGif from '../assest/loading.gif';

const AdminOrders = () => {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(false);
  const [expandedOrderId, setExpandedOrderId] = useState(null); // State to track expanded order
  const [sortOrder, setSortOrder] = useState('desc'); // State to track sorting order
  const [searchQuery, setSearchQuery] = useState(''); // State to track search query
  const [statusFilter, setStatusFilter] = useState(''); // State to track selected order status
  const [startDate, setStartDate] = useState(''); // State to track start date
  const [endDate, setEndDate] = useState(''); // State to track end date
  const [currentPage, setCurrentPage] = useState(1); // State to track the current page
  const ordersPerPage = 10; // Number of orders to display per page
  const [selectedOrders, setSelectedOrders] = useState([]); // State to track selected orders

  useEffect(() => {
    fetchCompletedOrders();
  }, []);

  const fetchCompletedOrders = async () => {
    setLoading(true);
    try {
      const response = await fetch(SummaryApi.getCompletedOrders.url, {
        credentials: "include",
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      if (data.success) {
        setOrders(data.data);
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      console.error("Error fetching orders:", error);
      toast.error('Failed to fetch orders.');
    } finally {
      setLoading(false);
    }
  };

  const updateOrderStatus = async (orderId, newStatus) => {
    try {
      const response = await fetch(SummaryApi.updateOrderStatus.url, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ orderId, newStatus })
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      if (data.success) {
        setOrders(orders.map(order => order._id === orderId ? data.data : order));
        toast.success('Order status updated successfully.');
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      console.error("Error updating order status:", error);
      toast.error('Failed to update order status.');
    }
  };

  const toggleOrderDetails = (orderId) => {
    setExpandedOrderId(expandedOrderId === orderId ? null : orderId);
  };

  const sortOrdersByDate = () => {
    const sortedOrders = [...orders].sort((a, b) => {
      const dateA = new Date(a.createdAt);
      const dateB = new Date(b.createdAt);
      return sortOrder === 'asc' ? dateA - dateB : dateB - dateA;
    });
    setOrders(sortedOrders);
    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
  };

  const filteredOrders = orders.filter(order => {
    const matchesStatus = statusFilter ? order.orderStatus === statusFilter : true;
    const matchesDateRange = (!startDate || new Date(order.createdAt) >= new Date(startDate)) &&
                              (!endDate || new Date(order.createdAt) <= new Date(endDate));
    
    return (order.orderNumber.includes(searchQuery) || 
            (order.customerName && order.customerName.toLowerCase().includes(searchQuery.toLowerCase()))) &&
            matchesStatus &&
            matchesDateRange;
  });

  const totalPages = Math.ceil(filteredOrders.length / ordersPerPage);
  const indexOfLastOrder = currentPage * ordersPerPage;
  const indexOfFirstOrder = indexOfLastOrder - ordersPerPage;
  const currentOrders = filteredOrders.slice(indexOfFirstOrder, indexOfLastOrder);

  const handleSelectOrder = (orderId) => {
    setSelectedOrders(prevSelected => 
      prevSelected.includes(orderId) 
        ? prevSelected.filter(id => id !== orderId) 
        : [...prevSelected, orderId]
    );
  };

  const bulkUpdateStatus = async (newStatus) => {
    try {
      const responses = await Promise.all(selectedOrders.map(orderId => 
        fetch(SummaryApi.updateOrderStatus.url, {
          method: 'POST',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ orderId, newStatus })
        })
      ));

      const updatedOrders = await Promise.all(responses.map(res => res.json()));
      setOrders(orders.map(order => {
        const updatedOrder = updatedOrders.find(updated => updated.data._id === order._id);
        return updatedOrder ? updatedOrder.data : order;
      }));
      toast.success('Selected orders updated successfully.');
      setSelectedOrders([]); // Clear selection after update
    } catch (error) {
      console.error("Error updating order statuses:", error);
      toast.error('Failed to update selected orders.');
    }
  };

  return (
    <div className="container mx-auto p-4 bg-white rounded-md">
      <h1 className="text-3xl font-bold mb-4">All Orders</h1>
      
      {/* Search Input */}
      <input
        type="text"
        placeholder="Search by Order Number or Customer Name"
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        className="border p-2 mb-4 w-full"
      />

      {/* Filter Options */}
      <div className="flex mb-4">
        <select
          value={statusFilter}
          onChange={(e) => setStatusFilter(e.target.value)}
          className="border p-2 mr-2"
        >
          <option value="">All Statuses</option>
          <option value="Pending">Pending</option>
          <option value="InTransit">In Transit</option>
          <option value="Delivered">Delivered</option>
        </select>

        <input
          type="date"
          value={startDate}
          onChange={(e) => setStartDate(e.target.value)}
          className="border p-2 mr-2"
        />
        <input
          type="date"
          value={endDate}
          onChange={(e) => setEndDate(e.target.value)}
          className="border p-2"
        />
      </div>

      {/* Bulk Actions */}
      <div className="flex mb-4">
        <button
          onClick={() => bulkUpdateStatus('Delivered')}
          disabled={selectedOrders.length === 0}
          className="bg-green-500 text-white px-4 py-2 rounded mr-2"
        >
          Mark as Delivered
        </button>
        <button
          onClick={() => bulkUpdateStatus('InTransit')}
          disabled={selectedOrders.length === 0}
          className="bg-blue-500 text-white px-4 py-2 rounded"
        >
          Mark as In Transit
        </button>
      </div>

      {/* Display Current Orders */}
      <div className="grid grid-cols-1 gap-4">
        {currentOrders.map(order => (
          <motion.div
            key={order._id}
            className="border p-4 rounded shadow-lg"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
          >
            <input
              type="checkbox"
              checked={selectedOrders.includes(order._id)}
              onChange={() => handleSelectOrder(order._id)}
            />
            <h3 className="text-lg font-bold">Order ID: {order.orderNumber}</h3>
            <p>Total Amount: {displayGHSCurrency(order.totalAmount)}</p>
            <p>Date: {new Date(order.createdAt).toLocaleDateString()}</p>
            <button
              onClick={() => toggleOrderDetails(order._id)}
              className="bg-gray-500 text-white px-4 py-2 rounded mr-2 transition duration-300 ease-in-out transform hover:scale-105"
            >
              {expandedOrderId === order._id ? 'Hide Details' : 'View All'}
            </button>
            {expandedOrderId === order._id && (
              <div className="mt-4 bg-white p-4 rounded-md">
                <h4 className="font-bold">Order Details:</h4>
                {order.products.map(product => (
                  <div key={product.productId} className="flex items-center mb-2 bg-white p-4 rounded-md border border-gray-200">
                    <img src={product.productImage} alt={product.productName} className="w-16 h-16 object-cover mr-2" />
                    <div>
                      <p>Name: {product.productName}</p>
                      <p>Color: {product.productColor ? product.productColor.join(', ') : 'As seen'}</p>
                      <p>Quantity: {product.quantity}</p>
                    </div>
                  </div>
                ))}
                <p>Shipping Address: {order.shippingAddress.map(address => (
                  <div key={address.deliveryAddressId}>
                    <p>{address.contactName}</p>
                    <p>{address.streetAddress}, {address.town}, {address.region}</p>
                    <p>Mobile: {address.mobile1}</p>
                  </div>
                ))}</p>
                <p>Order Status: {order.orderStatus}</p>
                <p>Payment Status: {order.paymentStatus}</p>
                <button
                  onClick={() => updateOrderStatus(order._id, 'InTransit')}
                  className="bg-blue-500 text-white px-4 py-2 rounded mr-2 transition duration-300 ease-in-out transform hover:scale-105"
                >
                  Mark as In Transit
                </button>
                <button
                  onClick={() => updateOrderStatus(order._id, 'Delivered')}
                  className="bg-green-500 text-white px-4 py-2 rounded transition duration-300 ease-in-out transform hover:scale-105"
                >
                  Mark as Delivered
                </button>
              </div>
            )}
          </motion.div>
        ))}
      </div>

      {/* Pagination Controls */}
      <div className="flex justify-between mt-4">
        <button
          onClick={() => setCurrentPage(currentPage - 1)}
          disabled={currentPage === 1}
          className="bg-gray-500 text-white px-4 py-2 rounded"
        >
          Previous
        </button>
        <span>Page {currentPage} of {totalPages}</span>
        <button
          onClick={() => setCurrentPage(currentPage + 1)}
          disabled={currentPage === totalPages}
          className="bg-gray-500 text-white px-4 py-2 rounded"
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default AdminOrders;