import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom'; // Import useNavigate
import displayGHSCurrency from '../helpers/displayCurrency';
import { PaystackButton } from 'react-paystack';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import SummaryApi from '../common';

const PaymentPage = () => {
    const location = useLocation(); // Get location object
    const navigate = useNavigate(); // Initialize navigate
    // const cartItems = useSelector(state => state?.cart?.items || []); // Safely access cartItems
    const { totalPrice, orderNumber, products } = location.state || { totalPrice: 0, orderNumber: null, products: [] }; // Provide default values
    const publickey = "pk_live_9fd6e426dbf8497f25f782fa9e6b81bb3cfe860b";
    const user = useSelector(state => state?.user?.user);
    const [isProcessing, setIsProcessing] = useState(false);
    const [shippingInfo, setShippingInfo] = useState({
        contactName: '',
        streetAddress: '',
        mobile1: '',
        mobile2: '',
        region: '', // Add region
        district: '', // Add district
        town: '', // Add town
    });

    useEffect(() => {
        fetchShippingInfo();
    }, []);

    const fetchShippingInfo = async () => {
        try {
            const response = await fetch(SummaryApi.getDeliveryAddress.url, {
                method: 'GET',
                credentials: 'include',
                headers: {
                    'content-type': 'application/json',
                },
            });

            const responseData = await response.json();
            if (responseData.success) {
                const { contactName, streetAddress, mobile1, mobile2, region, district, town } = responseData.data;
                setShippingInfo({
                    contactName,
                    streetAddress,
                    mobile1,
                    mobile2,
                    region, // Set region
                    district, // Set district
                    town, // Set town
                });
            } else {
                console.error("Failed to fetch shipping info:", responseData.message);
            }
        } catch (error) {
            console.error("Error fetching shipping info:", error);
        }
    };

    const handlePaymentSuccess = async () => {
        try {
            const response = await fetch(SummaryApi.updateOrderPaymentStatus.url, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'content-type': 'application/json',
                },
                body: JSON.stringify({ orderNumber, paymentStatus: 'Completed', userId: user._id }), // Include userId
            });

            const responseData = await response.json();
            if (responseData.success) {
                toast.success('Payment status updated successfully!');
                await handleUpdateProductStock(); 
                navigate('/paymentSuccessful', { state: { totalPrice } });
            } else {
                toast.error('Failed to update payment status: ' + responseData.message);
            }
        } catch (error) {
            console.error("Error updating payment status:", error);
            toast.error('An error occurred while updating payment status.');
        }
    };

    const handleUpdateProductStock = async () => {
        try {
            // Iterate through products to update stock for each product
            for (const item of products) {
                const productId = item.productId; // Ensure you are sending the correct product ID
                const quantity = item.quantity || 1; // Send the quantity of the product
                const response = await fetch(SummaryApi.updateProductStock.url, {
                    method: SummaryApi.updateProductStock.method,
                    credentials: 'include',
                    headers: {
                        'content-type': 'application/json',
                    },
                    body: JSON.stringify({
                        productId: productId,
                        quantity: quantity,
                    }),
                });

                const responseData = await response.json();
                if (!responseData.success) {
                    const productName = item.name || 'Unknown Product';
                    toast.error(`Failed to update stock for ${productName}: ${responseData.message}`);
                }
            }
        } catch (error) {
            console.error("Error updating product stock:", error);
            toast.error('An error occurred while updating product stock.');
        }
    };

    const componentProps = {
        publicKey: publickey,
        name: shippingInfo.contactName,
        email: user?.email,
        phone: shippingInfo.mobile1,
        amount: totalPrice * 100,
        currency: "GHS",
        text: `Pay ${displayGHSCurrency(totalPrice)}`,
        onSuccess: async () => {
            toast.success('Payment successful!');
            await handlePaymentSuccess();
            
        },
        onClose: () => {
            toast.error('Payment was not completed.'); // Handle payment cancellation
            
        },
    };

    return (
        <div className='container mx-auto p-4'>
            <h1 className='text-2xl font-bold mb-4'>Payment Page</h1>
            <div className='bg-white p-4 rounded-lg shadow-md flex flex-col items-center justify-center'>
                <h2 className='text-xl font-semibold mb-4'>Total Amount: {displayGHSCurrency(totalPrice)}</h2>
                <h2 className='text-xl font-semibold mb-4'>Contact Name: {shippingInfo.contactName}</h2>
                <h2 className='text-xl font-semibold mb-4'>Email: {user?.email}</h2>
                <h2 className='text-xl font-semibold mb-4'>Phone: {shippingInfo.mobile1}</h2>
                <PaystackButton {...componentProps} className="bg-blue-600 hover:bg-blue-700 text-white p-2 rounded">
                </PaystackButton>
            </div>
        </div>
    );
};

export default PaymentPage;
