import React from 'react'
import { GrClose } from 'react-icons/gr'

const Displayimage = ({
    imgUrl,
    onClose
}) => {
  return (
    <div className='fixed top-0 left-0 w-full h-full bg-black/50 flex justify-center items-center z-50'>

        <div>
        <div className='w-fit ml-auto text-2xl text-red-500 hover:text-red-600 cursor-pointer' onClick={onClose}>
        <GrClose/>
        </div>
        <div className='flex justify-center p-4 max-w-[80vh] max-h-[80vh]'>
            <img src={imgUrl} className='w-full h-full'/>
        </div>
        </div>
    </div>
  )
}

export default Displayimage