import React, { useEffect, useState } from 'react';
import { Bar, Line, Pie } from 'react-chartjs-2';
import { Chart, registerables } from 'chart.js'; // Import Chart and registerables
import axios from 'axios';
import SummaryApi from '../common';
import productCategory from '../helpers/productCategory'; // Import product categories
import { MdPrint } from "react-icons/md";
import * as XLSX from 'xlsx'; // Keep Excel import
import ProductDetailModal from './ProductDetailModal'; // Import the new modal component
import Notification from './Notification'; // Import the Notification component
import { toast } from 'react-toastify';

Chart.register(...registerables); // Register all necessary components

const PurchaseReportCharts = () => {
    const [data, setData] = useState({}); // For purchase counts/percentages
    const [moneyData, setMoneyData] = useState({}); // For total amount gained
    const [period, setPeriod] = useState('daily'); // Default period for purchase data
    const [moneyPeriod, setMoneyPeriod] = useState('daily'); // Default period for money data
    const [filterType, setFilterType] = useState('count'); // State for filter type
    const [startDate, setStartDate] = useState(''); // State for start date
    const [endDate, setEndDate] = useState(''); // State for end date
    const [selectedProductId, setSelectedProductId] = useState(null); // State for selected product ID
    const [showModal, setShowModal] = useState(false); // State to control modal visibility
    const [notification, setNotification] = useState(null); // State for notifications
    const [comparisonPeriod, setComparisonPeriod] = useState('lastMonth'); // Default comparison period
    const [currentData, setCurrentData] = useState({});
    const [previousData, setPreviousData] = useState({});
    const [previousWeekData, setPreviousWeekData] = useState({}); // Add this line
    const [previousMonthData, setPreviousMonthData] = useState({}); // Ensure this is also defined
    const [previousYearData, setPreviousYearData] = useState({}); // Ensure this is also defined

    // Set default start and end dates when the component mounts
    useEffect(() => {
        const today = new Date();
        const last30Days = new Date();
        last30Days.setDate(today.getDate() - 30); // Set to 30 days ago

        setStartDate(last30Days.toISOString().split('T')[0]); // Format to YYYY-MM-DD
        setEndDate(today.toISOString().split('T')[0]); // Format to YYYY-MM-DD
    }, []);

    // Fetch purchase data when component mounts or when dependencies change
    useEffect(() => {
        fetchPurchaseData();
    }, [period, filterType, startDate, endDate]); // Fetch data when period, filterType, startDate, or endDate changes

    // Fetch money data when moneyPeriod or date range changes
    useEffect(() => {
        fetchMoneyData(); // Fetch money data separately
    }, [moneyPeriod, startDate, endDate]); // Fetch money data when moneyPeriod changes

    // Fetch comparison data when the component mounts
    useEffect(() => {
        const fetchComparisonData = async () => {
            try {
                const currentResponse = await axios.get(`${SummaryApi.getPurchaseHistory.url}?period=current`);
                const previousWeekResponse = await axios.get(`${SummaryApi.getPurchaseHistory.url}?period=lastWeek`);
                const previousMonthResponse = await axios.get(`${SummaryApi.getPurchaseHistory.url}?period=lastMonth`);
                const previousYearResponse = await axios.get(`${SummaryApi.getPurchaseHistory.url}?period=lastYear`);

               
                // Assuming the API returns total products purchased in the response
                setCurrentData(currentResponse.data);
                setPreviousWeekData(previousWeekResponse.data);
                setPreviousMonthData(previousMonthResponse.data);
                setPreviousYearData(previousYearResponse.data);
            } catch (error) {
                console.error("Error fetching comparison data:", error);
            }
        };

        fetchComparisonData(); // Call the function to fetch comparison data
    }, []); // Run once on component mount

    const fetchPurchaseData = async () => {
        try {
            const response = await axios.get(`${SummaryApi.getPurchaseHistory.url}?period=${period}&filterType=${filterType}&startDate=${startDate}&endDate=${endDate}`);
            const purchaseData = response.data.data;

            // Prepare data for charts
            const labels = productCategory.map(cat => cat.label.toLowerCase()); // Normalize labels to lowercase
            const chartData = labels.map(label => {
                const count = purchaseData[label]?.count || 0; // Get count or default to 0
                return count; // Return the count for the chart
            });

            // Calculate total count
            const totalCount = chartData.reduce((acc, curr) => acc + curr, 0);

            // Prepare data for chart based on filter type
            const filteredLabels = labels; // Keep all labels
            const filteredChartData = chartData.map(count => {
                if (filterType === 'percentage' && totalCount > 0) {
                    return (count / totalCount) * 100; // Calculate percentage
                }
                return count; // Return count for 'count' filter type
            });

            // Set data for the chart
            setData({
                labels: filteredLabels, // Use all labels
                datasets: [{
                    label: filterType === 'percentage' ? 'Purchase Percentage' : 'Purchase Count',
                    data: filteredChartData, // Use filtered chart data
                    backgroundColor: ['rgba(75, 192, 192, 0.6)', 'rgba(54, 162, 235, 0.6)', 'rgba(255, 206, 86, 0.6)', 'rgba(255, 159, 64, 0.6)', 'rgba(153, 102, 255, 0.6)'], // Customize colors
                }],
            });
        } catch (error) {
            console.error("Error fetching purchase data:", error);
        }
    };

    const fetchMoneyData = async () => {
        try {
            const response = await axios.get(`${SummaryApi.getPurchaseHistory.url}?period=${moneyPeriod}&startDate=${startDate}&endDate=${endDate}`); // Use moneyPeriod for fetching money data

            const moneyData = response.data.totalAmounts; // Assuming totalAmounts is the correct key in the response

            if (Array.isArray(moneyData)) { // Ensure moneyData is an array
                const labels = moneyData.map(item => item.date); // Get all date labels
                const moneyChartData = moneyData.map(item => item.totalAmount); // Get total amounts

                setMoneyData({
                    labels: labels,
                    datasets: [{
                        label: 'Total Amount Gained',
                        data: moneyChartData, // Use the mapped money gained data
                        backgroundColor: 'rgba(54, 162, 235, 0.6)', // Customize color
                    }],
                });
            } else {
                console.error("Unexpected data structure for money data:", moneyData);
                setMoneyData({}); // Reset moneyData if the structure is not as expected
            }
        } catch (error) {
            console.error("Error fetching money data:", error);
        }
    };

    const handleExportExcel = () => {
        const exportData = data.labels.map((label, index) => ({
            Category: label,
            Count: data.datasets[0].data[index] || 0,
        }));

        const worksheet = XLSX.utils.json_to_sheet(exportData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Purchase Report');

        // Show success notification
        setNotification({ message: 'Export successful!', type: 'success' });

        XLSX.writeFile(workbook, 'purchase_report.xlsx');
    };

    const handleCloseNotification = () => {
        setNotification(null); // Clear notification
    };

    const handleExportChart = () => {
        const canvas = document.getElementById('myChart'); // Get the canvas element by ID
        if (canvas) {
            const image = canvas.toDataURL('image/png'); // Convert canvas to image data URL
            const link = document.createElement('a');
            link.href = image;
            link.download = 'chart.png'; // Set the default file name
            document.body.appendChild(link);
            link.click(); // Trigger the download
            document.body.removeChild(link); // Clean up
        } else {
            console.error("Chart canvas not found.");
        }
    };
    

    const handlePrintInvoice = () => {
        const printWindow = window.open('', '_blank');
        printWindow.document.write('<html><head><title>Invoice</title>');
        printWindow.document.write(`
            <style>
                body { font-family: Arial, sans-serif; }
                .invoice-header { background-color: #007bff; color: white; padding: 10px; display: flex; justify-content: space-between; align-items: center; }
                .invoice-content { padding: 20px; }
                .invoice-content h1, .invoice-content h2, .invoice-content h3 { margin: 0; }
                .invoice-content ul { list-style-type: none; padding: 0; }
                .invoice-content li { margin: 5px 0; }
                .table { width: 100%; border-collapse: collapse; }
                .table th, .table td { border: 1px solid #000; padding: 8px; text-align: left; }
                .table th { background-color: #f2f2f2; }
            </style>
        `);
        printWindow.document.write('</head><body>');
        printWindow.document.write(`
            <div class="invoice-header"> 
                <h1>Purchase Report</h1>
                <img src={Logo} alt="logo" className='w-10 h-10' />
            </div>
        `);
        printWindow.document.write('<div class="invoice-content" id="invoice"></div>');
        printWindow.document.write('</body></html>');
        printWindow.document.close();

        // Render the Invoice component into the new window
        const invoiceElement = printWindow.document.getElementById('invoice');
        if (invoiceElement) {
            invoiceElement.innerHTML = `
                <div class='flex flex-col gap-2'>
                    <div class='flex justify-between items-center'>
                        <h2>Purchase Summary</h2>
                    </div>
                    <p>Total Products Purchased: ${data.datasets ? data.datasets[0].data.reduce((acc, curr) => acc + curr, 0) : ''}</p>
                    <p>Total Amount Gained: GHs ${moneyData.datasets ? moneyData.datasets[0].data.reduce((acc, curr) => acc + curr, 0) : ''}</p>
                    <h3>Purchase Data:</h3>
                    <table class='table'>
                        <thead>
                            <tr>
                                <th>Product Category</th>
                                <th>Count</th>
                            </tr>
                        </thead>
                        ${data.labels.map((label, index) => `
                            <tr>
                                <td>${label}</td>
                                <td>${data.datasets[0].data[index]}</td>
                            </tr>
                        `).join('')}
                    </table>
                </div>
            `;
        }
    };
    const handleProductClick = (label) => {
        // Find the product ID based on the label (you may need to adjust this logic)
        const productId = productCategory.find(cat => cat.label.toLowerCase() === label)?.id; // Assuming each category has an id
        if (productId) {
            setSelectedProductId(productId);
            setShowModal(true); // Show the modal
        }
    };

    const closeModal = () => {
        setShowModal(false); // Close the modal
        setSelectedProductId(null); // Reset selected product ID
    };

    // Chart options to control size and appearance
    const pieOptions = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: true,
                position: 'top',
                title: {
                    display: true,
                    text: 'Product Categories', // Add a title to the legend
                },
            },
            tooltip: {
                callbacks: {
                    label: function(tooltipItem) {
                        const label = tooltipItem.label || '';
                        const value = tooltipItem.raw || 0;
                        return `${label}: ${value}`; // Customize tooltip to show label and value
                    }
                }
            }
        },
    };

    const handleComparisonChange = (e) => {
        setComparisonPeriod(e.target.value);
        fetchComparisonData(e.target.value); // Fetch data for the selected comparison period
    };

    const calculatePercentageChange = (current, previous) => {
        if (previous === 0) {
            return current > 0 ? 100 : -100; // Handle division by zero
        }
        if (previous === undefined || current === undefined) {
            return 0; // Return 0% if either value is undefined
        }
        return ((current - previous) / previous) * 100;
    };

    
    const weeklySalesChange = calculatePercentageChange(currentData.weeklySales || 0, previousWeekData.weeklySales || 0);
    const monthlySalesChange = calculatePercentageChange(currentData.monthlySales || 0, previousMonthData.monthlySales || 0);
    const annualSalesChange = calculatePercentageChange(currentData.annualSales || 0, previousYearData.annualSales || 0);

    const fetchComparisonData = async (period) => { // Define the function
        try {
            const response = await axios.get(`${SummaryApi.getPurchaseHistory.url}?period=${period}`);
            setPreviousData(response.data); // Assuming you want to set previous data
        } catch (error) {
            console.error("Error fetching comparison data:", error);
        }
    };

    return (
        <div>
            {notification && (
                <Notification
                    message={notification.message}
                    type={notification.type}
                    onClose={handleCloseNotification}
                />
            )}
            {showModal && <ProductDetailModal productId={selectedProductId} onClose={closeModal} />}
            <div className='flex flex-col bg-white p-4 rounded-lg shadow-md'>
                <h2 className='text-2xl font-bold'>Purchase Report</h2>
                
                <div className='flex justify-between items-center'>
                    <div className='flex gap-2 items-center'>
                        <h2>Period</h2>
                        <select className='border border-gray-300 rounded-md p-2 outline-none' onChange={(e) => setPeriod(e.target.value)} value={period}>
                            <option value="daily">Daily</option>
                            <option value="weekly">Weekly</option>
                            <option value="monthly">Monthly</option>
                            <option value="yearly">Yearly</option>
                        </select>
                    </div>
                    <div className='flex gap-2 items-center'>
                        <h2>Filter</h2>
                        <select className='border border-gray-300 rounded-md p-2 outline-none' onChange={(e) => setFilterType(e.target.value)} value={filterType}>
                            <option value="count">Filter by Count</option>
                            <option value="percentage">Filter by Percentage</option>
                        </select>
                    </div>
                    <div className='flex gap-2 items-center'>
                        <h2>Start Date</h2>
                        <input type="date" className='border border-gray-300 rounded-md p-2 outline-none' onChange={(e) => setStartDate(e.target.value)} value={startDate} />
                    </div>
                    <div className='flex gap-2 items-center'>
                        <h2>End Date</h2>
                        <input type="date" className='border border-gray-300 rounded-md p-2 outline-none' onChange={(e) => setEndDate(e.target.value)} value={endDate} />
                    </div>
                    <div className='flex gap-1 items-center text-white bg-blue-600 hover:bg-blue-700 p-2 rounded'>
                        <button onClick={handlePrintInvoice} className="">
                            Print Report 
                        </button><MdPrint />
                    </div>
                    <div className='flex gap-1 items-center text-white bg-green-600 hover:bg-green-700 p-2 rounded'>
                        <button onClick={handleExportExcel} className="">
                            Export to Excel
                        </button>
                    </div>
                    <div className='flex gap-1 items-center text-white bg-yellow-600 hover:bg-yellow-700 p-2 rounded'>
                        <button onClick={handleExportChart} className="">
                            Export Chart as Image
                        </button>
                    </div>
                </div>
            </div>
            <div className='flex gap-2 mt-4'>
                <div className=' w-60 h-24 bg-white flex flex-col gap-2 p-2 rounded-lg shadow-md'>
                    <h2>Total Products Purchased: {data.datasets ? data.datasets[0].data.reduce((acc, curr) => acc + curr, 0) : 0}</h2> {/* Display total products purchased */}
                </div>
                <div className=' w-60 h-24 bg-white flex flex-col gap-2 p-2 rounded-lg shadow-md'>
                    <h2>Total Number of Products {data.labels ? data.labels.length : ''}</h2>
                </div>
                <div className='w-60 h-24 bg-white flex flex-col gap-2 p-2 rounded-lg shadow-md'>
                <h2>Weekly Sales</h2>
                <p>{currentData.weeklySales || 0} <span>{calculatePercentageChange(currentData.weeklySales || 0, previousWeekData.weeklySales || 0) > 0 ? '↑' : '↓'}</span></p>
                <p>{calculatePercentageChange(currentData.weeklySales || 0, previousWeekData.weeklySales || 0).toFixed(2)}%</p>
            </div>
            <div className='w-60 h-24 bg-white flex flex-col gap-2 p-2 rounded-lg shadow-md'>
                <h2>Monthly Sales</h2>
                <p>{currentData.monthlySales} <span>{calculatePercentageChange(currentData.monthlySales, previousMonthData.monthlySales) > 0 ? '↑' : '↓'}</span></p>
                <p>{calculatePercentageChange(currentData.monthlySales, previousMonthData.monthlySales).toFixed(2)}%</p>
            </div>
            <div className='w-60 h-24 bg-white flex flex-col gap-2 p-2 rounded-lg shadow-md'>
                <h2>Annual Sales</h2>
                <p>{currentData.annualSales} <span>{calculatePercentageChange(currentData.annualSales, previousYearData.annualSales) > 0 ? '↑' : '↓'}</span></p>
                <p>{calculatePercentageChange(currentData.annualSales, previousYearData.annualSales).toFixed(2)}%</p>
            </div>
                <div>
                <label>Compare with:</label>
                <select value={comparisonPeriod} onChange={handleComparisonChange}>
                    <option value="lastMonth">Last Month</option>
                    <option value="lastYear">Last Year</option>
                    {/* Add more options as needed */}
                </select>
            </div>
            </div>
            
            {data.labels && data.labels.length > 0 ? (
                <>
                <div className='flex gap-1 w-full justify-between mt-6'>
                    <div className='w-2/3 bg-white rounded-lg items-center flex justify-center shadow-md'>
                        <div style={{ width: '100%', height: '400px' }} onClick={() => handleProductClick(selectedProductId ? productCategory.find(cat => cat.id === selectedProductId).label : data.labels[0])}> {/* Click handler for the chart */}
                            <Bar id='myChart' data={data} options={pieOptions} /> {/* Render the Bar chart once */}
                        </div>
                    </div>
                    <div className='w-1/3 right-0'>
                        <div className='bg-white rounded-lg items-center flex justify-center shadow-md'>
                        <div style={{ width: '90%', height: '400px' }}>
                            <Pie data={data} options={pieOptions} />
                        </div>
                    </div>
                    </div>
                </div>
                </>
            ) : (
                <p>No data available for the selected period.</p>
            )}
            
            <div className='flex flex-col bg-slate-100 p-4 rounded-lg shadow-md mt-10'>
                <h2 className='text-2xl font-bold'>Total Amount Gained Report</h2>
                <div className='flex justify-between items-center'>
                    <h2>Total Amount Gained {moneyData.labels ? moneyData.labels[0] : ''}</h2>
                    <h2>Total Amount: {moneyData.datasets ? moneyData.datasets[0].data.reduce((acc, curr) => acc + curr, 0) : ''}</h2> {/* Sum of all amounts */}
                
                    <select className='border border-gray-300 rounded-md p-2 outline-none' onChange={(e) => setMoneyPeriod(e.target.value)} value={moneyPeriod}>
                        <option value="daily">Daily</option>
                        <option value="weekly">Weekly</option>
                        <option value="monthly">Monthly</option>
                        <option value="yearly">Yearly</option>
                    </select>
                </div>
            </div>
            {moneyData.labels && moneyData.labels.length > 0 ? (
                <>
                    <div style={{ width: '100%', height: '400px' }}> {/* Set desired width and height for money gained chart */}
                        <Bar data={moneyData} options={pieOptions} />
                    </div>
                    <div className='flex justify-center items-center'>
                        <div style={{ width: '500px', height: '500px' }}> 
                            <Line data={moneyData} options={pieOptions} />
                        </div>
                    </div>
                </>
            ) : (
                <p>No money data available for the selected period.</p>
            )}
           
        </div>
    );
};

export default PurchaseReportCharts;
