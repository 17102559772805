import React, { useContext, useEffect, useRef, useState } from 'react'
import fetchCategoryWiseProduct from '../helpers/fetchCategoryWiseProduct'
import displayGHSCurrency from '../helpers/displayCurrency'
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import addToCart from '../helpers/addToCart'
import Context from '../context'

const HorizontalCardProduct = ({category, heading}) => {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const loadingList = new Array(13).fill(null)

  const [scroll, setScroll] = useState(0)
  const scrollElement = useRef()
  const [showButtons, setShowButtons] = useState({ left: false, right: false });

  const { fetchUserAddToCart } = useContext(Context)

  const handleAddToCart = async (e,id) => {
    await addToCart(e,id)
    fetchUserAddToCart()
  }

  const fetchData = async () => {
    setLoading(true)
    const categoryProduct = await fetchCategoryWiseProduct(category)
    setLoading(false)
    setData(categoryProduct?.data)
  }

  useEffect(() => {
    fetchData()
  },[])

  const scrollRight = () => {
    scrollElement.current.scrollLeft += 300
  }

  const scrollLeft = () => {
    scrollElement.current.scrollLeft -= 300
  }

  const handleMouseEnter = (position) => {
    if (position === 'left') {
      setShowButtons({ left: true, right: false });
    } else if (position === 'right') {
      setShowButtons({ left: false, right: true });
    }
  };

  const handleMouseLeave = () => {
    setShowButtons({ left: false, right: false });
  };

  return (
    <div data-aos="fade-up" className='container mx-auto bg-white px-4 my-6 relative' 
         onMouseLeave={handleMouseLeave}
         onMouseEnter={(e) => handleMouseEnter(e.clientX < window.innerWidth / 2 ? 'left' : 'right')}
    >

      <h2 className='text-2xl font-bold py-4'>{heading}</h2>

      <div className='flex items-center gap-4 md:gap-6 overflow-scroll scrollbar-none transition-all duration-300' ref={scrollElement}>
        {showButtons.left && (
          <button style={{background: 'rgba(141, 142, 142, 0.5)'}} className=' shadow-md rounded-full p-1 absolute left-0 text-lg text-white  h-10 w-10 hidden md:block' onClick={scrollLeft}>
            <FaAngleLeft className='ml-2'/>
          </button>
        )}
        {showButtons.right && (
          <button style={{background: 'rgba(141, 142, 142, 0.5)'}} className=' shadow-md rounded-full p-1 absolute right-0 text-lg text-white  h-10 w-10 hidden md:block' onClick={scrollRight}>
            <FaAngleRight className='ml-2'/>
          </button>
        )}

        {
          loading ? (
            loadingList.map((_, index) => (
              <div key={index} className='w-full min-w-[280px] md:max-w-[320px] max-w-[280px] md:min-w-[320px] bg-white rounded-sm shadow '>
                <div className='bg-slate-200 h-48 p-4 min-w-[120px] md:min-w-[145px] flex items-center justify-center animate-pulse'></div>
                <div className='p-4 grid gap-3'>
                  <h2 className='text-base md:text-lg font-medium text-ellipsis line-clamp-1 text-black p-1 py-2 animate-pulse rounded-full bg-slate-200 w-full'> </h2>
                  <p className='capitalize text-sm text-slate-500 p-1 py-2 animate-pulse rounded-full bg-slate-200 w-full'></p>
                  <div className='flex gap-3'>
                    <p className='text-sm text-slate-500 line-through p-1 py-2 animate-pulse rounded-full bg-slate-200 w-full'></p>
                    <p className='text-red-500 font-medium p-1 py-2 animate-pulse rounded-full bg-slate-200 w-full'></p>
                  </div>
                  <button className='text-white px-3 rounded-full text-sm animate-pulse bg-slate-200 w-full'></button>
                </div>
              </div>
            ))
          ) : (
            data.map((product, index) => {
              return (
                <Link key={product?._id} to={"product/" + product?._id} className='w-full min-w-[280px] md:max-w-[320px] max-w-[280px] md:min-w-[320px] h-36 mb-4 bg-white rounded-sm shadow-lg hover:shadow-2xl transition-all duration-300 flex'>
                  <div className='bg-slate-200 h-full p-4 min-w-[120px] md:min-w-[145px]'>
                    <img src={product.productImage[0]} className='object-scale-down h-full w-full hover:scale-110 transition-all duration-300' alt="no product" />
                  </div>
                  <div className='p-4 grid'>
                    <h2 className='text-base md:text-lg font-medium text-ellipsis line-clamp-1 text-black'>{product?.productName}</h2>
                    <p className='capitalize text-sm text-gray-500'>{product?.category}</p>
                    <div className='lg:flex gap-2'>
                      {product?.price && (
                        <p className='text-sm text-slate-500 line-through'>{displayGHSCurrency(product?.price)}</p>
                      )}
                      {product?.sellingPrice && (
                        <p className='text-red-500 font-medium'>{displayGHSCurrency(product?.sellingPrice)}</p>
                      )}
                    </div>
                    <button className='bg-red-600 hover:bg-red-700 text-white px-3 py-0.5 rounded-md text-sm' onClick={(e) => handleAddToCart(e, product?._id)}>View Details</button>
                  </div>
                </Link>
              )
            })
          )
        }
      </div>
    </div>
  )
}

export default HorizontalCardProduct
