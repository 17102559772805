import React, { useEffect, useState } from 'react'
import { FaRegUserCircle } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { Link, Outlet, useNavigate, NavLink } from 'react-router-dom';
import ROLE from '../common/role';



const AdminPanel = () => {
    const user = useSelector(state => state?.user?.user)
    const navigate = useNavigate()
    const [loading, setLoading] = useState(true) // Add loading state

    useEffect(() => {
        // Check if user is defined and loading is complete
        if (user) {
            if (user.role !== ROLE.ADMIN) {
                navigate("/")
            }
            setLoading(false) // Set loading to false once user is checked
        } else {
            // If user is not defined, you might want to handle it differently
            // For example, you could navigate to a login page or show a loading spinner
            setLoading(false) // Set loading to false if user is not found
        }
    }, [user, navigate])

    // Show a loading message or spinner while loading
    if (loading) {
        return <div>Loading...</div> // You can replace this with a spinner or loading component
    }

    return (
        <div className='min-h-[calc(100vh-120px)] flex'>
            <aside className='bg-white h-[calc(100vh-70px)] overflow-y-scroll scrollbar-none w-full max-w-60 customShadow'> {/* Fixed sidebar */}
                <div className='h-32 flex bg-slate-100 justify-center items-center flex-col'>
                    <div className='text-5xl cursor-pointer relative flex justify-center'>
                    {
                        user?.profilePic ? (
                        <img src={user?.profilePic} alt={user?.name} className='w-20 h-20 rounded-full' />
                        ) : (
                        <FaRegUserCircle/>
                        )
                    }

                    </div>
                    <div>
                        <p className='capitalize text-lg font-semibold'>{user?.name}</p>
                        <p className='text-sm'>{user?.role}</p>
                    </div>
                </div>

                    {/* navigation */}
                <div>
                    <nav className='grid p-4'>
                        <NavLink to={"all-users"} className={({ isActive }) => `px-2 py-1 rounded-md ${isActive ? 'bg-slate-200' : 'hover:bg-slate-100'}`}>All Users</NavLink>
                        <NavLink to={"all-products"} className={({ isActive }) => `px-2 py-1 rounded-md ${isActive ? 'bg-slate-200' : 'hover:bg-slate-100'}`}>All Products</NavLink>
                        <NavLink to={"all-orders"} className={({ isActive }) => `px-2 py-1 rounded-md ${isActive ? 'bg-slate-200' : 'hover:bg-slate-100'}`}>All Orders</NavLink>
                        <NavLink to={"all-banners"} className={({ isActive }) => `px-2 py-1 rounded-md ${isActive ? 'bg-slate-200' : 'hover:bg-slate-100'}`}>All Banners</NavLink>
                        <NavLink to={"add-promo-code"} className={({ isActive }) => `px-2 py-1 rounded-md ${isActive ? 'bg-slate-200' : 'hover:bg-slate-100'}`}>Add Promo Code</NavLink>
                        <NavLink to={"sales-report"} className={({ isActive }) => `px-2 py-1 rounded-md ${isActive ? 'bg-slate-200' : 'hover:bg-slate-100'}`}>Sales Report</NavLink>
                        <NavLink to={"all-return-request"} className={({ isActive }) => `px-2 py-1 rounded-md ${isActive ? 'bg-slate-200' : 'hover:bg-slate-100'}`}>All Return Request</NavLink>
                    </nav>
                </div>
            </aside>

            <main className='w-full p-2 overflow-y-auto'> {/* Add margin-left to avoid overlap and make it scrollable */}
                <Outlet />
            </main>
        </div>
    )
}

export default AdminPanel
