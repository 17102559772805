import logo from './logo.svg';
// import FLogo from './FLogo.svg';
import './App.css';
import { Outlet } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useEffect, useState } from 'react';
import SummaryApi from './common';
import Context from './context';
import { useDispatch } from 'react-redux';
import { setUserDetails } from './store/userSlice';
import FAQAssistant from './components/FAQAssistant';
import { IoIosChatboxes } from "react-icons/io";
import Aos from 'aos';
import 'aos/dist/aos.css';
import ScrollToTop from './ScrollToTop'; // Import the ScrollToTop component

function App() {
  const dispatch = useDispatch();
  const [cartProductCount, setCartProductCount] = useState(0);
  const [isFAQVisible, setIsFAQVisible] = useState(false); // State to manage FAQ visibility
  const [theme, setTheme] = useState('light'); // Default theme

  useEffect(() => {
    const savedTheme = localStorage.getItem('theme');
    if (savedTheme) {
      setTheme(savedTheme); // Load saved theme from local storage
    }
  }, []);

  const toggleTheme = () => {
    const newTheme = theme === 'light' ? 'dark' : 'light';
    setTheme(newTheme);
    localStorage.setItem('theme', newTheme); // Save theme preference
  };

  useEffect(() => {
    Aos.init({
      duration: 800,
      easing: 'ease-in-sine',
      delay: 100,
      offset: 100,
    });
    Aos.refresh();
  }, []);

  const fetchUserDetails = async () => {
    const dataResponse = await fetch(SummaryApi.current_user.url, {
      method: SummaryApi.current_user.method,
      credentials: "include"
    });

    const dataApi = await dataResponse.json();

    if (dataApi.success) {
      dispatch(setUserDetails(dataApi.data));
    }
  };

  const fetchUserAddToCart = async () => {
    const dataResponse = await fetch(SummaryApi.addToCartProductCount.url, {
      method: SummaryApi.addToCartProductCount.method,
      credentials: "include"
    });

    const dataApi = await dataResponse.json();

    setCartProductCount(dataApi?.data?.count);
  };

  useEffect(() => {
    fetchUserDetails();
    fetchUserAddToCart();
  }, []);

  return (
    <>
      <Context.Provider value={{
        fetchUserDetails,
        cartProductCount,
        fetchUserAddToCart
      }}>
        <ToastContainer position='top-center' />
        <Header toggleTheme={toggleTheme} theme={theme} /> {/* Pass theme and toggle function to Header */}
        <ScrollToTop /> {/* Include the ScrollToTop component here */}
        <main className={`min-h-[calc(100vh-120px)]   pt-16 ${theme}`}> {/* Apply theme class */}
          <Outlet />
        </main>
        <Footer />
        
        {/* Chat Icon */}
        <div className="chat-icon" onClick={() => setIsFAQVisible(!isFAQVisible)}>
          <IoIosChatboxes className='text-2xl text-blue-700 bg-transparent h-10 w-10 hover:scale-110 hover:shadow-md transition-all duration-300 rounded-full' />
        </div>

        {/* FAQ Assistant Pop-Up */}
        {isFAQVisible && (
          <div className="faq-popup">
            <button className="close-faq" onClick={() => setIsFAQVisible(false)}>X</button>
            <FAQAssistant />
          </div>
        )}
      </Context.Provider>
    </>
  );
}

export default App;
