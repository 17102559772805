import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import SummaryApi from '../common';
import loadingGif from '../assest/loading.gif';
import VerticalCard from '../components/VerticalCard';
import FilterSidebar from '../components/FilterSidebar'; // Import the FilterSidebar
import productCategory from '../helpers/productCategory';

const SearchProduct = () => {
    const query = useLocation();
    const [products, setProducts] = useState([]);
    const [uniqueTags, setUniqueTags] = useState([]);
    const [loading, setLoading] = useState(false);
    const [categoryFilter, setCategoryFilter] = useState(''); // State for category filter
    const [sortOption, setSortOption] = useState(''); // State for sorting option
    const [minPrice, setMinPrice] = useState(''); // State for minimum price
    const [maxPrice, setMaxPrice] = useState(''); // State for maximum price

    const fetchProduct = async () => {
        setLoading(true);
        try {
            const response = await fetch(SummaryApi.searchProduct.url + query.search);
            const dataResponse = await response.json();

            if (dataResponse && dataResponse.success) {
                let filteredProducts = dataResponse.data.products;

                // Apply category filter
                if (categoryFilter) {
                    filteredProducts = filteredProducts.filter(product => product.category === categoryFilter);
                }

                // Apply price range filter
                if (minPrice) {
                    filteredProducts = filteredProducts.filter(product => product.sellingPrice >= minPrice);
                }
                if (maxPrice) {
                    filteredProducts = filteredProducts.filter(product => product.sellingPrice <= maxPrice);
                }

                // Apply sorting
                if (sortOption === 'priceAsc') {
                    filteredProducts.sort((a, b) => a.sellingPrice - b.sellingPrice);
                } else if (sortOption === 'priceDesc') {
                    filteredProducts.sort((a, b) => b.sellingPrice - a.sellingPrice);
                } else if (sortOption === 'newest') {
                    filteredProducts.sort((a, b) => new Date(b.dateAdded) - new Date(a.dateAdded));
                } else if (sortOption === 'alphabetical') {
                    filteredProducts.sort((a, b) => a.productName.localeCompare(b.productName));
                }

                setProducts(filteredProducts);
                setUniqueTags(dataResponse.data.uniqueTags);
            } else {
                setProducts([]);
                setUniqueTags([]);
            }
        } catch (error) {
            console.error('Error fetching products:', error);
            setProducts([]);
            setUniqueTags([]);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchProduct();
    }, [query, categoryFilter, sortOption, minPrice, maxPrice]); // Add minPrice and maxPrice to dependencies

    return (
        <div className='container mx-auto p-4 flex flex-col md:flex-row '>
            <div className="hidden md:block">
                <FilterSidebar
                    categoryFilter={categoryFilter}
                    setCategoryFilter={setCategoryFilter}
                    sortOption={sortOption}
                    setSortOption={setSortOption}
                    productCategory={productCategory}
                    minPrice={minPrice}
                    setMinPrice={setMinPrice}
                    maxPrice={maxPrice}
                    setMaxPrice={setMaxPrice}
                />
            </div>

            <div className="flex-1 bg-white ml-6">
                <p className='font-semibold text-lg my-3 ml-6'>Shop All Products ({products.length} products found)</p>

                {/* Mobile Filters and Sorting */}
                <div className="block md:hidden mb-4">
                    <select
                        value={categoryFilter}
                        onChange={(e) => setCategoryFilter(e.target.value)}
                        className="border p-2 mr-2"
                    >
                        <option value="">All Categories</option>
                        {productCategory.map((category) => (
                            <option key={category.id} value={category.value}>
                                {category.label}
                            </option>
                        ))}
                    </select>

                    <select
                        value={sortOption}
                        onChange={(e) => setSortOption(e.target.value)}
                        className="border p-2"
                    >
                        <option value="">Sort By</option>
                        <option value="priceAsc">Price: Low to High</option>
                        <option value="priceDesc">Price: High to Low</option>
                        <option value="newest">Newest First</option>
                        <option value="alphabetical">Alphabetical Order</option>
                    </select>
                </div>

                {loading && <div className="mt-20 flex justify-center items-center"><img src={loadingGif} alt="loading" className='w-20 h-20 mr-20' /></div>}
                {products.length === 0 && !loading && <p className='bg-white text-center text-lg p-4'>No data found</p>}
                {products.length !== 0 && !loading && <VerticalCard loading={loading} data={products} />}
            </div>
        </div>
    );
};

export default SearchProduct;