import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import EditDeliveryAddress from './EditDeliveryAddress';
import { toast } from 'react-toastify';
import SummaryApi from '../../common';
import './DeliveryAddress.css'; // Import your CSS for styling

function DeliveryAddress() {
    const user = useSelector(state => state?.user?.user);
    const [deliveryAddress, setDeliveryAddress] = useState(null);
    const [openEditProfile, setOpenEditProfile] = useState(false);
    const [isAdding, setIsAdding] = useState(false);

    const fetchDeliveryAddress = async () => {
        try {
            const response = await fetch(SummaryApi.getDeliveryAddress.url, {
                method: 'GET',
                credentials: 'include',
            });
            if (response.ok) {
                const result = await response.json();
                setDeliveryAddress(result.data); 
            }
        } catch (error) {
            console.error('Error fetching delivery address:', error);
            toast.error("Failed to fetch delivery address.");
        }
    };

    const handleAddAddress = async (newAddress) => {
        try {
            const response = await fetch(SummaryApi.addDeliveryAddress.url, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(newAddress),
            });
            if (!response.ok) throw new Error('Failed to add address');
            const result = await response.json();
            setDeliveryAddress(result.data);
            toast.success("Delivery address added successfully!");
            setIsAdding(false);
        } catch (error) {
            console.error('Error adding delivery address:', error);
            toast.error("Failed to add delivery address.");
        }
    };

    const handleUserUpdate = async (updatedData) => {
        const payload = {
            userId: deliveryAddress?.userId, // Ensure this is the correct userId
            ...updatedData // Spread the rest of the updated data
        };

        try {
            const response = await fetch(SummaryApi.updateDeliveryAddress.url, {
                method: SummaryApi.updateDeliveryAddress.method,
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(payload), // Send the payload with userId
            });

            if (!response.ok) throw new Error('Failed to update address');
            const result = await response.json();
            setDeliveryAddress(result.data);
            toast.success("Delivery address updated successfully!");
            setOpenEditProfile(false);
        } catch (error) {
            console.error('Error updating delivery address:', error);
            toast.error("Failed to update delivery address.");
        }
    };

    useEffect(() => {
        fetchDeliveryAddress(); // Fetch the delivery address on component mount
    }, [user]);

    return (
        <div className="mr-9 lg:-ml-64 lg:max-w-7xl delivery-address-container">
            <div className="header">
                {deliveryAddress ? (
                    <button className="edit-btn" onClick={() => setOpenEditProfile(true)}>
                        Edit Delivery Address
                    </button>
                ) : (
                    <button className="add-btn" onClick={() => setIsAdding(true)}>
                        Add Delivery Address
                    </button>
                )}
            </div>

            <div className="address-display">
                {deliveryAddress ? (
                    <div className="address-info">
                        <p className='font-semibold'>{deliveryAddress.contactName}</p>
                        <p>{deliveryAddress.mobile1}</p>
                        <p>{deliveryAddress.streetAddress}</p>
                    </div>
                ) : (
                    <p>No delivery address added.</p>
                )}
            </div>

            {openEditProfile && (
                <EditDeliveryAddress
                    onClose={() => setOpenEditProfile(false)}
                    contactName={deliveryAddress?.contactName}
                    mobile1={deliveryAddress?.mobile1}
                    mobile2={deliveryAddress?.mobile2}
                    streetAddress={deliveryAddress?.streetAddress}
                    userId={deliveryAddress?._id}
                    callFunc={handleUserUpdate}
                />
            )}

            {isAdding && (
                <EditDeliveryAddress
                    onClose={() => setIsAdding(false)}
                    callFunc={handleAddAddress}
                />
            )}
        </div>
    );
}

export default DeliveryAddress;