import React, { useEffect, useState } from 'react'
import SummaryApi from '../common'
import { Link } from 'react-router-dom'
import scrollTop from '../helpers/scrollTop'

const FooterCategoryList = () => {
    const [categoryProduct, setCategoryProduct] = useState([])
    const [loading,setLoading] = useState(false)

    const categoryLoading = new Array(13).fill(null)

    const fetchCategoryProduct = async () => {
        setLoading(true)
        const response = await fetch(SummaryApi.categoryProduct.url)
        const dataResponse = await response.json()
        setLoading(false)
        setCategoryProduct(dataResponse.data)
    }

    useEffect(() => {
        fetchCategoryProduct()
    },[])
  return (
    <div className=''>
        <div className='grid grid-cols-2 gap-4 p-2'>
            {

                loading ? (
                        categoryLoading.map((el,index) => {
                            return(
                                <div className='w-5 h-1 md:w-2 md:h-2 overflow-hidden p-4 bg-slate-200 animate-pulse' key={"categoryLoading"+index}>
                                </div>
                            )
                        })
                    
                ):
                (
                    categoryProduct.map((product,index) => {
                        return(
                            <Link to={"/product-category?category="+product?.category} onClick={scrollTop} className='cursor-pointer' key={product?.category}>
                                <p className='text-white hover:text-red-400 text-sm font-semibold capitalize'>{product?.category}</p>
                            </Link>
                        )
                    })
                )
                
            }
        </div>
    </div>
  )
}

export default FooterCategoryList