import React, { useState, useEffect, useRef } from 'react'
import { GrClose } from "react-icons/gr";
import productCategory from '../helpers/productCategory'
import { MdCloudUpload } from "react-icons/md";
import uploadImage from '../helpers/uploadimage';
import Displayimage from './Displayimage';
import { MdDelete } from "react-icons/md";
import SummaryApi from '../common';
import { toast } from 'react-toastify';
import Quill from 'quill';
import 'quill/dist/quill.snow.css'; // Import Quill styles
import '../assest/quill-custom.css'; // Import custom CSS for Quill
import productColor from '../helpers/productColor';

const AdminEditProduct = (
    {
        onClose,
        productData,
        fetchdata
    }
) => {

    const [data, setData] = useState({
        ...productData,
        productName: productData?.productName,
        brandName: productData?.brandName,
        category: productData?.category,
        productImage: productData?.productImage || [],
        description: productData?.description || '',
        productDetailes: productData?.productDetailes || '', // Ensure this is initialized correctly
        productDetailesImage: productData?.productDetailesImage || [],
        productColor: Array.isArray(productData?.productColor) ? productData.productColor : [], // Ensure this is always an array
        stock: productData?.stock,
        price: productData?.price,
        sellingPrice: productData?.sellingPrice,
        promotion: {
            type: productData?.promotion?.type || '',
            value: productData?.promotion?.value || '',
            startDate: productData?.promotion?.startDate || '',
            endDate: productData?.promotion?.endDate || ''
        },
        freeShippingThreshold: productData?.freeShippingThreshold || '', // New field for free shipping
        deliveryFee: productData?.deliveryFee || 0, // New field for delivery fee
    });
    const [openFullScreenImage, setOpenFullScreenImage] = useState(false)
    const [fullScreenImage, setFullScreenImage] = useState("")
    const quillRefDescription = useRef(null);
    const quillRefDetails = useRef(null);
    const [tags, setTags] = useState(data.tags || []); // Initialize tags state

    useEffect(() => {
        // Initialize Quill editor for description
        const quillDescription = new Quill(quillRefDescription.current, {
            theme: 'snow',
            modules: {
                toolbar: [
                    ['bold', 'italic', 'underline'],
                    [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                    ['blockquote', 'code-block'],
                    [{ 'color': [] }, { 'background': [] }],
                    [{ 'font': ['Arial', 'Courier', 'Georgia', 'Times New Roman', 'Verdana', 'Roboto'] }],
                    [{ 'size': ['small', false, 'large', 'huge'] }],
                    ['clean'],
                ]
            }
        });

        // Set initial content for description
        quillDescription.root.innerHTML = data.description;

        // Handle changes for description
        quillDescription.on('text-change', () => {
            const description = quillDescription.root.innerHTML;
            setData((prev) => ({
                ...prev,
                description: description
            }));
        });

        // Initialize Quill editor for product details
        const quillDetails = new Quill(quillRefDetails.current, {
            theme: 'snow',
            modules: {
                toolbar: [
                    ['bold', 'italic', 'underline'],
                    [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                    ['blockquote', 'code-block'],
                    [{ 'color': [] }, { 'background': [] }],
                    [{ 'font': ['Arial', 'Courier', 'Georgia', 'Times New Roman', 'Verdana', 'Roboto'] }],
                    [{ 'size': ['small', false, 'large', 'huge'] }],
                    ['clean'],
                ]
            }
        });

        // Set initial content for product details
        quillDetails.root.innerHTML = data.productDetailes; // Ensure this is set correctly

        // Handle changes for product details
        quillDetails.on('text-change', () => {
            const productDetails = quillDetails.root.innerHTML;
            setData((prev) => ({
                ...prev,
                productDetailes: productDetails // Ensure this is updating the correct state
            }));
        });

        return () => {
            quillDescription.enable(false); // Clean up on unmount
            quillDetails.enable(false); // Clean up on unmount
        };
    }, []); // Run only once on mount

    const handleOnChange = (e) => {
        const { name, value, type, checked } = e.target;
        if (name === 'productColor') {
            // Handle checkbox for product colors
            setData((prev) => {
                const updatedColors = checked 
                    ? [...prev.productColor, value] // Add color if checked
                    : prev.productColor.filter(color => color !== value); // Remove color if unchecked
                return { ...prev, productColor: updatedColors }; // Update productColor array
            });
        } else if (name.startsWith('promotion.')) {
            const promotionField = name.split('.')[1];
            setData((prev) => ({
                ...prev,
                promotion: {
                    ...prev.promotion,
                    [promotionField]: value
                }
            }));
        } else {
            setData((prev) => ({
                ...prev,
                [name]: value
            }));
        }
    }
  
    const handleUploadProduct = async (e) => {
        const file = e.target.files[0]
  
        const uploadImageCloudinary = await uploadImage(file)
  
        setData((prev) => {
          return {
            ...prev,
            productImage : [...prev.productImage,uploadImageCloudinary.url]
          }
        })
  
      }

      const handleDeleteProductImage = async (index) => {
        const newProductImage = [...data.productImage]
        newProductImage.splice(index,1)
  
        setData((prev)=>{
          return {
            ...prev,
            productImage : [...newProductImage]
          }
        })
  
      }

      // upload product for product Details
    const handleUploadProductDetails = async (e) => {
        const file = e.target.files[0]
  
        const uploadImageCloudinary = await uploadImage(file)
  
        setData((prev) => {
          return {
            ...prev,
            productDetailesImage: [...prev.productDetailesImage, uploadImageCloudinary.url] // Only update productDetailesImage
          }
        })
  
      }


      // delete product Detailes Image
    const handleDeleteproductDetailesImage = async (index) => {
        const newProductImage = [...data.productDetailesImage]
        newProductImage.splice(index,1)
  
        setData((prev)=>{
          return {
            ...prev,
            productImage : [...newProductImage]
          }
        })
  
      }
  
    // upload product 
    const handleSubmit = async (e) => {
        e.preventDefault()

        // Convert productColor array to a string and include tags
        const updatedData = {
            ...data,
            productColor: data.productColor.join(','), // Join array into a string with commas
            tags: tags.join(',') // Join tags array into a string with commas
        };

        const response = await fetch(SummaryApi.updateProduct.url,{
          method : SummaryApi.updateProduct.method,
          credentials : "include",
          headers : {
            "content-type" : "application/json",
          },
          body : JSON.stringify(updatedData) // Use updatedData with productColor and tags as strings
        })
  
        const responseData = await response.json()
  
        if(responseData.success){
          toast.success(responseData?.message)
          onClose()
          fetchdata()
        }
  
        if(responseData.error){
          toast.error(responseData?.message)
        }
  
      }

    const handleTagChange = (e) => {
        const { value } = e.target;
        setTags(value.split(',')); // Split input by commas to create an array of tags
    };

  return (
    <div className='fixed top-0 left-0 w-full h-full bg-black/50 flex justify-center items-center'>
    <div className='bg-white p-4 rounded-md max-w-2xl w-full h-full max-h-[80%] overflow-hidden'>

      <div className='flex justify-between items-center pb-3'>
        <h2 className='text-lg font-bold'>Edit Product</h2>
        <div className='w-fit ml-auto text-2xl hover:text-red-600 cursor-pointer' onClick={onClose}>
          <GrClose/>
        </div>
      </div>

      <form className='grid p-4 gap-2 overflow-y-scroll h-full pb-5' onSubmit={handleSubmit}>
        <label htmlFor="productName">Product Name : </label>
        <input 
          type="text" 
          id="productName" 
          placeholder='Enter Product Name' 
          name='productName'
          value={data.productName} 
          onChange={handleOnChange}
          className='p-2 border bg-slate-100 rounded-md'
          
        />

        <label htmlFor="brandName" className='mt-3'>Brand Name : </label>
        <input 
          type="text" 
          id="brandName" 
          placeholder='Enter Brand Name' 
          name='brandName'
          value={data.brandName} 
          onChange={handleOnChange}
          className='p-2 border bg-slate-100 rounded-md'
          
        />

        <label htmlFor="brandName" className='mt-3'>Brand Name : </label>
        <select value={data.category} name='category' onChange={handleOnChange} className='p-2 border bg-slate-100 rounded-md' >
          <option value="">Select Category</option>
          {
            productCategory.map((el,index) => {
              return (
                <option value={el.value} key={el.value+index}>{el.label}</option>
              )
              
            })
          }
        </select>

        <label htmlFor="productImage" className='mt-3'>Product Image : </label>
        <label htmlFor="uploadImageInput" className='cursor-pointer'>
        <div className='p-2 bg-slate-100 border rounded-md h-32 w-full flex justify-center items-center'>
            <div className='text-slate-500 flex flex-col justify-normal items-center gap-2'>
              <span className='text-4xl'><MdCloudUpload /></span>
              <p className='text-sm'>Upload product image</p>
              <input type="file" id="uploadImageInput" className='hidden' onChange={handleUploadProduct} />
            </div>
          </div>
        </label>
        <div>
          {
            data?.productImage[0] ? (
              
              <div className='flex items-center gap-2'>
                {data.productImage.map((el,index)=>{
                  return(
                    <div className='relative group'>
                  <img 
                  src={el} 
                  alt={el} 
                  width={80} 
                  height={80} 
                  className='bg-slate-100 border cursor-pointer'
                  onClick={()=>{
                    setOpenFullScreenImage(true)
                    setFullScreenImage(el)
                  }}
                  />
                  <div className='absolute top-0 right-0 text-white bg-red-500 p-1 rounded-full cursor-pointer hidden group-hover:block' onClick={()=>handleDeleteProductImage(index)}>
                  <MdDelete/>
                  </div>
                  </div>

                )
                })}
              </div>
            ) : (
                <p className='text-xs text-red-500'>*Please upload image </p>
            )
          }
        </div>

        <label htmlFor="price" className='mt-3'>price : </label>
        <input 
          type="number" 
          id="price" 
          placeholder='Enter Price' 
          name='price'
          value={data.price} 
          onChange={handleOnChange}
          className='p-2 border bg-slate-100 rounded-md'
          
        />
        <label htmlFor="sellingPrice" className='mt-3'>sellingPrice : </label>
        <input 
          type="number" 
          id="sellingPrice" 
          placeholder='Enter Selling Price' 
          name='sellingPrice'
          value={data.sellingPrice} 
          onChange={handleOnChange}
          className='p-2 border bg-slate-100 rounded-md'
        />

        {/* product stock */}
        <label htmlFor="stock" className='mt-3'>Product In stock : </label>
        <input 
          type="number" 
          id="stock" 
          placeholder='Enter Product In stock' 
          name='stock'
          value={data.stock} 
          onChange={handleOnChange}
          className='p-2 border bg-slate-100 rounded-md'
        />

        {/* Product Color Section */}
        <label className='mt-3'>Product Color:</label>
        <div className='flex flex-col'>
            {productColor.map((color) => (
                <div key={color.value} className='flex items-center'>
                    <input
                        type="checkbox"
                        id={color.value}
                        name='productColor'
                        value={color.value}
                        checked={data.productColor.includes(color.value)} // Check if color is selected
                        onChange={handleOnChange}
                        className='mr-2'
                    />
                    <label htmlFor={color.value} className='cursor-pointer'>
                        {color.label}
                    </label>
                </div>
            ))}
        </div>

        {/* product descriptio */}
        <label htmlFor="description" className='mt-3'>Description : </label>
        <div ref={quillRefDescription} className='h-28 border resize-none bg-slate-100 rounded-md'></div>

        {/* pduct description */}
        <label htmlFor="productDetails" className='mt-3'>Product details : </label>
        <div ref={quillRefDetails} className='h-28 border resize-none bg-slate-100 rounded-md'></div>
        {/* images for product description */}
        <label htmlFor="productDetailesImage" className='mt-3'>Product Detailes Image : </label>
        <label htmlFor="productDetailesImageInput" className='cursor-pointer'>
        <div className='p-2 bg-slate-100 border rounded-md h-32 w-full flex justify-center items-center'>
            <div className='text-slate-500 flex flex-col justify-normal items-center gap-2'>
              <span className='text-4xl'><MdCloudUpload /></span>
              <p className='text-sm'>Upload product Detailes image</p>
              <input type="file" id="productDetailesImageInput" className='hidden' onChange={handleUploadProductDetails} />
            </div>
          </div>
        </label>
        <div>
          {
            data?.productDetailesImage[0] ? (
              
              <div className='flex items-center gap-2'>
                {data.productDetailesImage.map((el, index) => {
                  return (
                    <div className='relative group' key={index}>
                      <img
                        src={el}
                        alt={el}
                        width={80}
                        height={80}
                        className='bg-slate-100 border cursor-pointer'
                        onClick={() => {
                          setOpenFullScreenImage(true);
                          setFullScreenImage(el);
                        }}
                      />
                      <div className='absolute top-0 right-0 text-white bg-red-500 p-1 rounded-full cursor-pointer hidden group-hover:block' onClick={() => handleDeleteproductDetailesImage(index)}>
                        <MdDelete />
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : (
                <p className='text-xs text-red-500'>*Please upload image </p>
            )
          }
        </div>

        {/* Promotion Fields */}
        <h3 className='mt-4'>Promotion Details</h3>
        <label>Promotion Type:</label>
        <select name='promotion.type' value={data.promotion.type} onChange={handleOnChange} className='p-2 border bg-slate-100 rounded-md'>
            <option value=''>Select Promotion Type</option>
            <option value='percentage'>Percentage Discount</option>
            <option value='fixed'>Fixed Amount Discount</option>
            <option value='freeShipping'>Free Shipping</option> {/* New option for free shipping */}
        </select>

        <label>Promotion Value:</label>
        <input
            type="number"
            name='promotion.value'
            value={data.promotion.value}
            onChange={handleOnChange}
            className='p-2 border bg-slate-100 rounded-md'
        />

        <label>Free Shipping Threshold:</label>
        <input
            type="number"
            name='freeShippingThreshold'
            value={data.freeShippingThreshold}
            onChange={handleOnChange}
            className='p-2 border bg-slate-100 rounded-md'
        />

        <label>Start Date:</label>
        <input
            type="date"
            name='promotion.startDate'
            value={data.promotion.startDate}
            onChange={handleOnChange}
            className='p-2 border bg-slate-100 rounded-md'
        />

        <label>End Date:</label>
        <input
            type="date"
            name='promotion.endDate'
            value={data.promotion.endDate}
            onChange={handleOnChange}
            className='p-2 border bg-slate-100 rounded-md'
        />

        {/* Product Tags Section */}
        <label htmlFor="tags" className='mt-3'>Product Tags (comma separated):</label>
        <input
            type="text"
            id="tags"
            placeholder='Enter Product Tags'
            name='tags'
            value={tags.join(', ')} // Join tags array into a string for display
            onChange={handleTagChange}
            className='p-2 border bg-slate-100 rounded-md'
        />

        <button className='px-3 py-2 bg-red-500 text-white mb-10 hover:bg-red-700'>Update Product</button>

      </form>
      
    </div>

    {/* display image */}
    {
      openFullScreenImage && (
        <Displayimage onClose={()=>setOpenFullScreenImage(false)} imgUrl={fullScreenImage}/>
      )
    }
    
  </div>
  )
}

export default AdminEditProduct
