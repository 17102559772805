import React, { useState, useEffect } from 'react';
import { getDistrictsByRegion, getTownsByDistrict } from '../../helpers/ghanaLocations';
import { toast } from 'react-toastify';
import './EditDeliveryAddress.css'; // Import your CSS for styling

const EditDeliveryAddress = ({ onClose, callFunc, ...props }) => {
    const [formData, setFormData] = useState({
        contactName: props.contactName || '',
        mobile1: props.mobile1 || '',
        mobile2: props.mobile2 || '',
        streetAddress: props.streetAddress || '',
        region: '',
        district: '',
        town: '',
    });

    const [districts, setDistricts] = useState([]);
    const [towns, setTowns] = useState([]);
    const regions = ['Greater Accra', 'Ashanti', 'Central']; // Replace with actual regions from your helper

    useEffect(() => {
        if (formData.region) {
            const fetchedDistricts = getDistrictsByRegion(formData.region);
            setDistricts(fetchedDistricts);
            setFormData({ ...formData, district: '', town: '' }); // Reset district and town when region changes
        }
    }, [formData.region]);

    useEffect(() => {
        if (formData.district) {
            const fetchedTowns = getTownsByDistrict(formData.region, formData.district);
            setTowns(fetchedTowns);
        } else {
            setTowns([]); // Reset towns if no district is selected
        }
    }, [formData.district]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        callFunc(formData);
    };

    return (
        <div className="modal">
            <div className="modal-content">
                <span className="close" onClick={onClose}>&times;</span>
                <form className='form' onSubmit={handleSubmit}>
                    <input className='input' type="text" name="contactName" value={formData.contactName} onChange={handleChange} placeholder="Contact Name" required />
                    <input className='input' type="tel" name="mobile1" value={formData.mobile1} onChange={handleChange} placeholder="Mobile 1" required />
                    <input className='input' type="tel" name="mobile2" value={formData.mobile2} onChange={handleChange} placeholder="Mobile 2 (Optional)" />
                    <input className='input' type="text" name="streetAddress" value={formData.streetAddress} onChange={handleChange} placeholder="Street Address" required />
                    <select className='select' name="region" value={formData.region} onChange={handleChange} required>
                        <option value="">Select Region</option>
                        {regions.map((region) => (
                            <option key={region} value={region}>{region}</option>
                        ))}
                    </select>
                    <select className='select' name="district" value={formData.district} onChange={handleChange} required>
                        <option value="">Select District</option>
                        {districts.map((district) => (
                            <option key={district.name} value={district.name}>{district.name}</option>
                        ))}
                    </select>
                    <select className='select' name="town" value={formData.town} onChange={handleChange} required>
                        <option value="">Select Town</option>
                        {towns.map((town) => (
                            <option key={town} value={town}>{town}</option>
                        ))}
                    </select>
                    <button className='btn' type="submit">Submit</button>
                </form>
            </div>
        </div>
    );
};

export default EditDeliveryAddress;
