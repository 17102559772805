const productCategory = [
    {id: 1, label: 'Airpods & Cases', value: 'airpods & cases'},
    {id: 2, label: 'Pen Drives & Memory Cards', value: 'pen drives & memory cards'},
    {id: 3, label: 'Earphones & Headphones', value: 'earphones & headphones'},
    {id: 4, label: 'Power Banks', value: 'power banks'},
    {id: 5, label: 'Mouse & Keyboard', value: 'mouse & keyboard'},
    {id: 6, label: 'Phone Cases', value: 'phone cases'},
    {id: 7, label: 'Phone Charger', value: 'phone charger'},
    {id: 8, label: 'Game Controllers', value: 'game controllers'},
    {id: 9, label: 'Speakers', value: 'speakers'},
    {id: 10, label: 'Screen Protectors', value: 'screen protectors'},
    {id: 11, label: 'Phone Battery', value: 'phone battery'},
    {id: 12, label: 'Extension Boards', value: 'extension boards'},
    {id: 13, label: 'Watches', value: 'watches'},
    {id: 14, label: 'Mobile', value: 'mobile'},
]

export default productCategory
