import React, { useState } from 'react';
import { useSelector } from 'react-redux';

const AddReview = ({ productId, onClose, onSubmit }) => {
    const [rating, setRating] = useState(0);
    const [comment, setComment] = useState('');
    const user = useSelector(state => state?.user?.user);

    const handleSubmit = () => {
        if (rating > 0 && comment) {
            onSubmit({ productId, userId: user._id, name: user.name, profilePic: user.profilePic, rating, comment });
            onClose(); // Close the modal after submission
        } else {
            alert("Please provide a rating and a comment.");
        }
    };

    return (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
            <div className="bg-white p-6 rounded shadow-lg">
                <h2 className="text-lg font-bold mb-4">Add Review</h2>
                <div>
                    <label>Rating:</label>
                    <select value={rating} onChange={(e) => setRating(e.target.value)}>
                        <option value="0">Select Rating</option>
                        {[1, 2, 3, 4, 5].map((star) => (
                            <option key={star} value={star}>{star}</option>
                        ))}
                    </select>
                </div>
                <div>
                    <label>Comment:</label>
                    <textarea value={comment} onChange={(e) => setComment(e.target.value)} />
                </div>
                <div className="flex justify-end mt-4">
                    <button onClick={onClose} className="mr-2 bg-gray-300 text-gray-700 px-4 py-2 rounded">Cancel</button>
                    <button onClick={handleSubmit} className="bg-blue-500 text-white px-4 py-2 rounded">Submit</button>
                </div>
            </div>
        </div>
    );
};

export default AddReview;

