import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SummaryApi from '../../common';
import { useSelector } from 'react-redux';
import { MdDelete } from "react-icons/md";
import { useNavigate } from 'react-router-dom';
import { tailChase } from 'ldrs';
import noOrdersImage from '../../assest/packs.png';
import { MdNavigateNext } from "react-icons/md";
import { GrFormPrevious } from "react-icons/gr";
import displayGHSCurrency from '../../helpers/displayCurrency';
import AddReview from '../../components/AddReview';
import ReturnAndRefundModal from './ReturnAndRefundModal'; // Import the ReturnAndRefund component

const MyOrders = () => {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(false);
  const [activeStatus, setActiveStatus] = useState('');
  const [expandedOrder, setExpandedOrder] = useState(null);
  const [showReviewModal, setShowReviewModal] = useState(false);
  const [currentProductId, setCurrentProductId] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5; // Set items per page
  const navigate = useNavigate();
  tailChase.register();
  const user = useSelector(state => state.user);
  const userId = user?.user?._id;

  // State for delete confirmation modal
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [orderToDelete, setOrderToDelete] = useState(null);

  const [showReturnModal, setShowReturnModal] = useState(false);
  const [selectedOrderId, setSelectedOrderId] = useState(null);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [selectedOrderNumber, setSelectedOrderNumber] = useState(null);

  const fetchOrders = async (status) => {
    if (!userId) {
      toast.error('User ID is undefined. Please log in.');
      return;
    }

    setLoading(true);
    setActiveStatus(status);
    try {
      const response = await fetch(`${SummaryApi.viewMyOrder.url}?userId=${userId}${status ? `&status=${status}` : ''}`, {
        credentials: "include",
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      if (data.success) {
        setOrders(data.data);
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      console.error("Error fetching orders:", error);
      toast.error('Failed to fetch orders.');
    } finally {
      setLoading(false);
    }
  };

  const handleAddReview = async (reviewData) => {
      try {
        const response = await fetch(SummaryApi.addReview.url, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({...reviewData,
              
              productId: currentProductId,
            }),
        });
        const data = await response.json();
        if (data.success) {
            toast.success('Review added successfully!');
            fetchOrders(); // Refresh orders to show the new review
        } else {
            toast.error(data.message);
        }
    } catch (error) {
        console.error('Error adding review:', error);
        toast.error('Failed to add review.');
    }
};

const handleReviewClick = (productId) => {
    setCurrentProductId(productId);
        setShowReviewModal(true);
    };

  const deleteOrder = async (orderId) => {
    try {
      const response = await fetch(SummaryApi.deleteOrder.url, {
        method: SummaryApi.deleteOrder.method,
        credentials: 'include',
        headers: {
          'content-type': 'application/json'
        },
        body: JSON.stringify({
          _id: orderId
        })
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      if (data.success) {
        setOrders(orders.filter(order => order._id !== orderId));
        toast.success('Order deleted successfully.');
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      console.error("Error deleting order:", error);
      toast.error('Failed to delete order.');
    }
  };

  const handleDeleteClick = (orderId) => {
    setOrderToDelete(orderId);
    setShowDeleteModal(true);
  };

  const handleConfirmDelete = () => {
    if (orderToDelete) {
      deleteOrder(orderToDelete);
      setShowDeleteModal(false);
      setOrderToDelete(null);
    }
  };

  const handleCancelDelete = () => {
    setShowDeleteModal(false);
    setOrderToDelete(null);
  };

  const handleReturnClick = (orderId, product, order) => {
    const currentDate = new Date();
    const orderPlacedDate = new Date(order.createdAt); // Assuming order.createdAt is the order date
    const timeDifference = currentDate - orderPlacedDate;
    const daysDifference = timeDifference / (1000 * 3600 * 24); // Convert milliseconds to days

    if (daysDifference > 7) {
        toast.error('This product does not qualify for a return because it has exceeded the 7-day return period.');
    } else {
        setSelectedOrderId(orderId);
        setSelectedOrderNumber(order.orderNumber);
        setSelectedProducts([product]); // Pass only the selected product
        setShowReturnModal(true);
    }
  };

  useEffect(() => {
    fetchOrders(); // Fetch all orders initially
  }, []);

  // Calculate paginated orders
  const indexOfLastOrder = currentPage * itemsPerPage;
  const indexOfFirstOrder = indexOfLastOrder - itemsPerPage;
  const currentOrders = orders.slice(indexOfFirstOrder, indexOfLastOrder);
  const totalPages = Math.ceil(orders.length / itemsPerPage);

  const handlePageChange = (page) => {
    if (page > 0 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  const toggleDetails = (orderId) => {
    setExpandedOrder(prev => (prev === orderId ? null : orderId));
  };

  return (
    <div className="mr-9 lg:-ml-64 lg:max-w-7xl px-4">
      <div className='bg-white text-2xl h-16 py-2 px-4 flex justify-between items-center'>
        <p className='text-2xl font-bold p-2 border-b-2 border-gray-300 w-full'>My Orders</p>
      </div>
      <div className='bg-white text-2xl h-16 py-2 px-4 flex justify-between items-center'>
        <button
          onClick={() => fetchOrders()}
          className={`text-2xl cursor-pointer p-2 border-b-2 border-gray-300 w-full transition duration-300 ease-in-out transform hover:scale-105 ${
            activeStatus === '' ? 'text-red-700 border-b-4 border-red-700' : 'text-red-500 hover:text-red-700'
          }`}
        >
          All
        </button>
        <button
          onClick={() => fetchOrders('Pending')}
          className={`text-2xl cursor-pointer p-2 border-b-2 border-gray-300 w-full transition duration-300 ease-in-out transform hover:scale-105 ${
            activeStatus === 'Pending' ? 'text-red-700 border-b-4 border-red-700' : 'text-red-500 hover:text-red-700'
          }`}
        >
          Pending
        </button>
        
        <button
          onClick={() => fetchOrders('Delivered')}
          className={`text-2xl cursor-pointer p-2 border-b-2 border-gray-300 w-full transition duration-300 ease-in-out transform hover:scale-105 ${
            activeStatus === 'Delivered' ? 'text-red-700 border-b-4 border-red-700' : 'text-red-500 hover:text-red-700'
          }`}
        >
          Delivered
        </button>
      </div>

      {loading ? (
        <div className="ml-72 mt-20 justify-center items-center">
          <l-tail-chase
            size="40"
            speed="1.75" 
            color="black" 
          ></l-tail-chase>
        </div>
      ) : (
        <div className='p-4 bg-white'>
          {currentOrders.length === 0 ? (
            <div className="text-center bg-white p-4 rounded-lg items-center flex flex-col justify-center">
              <img src={noOrdersImage} alt="no orders" className='w-20 h-20 bg-slate-200 rounded-full '/>
              <p>You have placed no orders yet!</p>
              <p>All your orders will be saved here for you to access their state anytime.</p>
              <button 
                onClick={() => navigate('/')} // Navigate to homepage
                className="mt-4 bg-red-500 text-white px-4 py-2 rounded transition duration-300 ease-in-out transform hover:scale-105 hover:bg-red-700"
              >
                Continue Shopping
              </button>
            </div>
          ) : (
            currentOrders.map(order => (
              <div className=''>
                <div key={order._id} className='bg-white border p-4 mb-4 rounded shadow-sm '>
                  <div className='flex justify-between'>
                    <h3 className='text-lg font-bold'>Order No: {order.orderNumber}</h3>
                    <button onClick={() => handleDeleteClick(order._id)} className='text-red-500 hover:text-red-700 transition duration-300 ease-in-out transform hover:scale-105'>
                      <MdDelete size={24} />
                    </button>
                  </div>
                  <div>
                    {order.products.length > 0 && order.products[0].productId ? (
                      <div className='flex items-center mb-2'>
                        <div>
                          <h3 className='text-md font-semibold'>Order Details</h3>
                          <p className='text-gray-600'>Order Date: {new Date(order.createdAt).toLocaleDateString()}</p> {/* Display order date */}
                          <p className='text-gray-600'>Total Products: {order.products.reduce((total, product) => total + product.quantity, 0)}</p> {/* Total number of products */}
                          <p className='text-gray-600'>Total Amount:  {displayGHSCurrency(order.totalAmount)}</p> {/* Total amount */}
                        </div>
                      </div>
                    ) : (
                      <div className='flex items-center mb-2'>
                        <p>Product information is not available.</p>
                      </div>
                    )}
                    
                  </div>
                  <p>Status: {order.orderStatus}</p>
                  <button 
                    onClick={() => toggleDetails(order._id)} 
                    className='text-blue-500 hover:text-blue-700'
                  >
                    {expandedOrder === order._id ? 'Hide Details' : 'View Details'}
                  </button>
                  {expandedOrder === order._id && (
                    <div className='mt-2'>
                      {order.products.map(product => (
                        product.productId ? (
                          <div key={product.productId._id} className='flex items-center mb-2 border-2 border-gray-300 p-2'>
                            <img src={product.productImage} alt={product.productName} className='w-16 h-16 object-cover mr-2' />
                            <div className='lg:flex justify-between w-full'>
                              <div>
                                <p>{product.productName}</p>
                                <p>Color: {product.productColor ? product.productColor.join(', ') : 'N/A'}</p>
                                <p>Quantity: {product.quantity}</p>
                                <p>Price: {displayGHSCurrency(product.sellingPrice)}</p>
                              </div>
                              <div>
                                {
                                  order.orderStatus === 'Delivered' && (
                                    <button onClick={() => handleReviewClick(product.productId)} className='bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600 hover:text-white transition duration-300 ease-in-out transform hover:scale-105 hover:shadow-lg'>Add Review</button>
                                  )
                                }
                                <button onClick={() => handleReturnClick(order._id, product, order)} className='bg-yellow-500 text-white px-4 py-2 rounded hover:bg-yellow-600 hover:text-white transition duration-300 ease-in-out transform hover:scale-105 hover:shadow-lg'>Return and Refund</button>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div key={product._id} className='flex items-center mb-2'>
                            <p>Product information is not available.</p>
                          </div>
                        )
                      ))}
                    </div>
                  )}
                </div>
              </div>
            ))
          )}
          {/* Pagination Controls */}
          <div className='flex justify-center mt-4'>
            <button onClick={() => handlePageChange(1)} disabled={currentPage === 1} className='border border-red-700 p-1.5 rounded-md hover:bg-red-600 hover:text-white cursor-pointer'>《</button>
            <button onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1} className='border border-red-700 p-1.5 rounded-md hover:bg-red-600 hover:text-white ml-1 cursor-pointer'><GrFormPrevious size={24} /></button>

            {/* Numbered Pages */}
            {Array.from({ length: totalPages }, (_, index) => (
              <button
                key={index + 1}
                onClick={() => handlePageChange(index + 1)}
                className={`border border-red-700 p-1.5 rounded-md mx-1 ${currentPage === index + 1 ? 'bg-red-600 text-white' : 'hover:bg-red-600 hover:text-white'}`}
              >
                {index + 1}
              </button>
            ))}

            <button onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages} className='border border-red-700 p-1.5 rounded-md hover:bg-red-600 hover:text-white mr-1 cursor-pointer'><MdNavigateNext size={24} /></button>
            <button onClick={() => handlePageChange(totalPages)} disabled={currentPage === totalPages} className='border border-red-700 p-1.5 rounded-md hover:bg-red-600 hover:text-white cursor-pointer'>》</button>
          </div>
        </div>
      )}

       {/* Show review modal if needed */}
       {showReviewModal && (
                <AddReview
                    productId={currentProductId}
                    onClose={() => setShowReviewModal(false)}
                    onSubmit={handleAddReview}
                />
            )}

      {/* Confirmation Modal */}
      {showDeleteModal && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded shadow-lg">
            <h2 className="text-lg font-bold mb-4">Confirm Deletion</h2>
            <p>Are you sure you want to delete this order?</p>
            <div className="flex justify-end mt-4">
              <button onClick={handleCancelDelete} className="mr-2 bg-gray-300 text-gray-700 px-4 py-2 rounded hover:bg-gray-400 hover:text-white transition duration-300 ease-in-out transform hover:scale-105 hover:shadow-lg">Cancel</button>
              <button onClick={handleConfirmDelete} className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600 hover:text-white transition duration-300 ease-in-out transform hover:scale-105 hover:shadow-lg">Yes, Delete</button>
            </div>
          </div>
        </div>
      )}

      {showReturnModal && (
        <ReturnAndRefundModal 
          orderId={selectedOrderId} 
          orderNumber={selectedOrderNumber}
          products={selectedProducts} 
          onClose={() => setShowReturnModal(false)} 
        />
      )}
    </div>
  );
};

export default MyOrders;
