const ghanaLocations = {
    regions: [
      {
        name: 'Greater Accra',
        districts: [
          {
            name: 'Accra',
            towns: ['Osu', 'Labone', 'Tema'],
          },
          {
            name: 'Ga East',
            towns: ['Abokobi', 'Amasaman'],
          },
        ],
      },
      {
        name: 'Ashanti',
        districts: [
          {
            name: 'Kumasi',
            towns: ['Bantama', 'Asokwa'],
          },
          {
            name: 'Obuasi',
            towns: ['Obuasi Town', 'Akrofuom'],
          },
        ],
      },
      {
        name: 'Central',
        districts: [
          {
            name: 'Cape Coast Mtro',
            towns: ['Abura', 'Akotokyire', 'Amamoma', 'Apewosika', 'Ankaful village', 'Anto Essuekyir', 'Cape Coast', 'Duakor', 'Effutu', 'Ekon', 'Kakumdo', 'Koforidua', 'Kokoado', 'Kwaprow', 'Mpeasem', 'Nkanfoa', 'Nyinasin', 'Ola', 'Pedu'],
          }
        ],
      },
      // Add more regions, districts, and towns as needed
    ],
  };
  
  // Helper function to get districts by region
  export const getDistrictsByRegion = (region) => {
    const foundRegion = ghanaLocations.regions.find(r => r.name === region);
    return foundRegion ? foundRegion.districts : [];
  };
  
  // Helper function to get towns by district
  export const getTownsByDistrict = (region, district) => {
    const foundRegion = ghanaLocations.regions.find(r => r.name === region);
    if (foundRegion) {
      const foundDistrict = foundRegion.districts.find(d => d.name === district);
      return foundDistrict ? foundDistrict.towns : [];
    }
    return [];
  };

  // Export as default
  export default ghanaLocations;
