import React, { useState } from 'react'
import { IoIosClose } from "react-icons/io";
import SummaryApi from '../common';
import { toast } from 'react-toastify';

const ChangeUserDetails = ({
    name,
    email,
    number,
    userId,
    onClose,
    callFunc
}) => {
    const [userName, setUserName] = useState(name)
    const [userEmail, setUserEmail] = useState(email)
    const [userNumber, setUserNumber] = useState(number)

    const handleOnChangeSelect = (e) => {
        // Update specific field based on input name
        const { name, value } = e.target;
        if (name === "name") setUserName(value);
        else if (name === "email") setUserEmail(value);
        else if (name === "number") setUserNumber(value);
    }

    const updateUserDetails = async () => {
        const fetchResponse = await fetch(SummaryApi.updateUserProfile.url, {
            method : SummaryApi.updateUserProfile.method,
            credentials : "include",
            headers : {
                "content-type" : "application/json"
            },
            body : JSON.stringify({
                userId : userId,
                name : userName,
                email : userEmail,
                number : userNumber,
            })
        })

        const responseData = await fetchResponse.json()

        if(responseData.success){
            toast.success(responseData.message)
            onClose()
            callFunc({ // Pass updated user data back to Profile
                _id: userId,
                name: userName,
                email: userEmail,
                number: userNumber,
            });
        }else{
            toast.error(responseData.message)
        }

    }

  return (
    <div className='fixed top-0 bottom-0 flex left-0 right-0 w-full h-full z-10 justify-between items-center bg-black/50'>
        <div className='mx-auto bg-white p-4 shadow-md w-full max-w-sm'>

            <button className='block ml-auto' onClick={onClose}>
                <IoIosClose size={24}/>
            </button>

            <h1 className='pb-4 text-lg font-medium'>Edit Your Details</h1>
            
            <div className='flex flex-col my-4'>
                <label>Name:</label>
                <input className='border px-4 py-1' name="name" value={userName} onChange={handleOnChangeSelect} />
                <label>Email:</label>
                <input className='border px-4 py-1' name="email" value={userEmail} onChange={handleOnChangeSelect} />
                <label>Number:</label>
                <input className='border px-4 py-1' name="number" value={userNumber} onChange={handleOnChangeSelect} />
            </div>

            <button className='w-fit mx-auto block py-1 px-3 rounded-full bg-red-500 text-white hover:bg-red-700' onClick={updateUserDetails}>Update Details</button>
        </div>
    </div>
  )
}

export default ChangeUserDetails