import React, { useState } from 'react';
import scrollTop from '../helpers/scrollTop';
import displayINRCurrency from '../helpers/displayCurrency';
import { Link } from 'react-router-dom';
import { MdNavigateNext } from "react-icons/md";
import { GrFormPrevious } from "react-icons/gr";

const AllPromotionProductsDisplay = ({ loading, data = [] }) => {
    const loadingList = new Array(13).fill(null);

    // Pagination state
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 24; // Set items per page

    // Calculate paginated products
    const indexOfLastProduct = currentPage * itemsPerPage;
    const indexOfFirstProduct = indexOfLastProduct - itemsPerPage;
    const currentItems = data.slice(indexOfFirstProduct, indexOfLastProduct);
    const totalPages = Math.ceil(data.length / itemsPerPage); // Calculate total pages

    // Pagination controls
    const handlePageChange = (page) => {
        if (page > 0 && page <= totalPages) {
            setCurrentPage(page);
        }
    };

    // Calculate the range of pages to display
    const getPaginationRange = () => {
        const range = 5; // Number of pages to show
        const start = Math.max(1, currentPage - Math.floor(range / 2));
        const end = Math.min(totalPages, start + range - 1);
        return Array.from({ length: end - start + 1 }, (_, i) => start + i);
    };

    return (
        <div>
            <div className='grid grid-cols-3 gap-4 sm:grid-cols-4 sm:gap-4 md:grid-cols-6 justify-between md:justify-between md:gap-2 overflow-x-scroll scrollbar-none transition-all'>
                {
                    loading ? (
                        loadingList.map((_, index) => {
                            return (
                                <div key={index} className='w-full min-w-[140px] md:min-w-[280px] md:max-w-[320px] bg-white rounded-sm shadow'>
                                    <div className='bg-slate-200 h-48 p-4 min-w-[140px] flex justify-center items-center animate-pulse'></div>
                                    <div className='p-4 grid gap-3'>
                                        <h2 className='font-medium text-base md:text-lg text-ellipsis line-clamp-1 text-black p-1 py-2 animate-pulse rounded-full bg-slate-200'> </h2>
                                        <p className='capitalize text-slate-500 p-1 animate-pulse rounded-full bg-slate-200 py-2'></p>
                                        <div className='flex gap-3'>
                                            <p className='text-red-600 font-medium p-1 animate-pulse rounded-full bg-slate-200 w-full py-2'></p>
                                            <p className='text-slate-500 line-through p-1 animate-pulse rounded-full bg-slate-200 w-full py-2'></p>
                                        </div>
                                        <button className='text-sm text-white px-3 rounded-full bg-slate-200 py-2 animate-pulse'></button>
                                    </div>
                                </div>
                            );
                        })
                    ) : (
                        currentItems.map((product, index) => {
                            return (
                                <Link key={product?._id} to={"/product/" + product?._id} className='w-full min-w-[120px] md:min-w-40 max-w-[140px] md:max-w-40 bg-white rounded-sm shadow-md md:mb-4' onClick={scrollTop}>
                                    <div className='bg-slate-200 h-32 md:h-32 p-4 md:w-40 min-w-[120px] flex justify-center items-center relative'>
                                        <img src={product?.productImage[0]} alt='' className='object-scale-down h-full hover:scale-110 transition-all mix-blend-multiply' />
                                        <div style={{ background: 'rgba(255, 255, 255, 0.5)' }} className='p-1 absolute top-2 right-2 rounded-md'>
                                            <p className='text-red-500 font-sm text-sm '>{product?.promotion?.value}% OFF</p>
                                        </div>
                                    </div>
                                    <div className='p-4 grid '>
                                        <h2 className='md:font-sm text-sm md:text-base text-ellipsis line-clamp-1 text-black'>{product?.productName}</h2>
                                        <p className='capitalize text-slate-500'>{product?.category}</p>
                                        <div className='grid md:grid'>
                                            {product?.price && (
                                                <p className='text-sm text-slate-500 line-through'>{displayINRCurrency(product?.price)}</p>
                                            )}
                                            {product?.sellingPrice && (
                                                <p className='text-red-600 md:font-medium'>{displayINRCurrency(product?.sellingPrice)}</p>
                                            )}
                                        </div>
                                        <button className='text-sm bg-red-600 hover:bg-red-700 text-white md:px-3 md:py-0.5 rounded-sm mt-2'>View Product</button>
                                    </div>
                                </Link>
                            );
                        })
                    )
                }
            </div>

            {/* Pagination Controls */}
            <div className='flex justify-center mt-4'>
                <button onClick={() => handlePageChange(1)} disabled={currentPage === 1} className='border border-red-700 p-1.5 rounded-md hover:bg-red-600 hover:text-white'>《</button>
                <button onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1} className='border border-red-700 p-1.5 rounded-md hover:bg-red-600 hover:text-white ml-1'><GrFormPrevious /></button>

                {/* Numbered Pages */}
                {getPaginationRange().map((page) => (
                    <button
                        key={page}
                        onClick={() => handlePageChange(page)}
                        className={`border border-red-700 p-1.5 rounded-md mx-1 ${currentPage === page ? 'bg-red-600 text-white' : 'hover:bg-red-600 hover:text-white'}`}
                    >
                        {page}
                    </button>
                ))}

                <button onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages} className='border border-red-700 p-1.5 rounded-md hover:bg-red-600 hover:text-white mr-1'><MdNavigateNext /></button>
                <button onClick={() => handlePageChange(totalPages)} disabled={currentPage === totalPages} className='border border-red-700 p-1.5 rounded-md hover:bg-red-600 hover:text-white'>》</button>
            </div>
        </div>
    );
};

export default AllPromotionProductsDisplay;