import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import productCategory from '../helpers/productCategory';
import SummaryApi from '../common';
import AllPromotionProductsDisplay from '../components/AllPromotionProductsDisplay';
import { IoMdArrowDropdown } from "react-icons/io";
import { IoIosClose } from "react-icons/io";

const AllPromotionProducts = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();
    const urlSearch = new URLSearchParams(location.search);
    const urlCategoryListinArrary = urlSearch.getAll("category");

    const urlCategoryListObject = {};
    urlCategoryListinArrary.forEach(el => {
        urlCategoryListObject[el] = true;
    });

    const [selectCategory, setSelectCategory] = useState(urlCategoryListObject);
    const [filterCategoryList, setFilterCategoryList] = useState([]);
    const [sortBy, setSortBy] = useState();
    const [showSortModal, setShowSortModal] = useState(false);
    const [showCategoryModal, setShowCategoryModal] = useState(false);

    const fetchData = async () => {
        setLoading(true);
        try {

            // Construct the URL with query parameters
            const url = new URL(SummaryApi.getPromotedProducts.url);
            if (filterCategoryList.length > 0) {
                url.searchParams.append('category', filterCategoryList.join(',')); // Append categories as a comma-separated list
            }

            const response = await fetch(url, {
                method: 'GET', // Use GET method
                headers: {
                    "content-type": "application/json",
                }
            });

            const dataResponse = await response.json();

            // Check if dataResponse exists and has a data property that is an array
            if (dataResponse && Array.isArray(dataResponse)) { // Adjusted to check if dataResponse is an array
                dataResponse.forEach(product => {
                });
                setData(dataResponse); // Set data only if it exists
            } else {
                console.error("Data is not an array or is undefined:", dataResponse);
                setData([]); // Set data to an empty array if the structure is not as expected
            }

        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setLoading(false);
        }
    };

    const handleSelectCategory = (e) => {
        const { value, checked } = e.target;

        setSelectCategory((prev) => ({
            ...prev,
            [value]: checked
        }));
    };

    useEffect(() => {
        fetchData(); // Fetch data when the component mounts
    }, [filterCategoryList]);

    useEffect(() => {
        const arrayOfCategory = Object.keys(selectCategory).filter(categoryKeyName => selectCategory[categoryKeyName]);
        setFilterCategoryList(arrayOfCategory);
        
        const urlFormat = arrayOfCategory.map(el => `category=${el}`).join("&&");
        navigate("/all-promotion-products?" + urlFormat);
    }, [selectCategory]);

    const handleOnchangeSortBy = (e) => {
        const { value } = e.target;
        setSortBy(value);

        const sortedData = [...data];
        if (value === 'asc') {
            sortedData.sort((a, b) => a.sellingPrice - b.sellingPrice);
        } else if (value === 'dsc') {
            sortedData.sort((a, b) => b.sellingPrice - a.sellingPrice);
        }
        setData(sortedData);
    };

    // Filter data based on selected categories
    const filteredData = data.filter(product => {
        const hasPromotion = product.promotion && product.promotion.value > 0; // Check if the product has a promotion
        const isInSelectedCategory = filterCategoryList.length === 0 || filterCategoryList.includes(product.category.trim()); // Ensure to trim spaces
        return hasPromotion && isInSelectedCategory;
    });


    return (
        <div className='container mx-auto p-4'>
            <div className='lg:grid grid-cols-[200px,1fr]'>
                <div className='bg-white p-2 min-h-[calc(100vh-120px)] overflow-y-scroll scrollbar-none lg:block hidden'>
                    <div>
                        <h3 className='text-lg font-semibold text-slate-500 border-b p-1 border-slate-300'>Sort By</h3>
                        <form className='text-sm flex flex-col gap-2 py-2'>
                            <div className='flex items-center gap-2'>
                                <input type='radio' name='sortBy' value={"asc"} checked={sortBy === 'asc'} onChange={handleOnchangeSortBy} />
                                <label>Price - Low to High</label>
                            </div>
                            <div className='flex items-center gap-2'>
                                <input type='radio' name='sortBy' value={"dsc"} checked={sortBy === 'dsc'} onChange={handleOnchangeSortBy} />
                                <label>Price - High to Low</label>
                            </div>
                        </form>
                    </div>

                    <div>
                        <h3 className='text-lg font-semibold text-slate-500 border-b p-1 border-slate-300'>Category</h3>
                        <form className='text-sm flex flex-col gap-2 py-2'>
                            {productCategory.map((categoryName, index) => {
                                return (
                                    <div className='flex items-center gap-3' key={index}>
                                        <input type='checkbox' checked={selectCategory[categoryName?.value]} name={"category"} value={categoryName?.value} id={categoryName?.value} onChange={handleSelectCategory} />
                                        <label htmlFor={categoryName?.value}>{categoryName?.label}</label>
                                    </div>
                                );
                            })}
                        </form>
                    </div>
                </div>

                <div className='lg:hidden flex gap-2 ml-4 mt-4'>
                    <button onClick={() => setShowSortModal(true)} className='bg-slate-200 text-black px-4 py-2 rounded flex items-center gap-2'>Sort By <IoMdArrowDropdown size={25}/></button>
                    <button onClick={() => setShowCategoryModal(true)} className='bg-slate-200 text-black px-4 py-2 rounded flex items-center gap-2'>Category <IoMdArrowDropdown size={25}/></button>
                </div>

                {showSortModal && (
                    <div className='fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50'>
                        <div className='bg-white p-4 rounded w-11/12 max-w-md'>
                            <div className='flex justify-between border-b p-1 border-slate-300'>
                                <h3 className='text-lg font-semibold text-slate-500 '>Sort By</h3>
                                <button onClick={() => setShowSortModal(false)} className='text-red-600 px-4 py-2 rounded hover:text-red-800 hover:scale-110'><IoIosClose size={25}/></button>
                            </div>
                            <form className='text-sm flex flex-col gap-2 py-2'>
                                <div className='flex items-center gap-2'>
                                    <input type='radio' name='sortBy' value={"asc"} checked={sortBy === 'asc'} onChange={handleOnchangeSortBy} />
                                    <label>Price - Low to High</label>
                                </div>
                                <div className='flex items-center gap-2'>
                                    <input type='radio' name='sortBy' value={"dsc"} checked={sortBy === 'dsc'} onChange={handleOnchangeSortBy} />
                                    <label>Price - High to Low</label>
                                </div>
                            </form>
                        </div>
                    </div>
                )}

                {showCategoryModal && (
                    <div className='fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50'>
                        <div className='bg-white p-4 rounded w-11/12 max-w-md'>
                            <div className='flex justify-between border-b p-1 border-slate-300'>
                                <h3 className='text-lg font-semibold text-slate-500 '>Category <span className='text-sm text-slate-400 font-normal'>(You can select multiple category)</span></h3>
                                <button onClick={() => setShowCategoryModal(false)} className='text-red-600 px-4 py-2 rounded hover:text-red-800 hover:scale-110'><IoIosClose size={25}/></button>
                            </div>
                            <form className='text-sm flex flex-col gap-2 py-2'>
                                {productCategory.map((categoryName, index) => {
                                    return (
                                        <div className='flex items-center gap-3' key={index}>
                                            <input type='checkbox' checked={selectCategory[categoryName?.value]} name={"category"} value={categoryName?.value} id={categoryName?.value} onChange={handleSelectCategory} />
                                            <label htmlFor={categoryName?.value}>{categoryName?.label}</label>
                                        </div>
                                    );
                                })}
                            </form>
                        </div>
                    </div>
                )}

                <div className='flex flex-col lg:flex-row px-4'>
                    <div className='flex-1'>
                        <p className='font-medium text-slate-800 text-lg my-2'>Filter Results : ({filteredData.length} products found)</p>
                        <div className='lg:min-h-[calc(100vh-120px)] lg:overflow-y-scroll scrollbar-none lg:max-h-[calc(100vh-120px)]'>
                            {loading ? (
                                <p>Loading...</p>
                            ) : (
                                filteredData.length !== 0 ? (
                                    <AllPromotionProductsDisplay data={filteredData} loading={loading} />
                                ) : (
                                    <p>No products found.</p> // Display message if no products are found
                                )
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AllPromotionProducts;
