import React, { useState } from 'react'
import { IoMdArrowDropdown } from "react-icons/io";


const Return = () => {
  // State to manage which question is expanded
  const [expandedQuestion, setExpandedQuestion] = useState(null);

  // Function to toggle the expanded question
  const toggleQuestion = (question) => {
    setExpandedQuestion(expandedQuestion === question ? null : question);
  };

  return (
    <div className='bg-white rounded-md w-full h-full p-6 shadow-md'>
        <div className='border border-gray-300 p-4 rounded-md'>
            {/* Question 1 */}
            <div className='border-b border-gray-300 my-3 pb-2'>
                <div className='flex justify-between cursor-pointer' onClick={() => toggleQuestion('payment')}>
                    <button className='text-black text-lg font-semibold'>What is Musto's return policy?</button>
                    <IoMdArrowDropdown className='text-black text-xl' />
                </div>
                {expandedQuestion === 'payment' && (
                    <div className='flex flex-col gap-2 p-3 mt-2'>
                        <p>Musto's return policy allows customers to return products within 14 days of purchase. The product must be in its original packaging, with all tags and labels attached. The customer is responsible for the return shipping costs, and the refund will be processed in the original payment method.</p>
                        <p>For more details, please refer to our <a href="https://www.musto.com/pages/return-policy" target="_blank" className="text-blue-500">Return Policy</a> page.</p>

                    </div>
                )}
            </div>

            {/* Question 2 */}
            <div className='border-b border-gray-300 my-3 pb-2'>
                <div className='flex justify-between cursor-pointer' onClick={() => toggleQuestion('updatePaymentDetails')}>
                    <button className='text-black text-lg font-semibold'>How can I file an online return?</button>
                    <IoMdArrowDropdown className='text-black text-xl' />
                </div>
                {expandedQuestion === 'updatePaymentDetails' && (
                    <div className='flex flex-col gap-2 p-3 mt-2'>
                        <p>To file an online return, follow these steps:</p>
                        <ul>
                            <li>Step 1: Log in to your Musto account and navigate to "Orders."</li>
                            <li>Step 2: Locate the order you want to return.</li>
                            <li>Step 3: Click on the "Return" button and follow the instructions.</li>
                        </ul>
                    </div>
                )}
            </div>

            {/* Question 3 */}
            <div className='border-b border-gray-300 my-3 pb-2'>
                <div className='flex justify-between cursor-pointer' onClick={() => toggleQuestion('availablePayment')}>
                    <button className='text-black text-lg font-semibold'>When I return an item on Musto, how will I get my money back?</button>
                    <IoMdArrowDropdown className='text-black text-xl' />
                </div>
                {expandedQuestion === 'availablePayment' && (
                    <div className='flex flex-col gap-2 p-3 mt-2'>
                        <p>If you paid via bank transfer, the refund will be credited directly to your bank account. It may take up to 8 business days for the bank to process it.</p>
                        <p>If you paid via credit card, the refund will be credited back to your credit card. This process typically takes 5-7 business days.</p>
                        <p>If you paid via cash on delivery, the refund will be credited to your Musto account. You can use this credit for future purchases.</p>
                        <p>If you selected a voucher as the refund method, the voucher will be processed immediately, and you'll receive your voucher code via email.</p>
                        
                        
                    </div>
                )}
            </div>

        </div>
    </div>
  )
}

export default Return
