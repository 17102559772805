// Define delivery fees for specific towns
const townDeliveryFees = {
    "Abura": 10.00,
    "Akotokyire": 10.00,
    "Amamoma": 10.00,
    "Apewosika": 10.00,
    "Ankaful village": 15.00,
    "Anto Essuekyir": 10.00,
    "Cape Coast": 15.00,
    "Duakor": 10.00,
    "Effutu": 15.00,
    "Ekon": 15.00,
    "Kakumdo": 10.00,
    "Koforidua": 10.00,
    "Kokoado": 10.00,
    "Kwaprow": 10.00,
    "Mpeasem": 15.00,
    "Nkanfoa": 15.00,
    "Nyinasin": 15.00,
    "Ola": 10.00,
    "Pedu": 10.00,
    // Add more towns and their fees as needed
};

export default townDeliveryFees;