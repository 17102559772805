import React, { useState } from 'react'
import { IoMdArrowDropdown } from "react-icons/io";


const Delivery = () => {
  // State to manage which question is expanded
  const [expandedQuestion, setExpandedQuestion] = useState(null);

  // Function to toggle the expanded question
  const toggleQuestion = (question) => {
    setExpandedQuestion(expandedQuestion === question ? null : question);
  };

  return (
    <div className='bg-white rounded-md w-full h-full p-6 shadow-md'>
        <div className='border border-gray-300 p-4 rounded-md'>
            {/* Question 1 */}
            <div className='border-b border-gray-300 my-3 pb-2'>
                <div className='flex justify-between cursor-pointer' onClick={() => toggleQuestion('deliverytime')}>
                    <button className='text-black text-lg font-semibold'>When can I expect to get my order?</button>
                    <IoMdArrowDropdown className='text-black text-xl' />
                </div>
                {expandedQuestion === 'deliverytime' && (
                    <div className='flex flex-col gap-2 mt-2 p-3'>
                        <p>The delivery date specified on the checkout page and in the email confirmation will be met by your order. Click here to find out more about our delivery schedule.</p>
                    </div>
                )}
            </div>

            {/* Question 2 */}
            <div className='border-b border-gray-300 my-3 pb-2'>
                <div className='flex justify-between cursor-pointer' onClick={() => toggleQuestion('deliveryaddress')}>
                    <button className='text-black text-lg font-semibold'>Can I change my delivery address after placing an order?</button>
                    <IoMdArrowDropdown className='text-black text-xl' />
                </div>
                {expandedQuestion === 'deliveryaddress' && (
                    <div className='flex flex-col gap-2 mt-2 p-3'>
                        <p>It's crucial to verify your delivery details, including your address and phone number, before finalizing your order.</p>
                        <p className='font-bold'>After the order is placed, changes to the delivery information cannot be made.</p>
                        <p>To ensure proper delivery, make sure to double-check the delivery details on the checkout page.</p>
                        
                    </div>
                )}
            </div>

            {/* Question 3 */}
            <div className='border-b border-gray-300 my-3 pb-2'>
                <div className='flex justify-between cursor-pointer' onClick={() => toggleQuestion('deliverycost')}>
                    <button className='text-black text-lg font-semibold'>What is the cost of delivery?</button>
                    <IoMdArrowDropdown className='text-black text-xl' />
                </div>
                    {expandedQuestion === 'deliverycost' && (
                    <div className='flex flex-col gap-2 mt-2 p-3'>
                        <p>The delivery fee is the cost associated with getting your package to your home or chosen pickup point.</p>
                        <p>The fee can vary based on factors like your location, the delivery option you choose, the shipping method, and the size or type of item.</p>
                        <p className='font-bold'>The delivery fee is not included in the product price; it is a separate charge.</p>
                        <p>To view the delivery fee, you can add the item to your cart and proceed to the checkout page.</p>
                        
                    </div>
                )}
            </div>

            {/* Question 4 */}
            <div className='border-b border-gray-300 my-3 pb-2'>
                <div className='flex justify-between cursor-pointer' onClick={() => toggleQuestion('deliverydelay')}>
                    <button className='text-black text-lg font-semibold'>If my delivery does not come within the anticipated time range, what should I do?</button>
                    <IoMdArrowDropdown className='text-black text-xl' />
                </div>
                {expandedQuestion === 'deliverydelay' && (
                    <div className='flex flex-col gap-2 mt-2 p-3'>
                        <p>If your delivery hasn't arrived within the estimated time, follow these steps:</p>
                        <ul>
                            <li>Step 1: Check the delivery status on the website.</li>
                            <li>Step 2: Contact the seller for updates.</li>
                            <li>Step 3: Check your email for any updates from the seller.</li>
                            <li>Step 4: If the delivery is delayed, you can contact the seller for more information.</li>
                            <li>Step 5: If the issue persists, you can contact the website's customer service.</li>
                            <li>Step 6: If the issue is with the seller, you can contact the website's customer service.</li>
                        </ul>
                        <p>We will assist you in ensuring your delivery arrives as soon as possible, so feel free to reach out with any concerns.</p>
                    </div>
                )}
            </div>
            {/* Question 5 */}
            <div className='border-b border-gray-300 my-3 pb-2'>
                <div className='flex justify-between cursor-pointer' onClick={() => toggleQuestion('deliverydamage')}>
                    <button className='text-black text-lg font-semibold'>What is the case where my delivery arrives damaged?</button>
                    <IoMdArrowDropdown className='text-black text-xl' />
                </div>
                {expandedQuestion === 'deliverydamage' && (
                    <div className='flex flex-col gap-2 mt-2 p-3'>
                        <p>If your item arrives damaged, you can initiate a return online by following these steps:</p>
                        <ul>
                            <li>Step 1: Log into your account and go to "Orders."</li>
                            <li>Step 2: Select the order with the item(s) you want to return.</li>
                            <li>Step 3: Click on the "Return" button.</li>
                            <li>Step 4: Follow the instructions to complete the return process.</li>
                        </ul>
                        <p className='font-bold'>It’s important to inspect your delivery upon arrival and report any damages right away.</p>
                    </div>
                )}
            </div>
        </div>
    </div>
  )
}

export default Delivery
