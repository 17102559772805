import React, { useState } from 'react';
import { toast } from 'react-toastify';
import SummaryApi from '../../common';

const ReturnAndRefundModal = ({ orderId, orderNumber, products, onClose }) => {
    const [reason, setReason] = useState('');
    const [refund, setRefund] = useState(false);
    const [exchange, setExchange] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch(SummaryApi.requestReturn.url, {
                method: SummaryApi.requestReturn.method,
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ orderId, orderNumber, reason, refund, exchange, products }),
            });

            const data = await response.json();
            if (data.success) {
                toast.success(data.message);
                onClose(); // Close the modal after successful submission
            } else {
                toast.error(data.message);
            }
        } catch (error) {
            console.error('Error submitting return request:', error);
            toast.error('Failed to submit return request.');
        }
    };

    return (
        <div className="modal">
            <div className="modal-content">
                <h2>Return and Refund for Order Number: {orderNumber}</h2>
                <form onSubmit={handleSubmit}>
                    <textarea
                        value={reason}
                        onChange={(e) => setReason(e.target.value)}
                        placeholder="Reason for return"
                        required className='border border-gray-200 p-1.5 rounded-md w-full'
                    />
                    <div className='flex gap-4'>
                        <label>
                            <input
                                type="checkbox"
                                checked={refund}
                                onChange={() => setRefund(!refund)}
                            />
                            Refund
                        </label>
                        <label>
                            <input
                                type="checkbox"
                                checked={exchange}
                                onChange={() => setExchange(!exchange)}
                            />
                            Exchange
                        </label>
                    </div>
                    <h3>Product</h3>
                    {products.map(product => (
                        <div key={product.productId} className="product-details flex gap-4">
                            <img src={product.productImage} alt={product.productName} className='w-20 h-20 object-cover' />
                            <div>
                                <p>Name: {product.productName}</p>
                                <p>Price: {product.sellingPrice}</p>
                                <p>Quantity: {product.quantity}</p>
                            </div>
                        </div>
                    ))}
                    <button type="submit" className='border border-red-700 p-1.5 rounded-md hover:bg-red-600 hover:text-white cursor-pointer mr-4'>Submit Return Request</button>
                    <button type="button" onClick={onClose} className='border border-red-700 p-1.5 rounded-md hover:bg-red-600 hover:text-white cursor-pointer'>Cancel</button>
                </form>
            </div>
        </div>
    );
};

export default ReturnAndRefundModal;