import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import SummaryApi from '../common';
import displayGHSCurrency from '../helpers/displayCurrency';
import { useSelector } from 'react-redux';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { tailChase } from 'ldrs'
import townDeliveryFees from '../helpers/townDeliveryFees';
import { getDistrictsByRegion, getTownsByDistrict } from '../helpers/ghanaLocations'; // Import your location helpers


const Checkout = () => {
    const user = useSelector(state => state?.user?.user);
    const location = useLocation();
    const navigate = useNavigate();
    const [cartItems, setCartItems] = useState([]);
    const [loading, setLoading] = useState(true);
    const [shippingInfo, setShippingInfo] = useState({
        contactName: '',
        streetAddress: '',
        mobile1: '',
        mobile2: '',
        region: '',
        district: '',
        town: '',
    });
    const [isProcessing, setIsProcessing] = useState(false);
    const [promoCode, setPromoCode] = useState(''); // State for promo code
    const [discount, setDiscount] = useState(0); // State for discount amount
    const [districts, setDistricts] = useState([]); // State for districts
    const [towns, setTowns] = useState([]); // State for towns
    tailChase.register()

    const productData = location.state?.product;

    useEffect(() => {
        const fetchCartItems = async () => {
            try {
                const response = await fetch(SummaryApi.addToCartProductView.url, {
                    method: SummaryApi.addToCartProductView.method,
                    credentials: 'include',
                    headers: {
                        'content-type': 'application/json',
                    },
                });

                const responseData = await response.json();
                if (responseData.success) {
                    setCartItems(responseData.data);
                } else {
                    console.error("Failed to fetch cart items:", responseData.message);
                }
            } catch (error) {
                console.error("Error fetching cart items:", error);
            } finally {
                setLoading(false);
            }
        };

        const fetchShippingInfo = async () => {
            try {
                const response = await fetch(SummaryApi.getDeliveryAddress.url, {
                    method: 'GET',
                    credentials: 'include',
                    headers: {
                        'content-type': 'application/json',
                    },
                });

                const responseData = await response.json();
                if (responseData.success) {
                    const { contactName, streetAddress, mobile1, mobile2, region, district, town } = responseData.data;
                    setShippingInfo({
                        contactName,
                        streetAddress,
                        mobile1,
                        mobile2,
                        region,
                        district,
                        town,
                    });
                } else {
                    console.error("Failed to fetch shipping info:", responseData.message);
                }
            } catch (error) {
                console.error("Error fetching shipping info:", error);
            }
        };

        if (!productData) {
            fetchCartItems();
        }
        fetchShippingInfo();
    }, [productData]);

    useEffect(() => {
        if (shippingInfo.region) {
            const fetchedDistricts = getDistrictsByRegion(shippingInfo.region);
            setDistricts(fetchedDistricts);
            setShippingInfo((prev) => ({ ...prev, district: '', town: '' })); // Reset district and town when region changes
        }
    }, [shippingInfo.region]);

    useEffect(() => {
        if (shippingInfo.district) {
            const fetchedTowns = getTownsByDistrict(shippingInfo.region, shippingInfo.district);
            setTowns(fetchedTowns);
        } else {
            setTowns([]); // Reset towns if no district is selected
        }
    }, [shippingInfo.district]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setShippingInfo((prev) => ({ ...prev, [name]: value }));
    };

    const handlePromoCodeChange = (e) => {
        setPromoCode(e.target.value);
    };

    const applyPromoCode = async () => {
        try {
            const response = await fetch(SummaryApi.validatePromoCode.url, {
                method: SummaryApi.validatePromoCode.method,
                credentials: 'include',
                headers: {
                    'content-type': 'application/json',
                },
                body: JSON.stringify({ code: promoCode }),
            });

            const responseData = await response.json();
            if (responseData.success) {
                setDiscount(responseData.discount); // Assuming the response contains the discount amount
                toast.success(`Promo code applied! Discount: ${displayGHSCurrency(responseData.discount)}`);
            } else {
                toast.error('Invalid promo code.');
            }
        } catch (error) {
            console.error("Error validating promo code:", error);
            toast.error('An error occurred while validating the promo code.');
        }
    };

    const handleCheckout = async () => {
        // Check if all required shipping information fields are filled
        const requiredFields = ['contactName', 'streetAddress', 'mobile1', 'region', 'district', 'town'];
        const isShippingInfoValid = requiredFields.every(field => shippingInfo[field]);

        if (!isShippingInfoValid) {
            toast.error('Please fill in all required delivery information fields.');
            return; // Prevent further execution if validation fails
        }

        setIsProcessing(true);
        const orderDetails = {
            userId: user._id,
            items: productData ? [productData] : cartItems.map(item => ({
                productId: item.productId, // Ensure this is the correct product ID
                quantity: item.quantity,
                sellingPrice: item.sellingPrice,
                productName: item.productName,
                productImage: item.productImage, // Ensure this is a single image if needed
                productColor: item.productColor || [],
            })),
            shipping: shippingInfo,
            totalAmount: finalTotal, // Apply discount to total amount
        };

        try {
            const response = await fetch(SummaryApi.placeOrder.url, {
                method: SummaryApi.placeOrder.method,
                credentials: 'include',
                headers: {
                    'content-type': 'application/json',
                },
                body: JSON.stringify(orderDetails),
            });

            const responseData = await response.json();
            if (responseData.success) {
                const orderNumber = responseData.order.orderNumber; // Adjust this line based on your API response structure
                await sendOrderEmail(user.email, 'mustophonesandaccessories@gmail.com', { ...orderDetails, orderNumber });
                toast.success('Order placed successfully!');
                navigate('/payment', { 
                    state: { 
                        totalPrice: orderDetails.totalAmount, 
                        shippingInfo: shippingInfo,
                        orderNumber: orderNumber,
                        products: orderDetails.items,
                    } 
                });
            } else {
                toast.error('Failed to place order: ' + responseData.message);
            }
        } catch (error) {
            console.error("Error placing order:", error);
            toast.error('An error occurred while placing the order.');
        } finally {
            setIsProcessing(false); // Reset loading state
        }
    };

    // Function to send order email
    const sendOrderEmail = async (userEmail, adminEmail, orderDetails) => {
        try {
            const response = await fetch(SummaryApi.sendOrderEmail.url, {
                method: SummaryApi.sendOrderEmail.method,
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ userEmail, adminEmail, orderDetails }),
            });

            const data = await response.json();
            if (!data.success) {
                console.error('Failed to send email:', data.message);
            }
        } catch (error) {
            console.error('Error sending email:', error);
        }
    };

    // Calculate total price, promotions, and delivery fees
    const calculateTotals = () => {
        let totalPrice = 0;
        let totalPromotion = 0;
        let totalDeliveryFee = 0; // Initialize delivery fee
        let totalFreeShipping = 0;

        const items = productData ? [productData] : cartItems;

        items.forEach(item => {
            const itemPrice = item.sellingPrice * (item.quantity || 1);
            
            // Add item price to total price
            totalPrice += itemPrice;

            // Check for promotion
            if (item.productId?.promotion?.value && item.productId?.promotion?.endDate) {
                const currentDate = new Date();
                const promotionEndDate = new Date(item.productId?.promotion?.endDate);
                const promotionPercentage = item.productId?.promotion?.value;

                // Only apply promotion if current date is before the promotion end date
                if (currentDate < promotionEndDate) {
                    const promotionValue = (itemPrice * promotionPercentage) / 100;
                    totalPromotion += promotionValue;
                }
            }

            // Check for free shipping threshold
            if (item.productId?.freeShippingThreshold && itemPrice >= item.productId?.freeShippingThreshold) {
                totalFreeShipping += totalDeliveryFee; // Subtract delivery fee if threshold is met
            }
        });

        // Calculate delivery fee based on the town only once
        const town = shippingInfo.town;
        totalDeliveryFee = townDeliveryFees[town] || 0; // Set delivery fee based on town

        return { totalPrice, totalPromotion, totalDeliveryFee, totalFreeShipping };
    };

    // Calculate final total
    const { totalPrice, totalPromotion, totalDeliveryFee, totalFreeShipping } = calculateTotals();
    const finalTotal = totalPrice - totalPromotion + totalDeliveryFee - totalFreeShipping - discount;

    if (loading) return <div>
        <div className="flex justify-center items-center h-screen">
                <l-tail-chase
                size="40"
                speed="1.75" 
                color="black" 
            ></l-tail-chase>
        </div>
    </div>;

    return (
        <div className="container mx-auto p-4">
            <ToastContainer />
            <h1 className="text-2xl font-bold mb-4">Checkout</h1>
            <div className="mb-6">
                <h2 className="text-xl font-semibold">Delivery Information</h2>
                <input
                    type="text"
                    name="contactName"
                    placeholder="Full Name"
                    value={shippingInfo.contactName}
                    onChange={handleChange}
                    className="border p-2 w-full mb-2"
                    required
                />
                <input
                    type="text"
                    name="streetAddress"
                    placeholder="Street Address"
                    value={shippingInfo.streetAddress}
                    onChange={handleChange}
                    className="border p-2 w-full mb-2"
                    required
                />
                <input
                    type="text"
                    name="mobile1"
                    placeholder="Mobile Number 1"
                    value={shippingInfo.mobile1}
                    onChange={handleChange}
                    className="border p-2 w-full mb-2"
                    required
                />
                <input
                    type="text"
                    name="mobile2"
                    placeholder="Mobile Number 2 (optional)"
                    value={shippingInfo.mobile2}
                    onChange={handleChange}
                    className="border p-2 w-full mb-2"
                />
                <select
                    className='select border p-2 w-full mb-2'
                    name="region"
                    value={shippingInfo.region}
                    onChange={handleChange}
                    required
                >
                    <option value="">Select Region</option>
                    {['Greater Accra', 'Ashanti', 'Central'].map((region) => (
                        <option key={region} value={region}>{region}</option>
                    ))}
                </select>
                <select
                    className='select border p-2 w-full mb-2'
                    name="district"
                    value={shippingInfo.district}
                    onChange={handleChange}
                    required
                >
                    <option value="">Select District</option>
                    {districts.map((district) => (
                        <option key={district.name} value={district.name}>{district.name}</option>
                    ))}
                </select>
                <select
                    className='select border p-2 w-full mb-2'
                    name="town"
                    value={shippingInfo.town}
                    onChange={handleChange}
                    required
                >
                    <option value="">Select Town</option>
                    {towns.map((town) => (
                        <option key={town} value={town}>{town}</option>
                    ))}
                </select>
            </div>

            

            {/* Order Summary */}
            <h2 className="text-xl font-semibold mb-2">Order Summary</h2>
            <div className="bg-white shadow-md border p-4 mb-4">
                {(productData ? [productData] : cartItems).map((item) => (
                    <div key={item._id} className="flex bg-slate-100 shadow-sm border-b pb-2 justify-between mb-2">
                        <div className="flex items-center gap-2 justify-between">
                        <div>
                            <img src={item.image || item.productId.productImage[0]} alt={item.name || item.productId.productName} className="w-20 h-20 object-cover mr-2" />
                        </div>
                        <div className='flex flex-col'>
                            <span>{item.name || item.productId.productName} </span>

                            <div>
                                {item.productColor && item.productColor.length > 0 && (
                                    <div className="text-sm text-gray-500">Colors: {item.productColor.join(', ')}</div>
                                )}
                            </div>

                            <span className='text-sm text-gray-500'>
                                (x{item.quantity || 1})
                            </span>
                            {/* <span className='text-sm text-gray-500'>
                                {displayGHSCurrency(finalProductTotal)}
                            </span> */}
                            
                        </div>
                        </div>
                        <div className='lg:mr-10 mr-2 mt-4 lg:mt-10 flex'>
                            <span>{displayGHSCurrency ((item.price || item.productId.sellingPrice) * (item.quantity || 1))}</span>
                        </div>
                        
                    </div>
                ))}
            </div>

            {/* Promo Code Section */}
            <div className="mb-4">
                <h2 className="text-xl font-semibold">Promo Code</h2>
                <input
                    type="text"
                    value={promoCode}
                    onChange={handlePromoCodeChange}
                    placeholder="Enter promo code"
                    className="border p-2 w-full mb-2"
                />
                <button
                    onClick={applyPromoCode}
                    className="bg-green-600 hover:bg-green-700 text-white p-2 rounded"
                >
                    Apply Promo Code
                </button>
            </div>

            {/* Total Amount */}
            <div className='bg-white shadow-md px-8 py-2 mb-2 w-full '>
                    <div className="flex justify-between font-bold">
                        <span>Total:</span>
                        <span>{displayGHSCurrency(totalPrice)}</span>
                    </div>
                    {discount > 0 && (
                        <div className="flex justify-between text-red-500">
                            <span>Discount:</span>
                            <span>-{displayGHSCurrency(discount)}</span>
                        </div>
                    )}
                    
                    {totalPromotion > 0 && (
                        <div className="flex justify-between text-red-500">
                            <span>Promotions:</span>
                            <span>-{displayGHSCurrency(totalPromotion)}</span>
                        </div>
                    )}

                    {totalDeliveryFee > 0 && (
                        <div className="flex justify-between text-green-500">
                            <span>Delivery Fee:</span>
                            <span>{displayGHSCurrency(totalDeliveryFee)}</span>
                        </div>
                    )}
                    <div className="flex justify-between text-xl font-bold">
                        <span>Final Total:</span>
                        <span>{displayGHSCurrency(finalTotal)}</span>
                    </div>
                </div>
            <div className='flex justify-end'>
                <button
                    onClick={handleCheckout}
                    className="bg-blue-600 hover:bg-blue-700 text-white w-full lg:w-40 p-2 rounded"
                    disabled={isProcessing} // Disable button while processing
                >
                    {isProcessing ? 'Processing...' : 'Confirm Order'} {/* Show loading text */}
                </button>
            </div>
        </div>
    );
};

export default Checkout;
