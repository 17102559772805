import SummaryApi from "../common"
import { toast } from "react-toastify"

const addToCart = async (e, productId, productName, sellingPrice, productImage, selectedColors) => {
    try {
        // Ensure selectedColors is an array
        const colorsToSend = Array.isArray(selectedColors) && selectedColors.length > 0 ? selectedColors : ["As seen"]; // Default value if no colors are selected

        const response = await fetch(SummaryApi.addToCartProduct.url, {
            method: SummaryApi.addToCartProduct.method,
            credentials: 'include',
            headers: {
                'content-type': 'application/json'
            },
            body: JSON.stringify({
                productId,
                productName,
                sellingPrice,
                productImage,
                colors: colorsToSend // Send the modified colors array
            })
        });

        const responseData = await response.json();
        if (responseData.success) {
            toast.success("Product added to cart!"); // Show success message
        } else {
            toast.error(responseData.message || "Error adding product to cart."); // Show error message
        }
    } catch (error) {
        console.error("Error adding to cart:", error);
        toast.error("An error occurred while adding to cart."); // Show error message
    }
};

export default addToCart
