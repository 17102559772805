import React from 'react'
import MyReturnRequests from '../../components/MyReturnRequests'
const ReturnAndRefund = () => {
  return (
    <div className='mr-9 lg:-ml-64 lg:max-w-7xl px-4 bg-white rounded-lg'>
        <MyReturnRequests />
    </div>
  )
}

export default ReturnAndRefund