import React, { useState } from 'react'
import ROLE from '../common/role'
import { IoIosClose } from "react-icons/io";
import SummaryApi from '../common';
import { toast } from 'react-toastify';

const ChangeUserRole = ({
    name,
    email,
    role,
    userId,
    onClose,
    callFunc
}) => {
    const [userRole, setUserRole] = useState(role)

    const handleOnChangeSelect = (e) => {
        setUserRole(e.target.value)

    }

    const updateUserRole = async () => {
        const fetchResponse = await fetch(SummaryApi.updateUser.url, {
            method : SummaryApi.updateUser.method,
            credentials : "include",
            headers : {
                "content-type" : "application/json"
            },
            body : JSON.stringify({
                userId : userId,
                role : userRole
            })
        })

        const responseData = await fetchResponse.json()

        if(responseData.success){
            toast.success(responseData.message)
            onClose()
            callFunc()
        }else{
            toast.error(responseData.message)
        }

    }

  return (
    <div className='fixed top-0 bottom-0 flex left-0 right-0 w-full h-full z-10 justify-between items-center bg-black/50'>
        <div className='mx-auto bg-white p-4 shadow-md w-full max-w-sm'>

            <button className='block ml-auto' onClick={onClose}>
                <IoIosClose size={24}/>
            </button>

            <h1 className='pb-4 text-lg font-medium'>Change User Role</h1>

            <p>Name : {name}</p>
            <p>Email : {email}</p>
            
            <div className='flex items-center justify-between my-4'>
                <p>Role : </p>
                <select className='border px-4 py-1' value={userRole} onChange={handleOnChangeSelect}>

                    {
                        Object.values(ROLE).map(el => {
                            return (
                                <option key={el} value={el}>{el}</option>
                            )
                        })
                    }
                    
                </select>
            </div>

            <button className='w-fit mx-auto block py-1 px-3 rounded-full bg-red-500 text-white hover:bg-red-700' onClick={updateUserRole}>Change Role</button>
        </div>
    </div>
  )
}

export default ChangeUserRole