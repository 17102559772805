import React, { useState, useEffect } from 'react'
import { IoIosArrowForward } from "react-icons/io";
import Accounts from '../help center/Accounts'; // Import the Accounts component
import Payment from '../help center/Payment'; // Import the Payment component
import Order from '../help center/Order'; // Import the Order component
import Delivery from '../help center/Delivery'; // Import the Delivery component
import Products from '../help center/Products'; // Import the Product component
import Return from '../help center/Return'; // Import the Return component
import { Link } from 'react-router-dom';

const HelpCenter = () => {
  const [activePage, setActivePage] = useState(null); // State to manage active content
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768); // Check for mobile screen size

  const handleNavigation = (page) => {
    setActivePage(page); // Set the active page based on the button clicked
  };

  const handleBack = () => {
    setActivePage(null); // Reset active page to show buttons again
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768); // Update mobile state on resize
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div className='container mx-auto p-10'>
      <div className='bg-red-200 h-36 p-4 text-lg'>
        <h1 className='text-black text-2xl font-bold'>Help Center</h1>
        <h1 className='text-black text-lg'>Hello, how may we assist you?</h1>
      </div>
      <div className='flex gap-10'>
        {/* Mobile View */}
        {isMobile ? (
         <div className='bg-white p-4 w-full grid justify-between h-96 mt-5 shadow-md'>
         <div className='flex justify-between w-72 items-center' onClick={() => handleNavigation('accounts')}>
           <button className='text-black text-2xl' >Account</button>
           <IoIosArrowForward className='text-black text-xl' />
         </div>
         <div className='flex justify-between items-center mt-3' onClick={() => handleNavigation('payment-issues')}>
           <button className='text-black text-2xl'>Payment Issues</button>
           <IoIosArrowForward className='text-black text-xl' />
         </div>
         <div className='flex justify-between items-center mt-3' onClick={() => handleNavigation('order-issues')}>
           <button className='text-black text-2xl'>Order Issues</button>
           <IoIosArrowForward className='text-black text-xl' />
         </div>
         <div className='flex justify-between items-center mt-3' onClick={() => handleNavigation('delivery-issues')}>
           <button className='text-black text-2xl'>Delivery Issues</button>
           <IoIosArrowForward className='text-black text-xl' />
         </div>
         <div className='flex justify-between items-center mt-3' onClick={() => handleNavigation('product-issues')}>
           <button className='text-black text-2xl'>Products</button>
           <IoIosArrowForward className='text-black text-xl' />
         </div>
         <Link to={"return-and-refund"} className='flex justify-between items-center mt-3' onClick={() => handleNavigation('return-and-refund')}>
           <button className='text-black text-2xl'>Return & Refund</button>
           <IoIosArrowForward className='text-black text-xl' />
         </Link>
       </div>
        ) : (
          // Desktop View
          <div className='bg-white p-4 w-80 h-full grid justify-between mt-5 shadow-md'>
            <div className='flex justify-between w-72 items-center cursor-pointer' onClick={() => handleNavigation('accounts')}>
              <button className='text-black text-2xl'>Account</button>
              <IoIosArrowForward className='text-black text-xl' />
            </div>
            <div className='flex justify-between items-center mt-3 cursor-pointer' onClick={() => handleNavigation('payment-issues')}>
              <button className='text-black text-2xl'>Payment Issues</button>
              <IoIosArrowForward className='text-black text-xl' />
            </div>
            <div className='flex justify-between items-center mt-3 cursor-pointer' onClick={() => handleNavigation('order-issues')}>
              <button className='text-black text-2xl'>Order Issues</button>
              <IoIosArrowForward className='text-black text-xl' />
            </div>
            <div className='flex justify-between items-center mt-3 cursor-pointer' onClick={() => handleNavigation('delivery-issues')}>
              <button className='text-black text-2xl'>Delivery Issues</button>
              <IoIosArrowForward className='text-black text-xl' />
            </div>
            <div className='flex justify-between items-center mt-3 cursor-pointer' onClick={() => handleNavigation('product-issues')}>
              <button className='text-black text-2xl'>Products</button>
              <IoIosArrowForward className='text-black text-xl' />
            </div>
            <div className='flex justify-between items-center mt-3 cursor-pointer' onClick={() => handleNavigation('return-and-refund')}>
              <button className='text-black text-2xl'>Return & Refund</button>
              <IoIosArrowForward className='text-black text-xl' />
            </div>
          </div>
        )}

        {/* Render the active content based on the state */}
        <div className={` w-full h-fit ${activePage ? 'absolute lg:hidden top-20 left-0 right-0  bottom-100  bg-white z-1' : ''}`}>
          {activePage && (
            <div className='m-2 w-full h-full'>
              <button onClick={handleBack} className='p-2 ml-2 bg-gray-500 text-white rounded'>
                Back
              </button>
              <>
              {activePage === 'accounts' && <Accounts />}
              {activePage === 'payment-issues' && <Payment />}
              {activePage === 'order-issues' && <Order />}
              {activePage === 'delivery-issues' && <Delivery />}
              {activePage === 'product-issues' && <Products />}
              {activePage === 'return-and-refund' && <Return />}
              </>
            </div>
          )}
        </div>
        <div className='mt-2 hidden lg:block w-full h-full'>
          {activePage && (
           <>
              {activePage === 'accounts' && <Accounts />}
              {activePage === 'payment-issues' && <Payment />}
              {activePage === 'order-issues' && <Order />}
              {activePage === 'delivery-issues' && <Delivery />}
              {activePage === 'product-issues' && <Products />}
              {activePage === 'return-and-refund' && <Return />}
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default HelpCenter;
