import React, { useEffect, useState } from 'react';

const CountdownTimer = ({ endDate: propEndDate }) => {
  const calculateTimeLeft = () => {
    const now = new Date(); // Current local time
    const endDate = new Date(propEndDate); // End date in UTC

    // Log the current time and end date
    

    const difference = endDate.getTime() - now.getTime(); // Calculate the difference in milliseconds

   

    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)), // Calculate days
        hours: Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
        minutes: Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60)),
        seconds: Math.floor((difference % (1000 * 60)) / 1000),
      };
    }
    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer);
  }, [propEndDate]); // Update dependency to the renamed parameter

  return (
    <div className="countdown">
      {timeLeft.days !== undefined ? (
        <div className='rounded-md p-2 flex gap-2 justify-between'>
            <p className='text-sm lg:text-lg bg-white rounded-md p-2'>{timeLeft.days}d</p>
            <p className='text-sm lg:text-lg bg-white rounded-md p-2'>{timeLeft.hours}h</p>
            <p className='text-sm lg:text-lg bg-white rounded-md p-2'>{timeLeft.minutes}m</p>
            <p className='text-sm lg:text-lg bg-white rounded-md p-2'>{timeLeft.seconds}s</p>
        </div>
      ) : (
        <span>Time's up!</span>
      )}
    </div>
  );
};

export default CountdownTimer;
