import React, { useState } from 'react';
import { FaStar } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { FaRegUserCircle } from "react-icons/fa";
import { FaStarHalfAlt } from 'react-icons/fa';
import { Link } from 'react-router-dom';



const ProductReviews = ({ reviews, onAddReview }) => {
  const [rating, setRating] = useState(0); // State to track the selected rating
  const [comment, setComment] = useState(''); // State to track the user's comment

  const user = useSelector(state => state?.user?.user);
  

  const calculateAverageRating = (reviews) => {
    if (reviews.length === 0) return 0; // No reviews, return 0
    const totalRating = reviews.reduce((acc, review) => acc + review.rating, 0);
    return totalRating / reviews.length; // Calculate average
  };
const averageRating = calculateAverageRating(reviews)
  // Function to handle the submission of the review
  const handleSubmit = (e) => {
    e.preventDefault();
    if (rating === 0 || comment.trim() === '') {
      alert("Please provide a rating and a comment.");
      return;
    }
    onAddReview(rating, comment); // Call the function passed from ProductDetails
    setRating(0);
    setComment('');
  };

  
  const totalReviews = reviews.length;

  
  const fiveStarCount = reviews.filter(review => review.rating === 5).length;
  const fourStarCount = reviews.filter(review => review.rating === 4).length;
  const threeStarCount = reviews.filter(review => review.rating === 3).length;
  const twoStarCount = reviews.filter(review => review.rating === 2).length;
  const oneStarCount = reviews.filter(review => review.rating === 1).length;

  return (
    <div className="bg-white p-4 rounded shadow-md lg:flex justify-between">
      <div className='flex flex-col gap-2 w-9/12'>
        <h3 className="text-lg font-bold">Customer Reviews</h3>
        {reviews.map((review, index) => (
          <>
          <div key={index} className="border-b py-2">
            <div className="flex items-center w-full">
              
              {review.profilePic && (
                <img src={review.profilePic} alt="User Profile" className="w-10 h-10 rounded-full mr-2" />
              )}
              {!review.profilePic && (
                <FaRegUserCircle className="w-10 h-10 rounded-full mr-2" />
              )}
              {/* Render stars based on the review rating */}
              <div className="flex items-center">
              {[...Array(5)].map((_, i) => (
                <FaStar key={i} className={i < review.rating ? 'text-red-500' : 'text-gray-300'} />
              ))}
              </div>
              <div className="flex flex-col gap-2 ml-2">
                <p className="ml-2">{review.comment}</p> {/* Display the review comment */}
                <p className="ml-2">{review.name}</p>
                
                {/* Convert createdAt to Date object if it's a string */}
                <p className="ml-2">{new Date(review.createdAt).toLocaleDateString()}</p> {/* Display the formatted date */}
              </div>
            </div>
          </div>
          
          </>
        ))}
        <Link to={{ pathname: "/all-product-reviews", state: { reviews } }} className='text-red-500'>View All Reviews</Link>
      </div>

      <div className='flex flex-col gap-2'>
        <div className='flex flex-col justify-center lg:flex p-2 bg-gray-200 gap-4 w-48 h-40'>
          <h3 className='text-md font-bold ml-2'>Average Rating: {averageRating.toFixed(1)}</h3>

          <div className='flex items-center justify-center gap-2'>
                {[...Array(5)].map((_, i) => {
                  if (i < Math.floor(averageRating)) {
                    return <FaStar key={i} className='text-red-500' />;
                  } else if (i === Math.floor(averageRating) && averageRating % 1 !== 0) {
                    return <FaStarHalfAlt key={i} className='text-red-500' />;
                  } else {
                    return <FaStar key={i} className='text-gray-300' />;
                  }
                })}
                
          </div>
          <p className='text-gray-600 ml-10 items-center'>({reviews.length} reviews)</p>
        </div>
        <div className='flex flex-col p-2 bg-gray-200 gap-2 w-60 rounded-md h-40'>
          

        <div className='flex gap-2 justify-between'>
          <p className='flex w-full -mr-5'>5 stars</p>
          <p className='text-gray-600 -ml-20'>({fiveStarCount})</p> 
          
          <div className='w-full flex bg-slate-300 h-2.5 border-2 rounded-full items-center mt-2 shadow-sm'>
            <div className='bg-red-500 w-full' style={{ width: totalReviews > 0 ? `${(fiveStarCount / totalReviews) * 100}%` : '0%', height: '5px', borderRadius: '5px' }}></div></div>
          </div>
          
          <div className='flex gap-2 justify-between'>
          <p className='flex w-full -mr-5'>4 stars</p>
          <p className='text-gray-600 -ml-20'>({fourStarCount})</p> 
          
          <div className='w-full flex bg-slate-300 h-2.5 border-2 rounded-full items-center mt-2 shadow-sm'>
            <div className='bg-red-500 w-full' style={{ width: totalReviews > 0 ? `${(fourStarCount / totalReviews) * 100}%` : '0%', height: '5px', borderRadius: '5px' }}></div></div>
          </div>

          <div className='flex gap-2 justify-between'>
          <p className='flex w-full -mr-5'>3 stars</p>
          <p className='text-gray-600 -ml-20'>({threeStarCount})</p> 
          
          <div className='w-full flex bg-slate-300 h-2.5 border-2 rounded-full items-center mt-2 shadow-sm'>
            <div className='bg-red-500 w-full' style={{ width: totalReviews > 0 ? `${(threeStarCount / totalReviews) * 100}%` : '0%', height: '5px', borderRadius: '5px' }}></div></div>
          </div>

          <div className='flex gap-2 justify-between'>
          <p className='flex w-full -mr-5'>2 stars</p>
          <p className='text-gray-600 -ml-20'>({twoStarCount})</p> 
          
          <div className='w-full flex bg-slate-300 h-2.5 border-2 rounded-full items-center mt-2 shadow-sm'>
            <div className='bg-red-500 w-full' style={{ width: totalReviews > 0 ? `${(twoStarCount / totalReviews) * 100}%` : '0%', height: '5px', borderRadius: '5px' }}></div></div>
          </div>
          <div className='flex gap-2 justify-between'>
          <p className='flex w-full -mr-5'>1 star</p>
          <p className='text-gray-600 -ml-20'>({oneStarCount})</p> 
          
          <div className='w-full flex bg-slate-300 h-2.5 border-2 rounded-full items-center mt-2 shadow-sm'>
            <div className='bg-red-500 w-full' style={{ width: totalReviews > 0 ? `${(oneStarCount / totalReviews) * 100}%` : '0%', height: '5px', borderRadius: '5px' }}></div></div>
          </div>

         </div>
      </div>
    </div>
  );
};

export default ProductReviews;
