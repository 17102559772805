import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom'; // Import useLocation and useNavigate
import { toast } from 'react-toastify';
import displayGHSCurrency from '../helpers/displayCurrency';
import { FaCheckCircle } from "react-icons/fa";


const PaymentSuccessful = () => {
	const location = useLocation(); // Get location object
	const navigate = useNavigate(); // Initialize navigate
	const { totalPrice } = location.state || { totalPrice: 0 }; // Retrieve totalPrice from state

	const handleContinueShopping = () => {
		// Navigate to the homepage or products page
		navigate('/'); // Adjust the path as needed
	};

	return (
		<div className="container mx-auto p-4 flex flex-col items-center justify-center">
			<h1 className="text-3xl font-bold mb-4 animate__animated animate__fadeIn">Payment Successful!</h1>
			<div className="flex flex-col items-center justify-center bg-white p-4 rounded-lg shadow-md">
                <FaCheckCircle className="text-green-500 text-6xl mb-4 animate__animated animate__fadeIn" />
                <p className="text-xl mb-4 animate__animated animate__fadeIn">Thank you for your purchase!</p>
                <p className="text-lg mb-4 animate__animated animate__fadeIn">Total Amount: <strong>{displayGHSCurrency(totalPrice)}</strong></p>
                <button
                    onClick={handleContinueShopping}
                    className="bg-red-600 hover:bg-red-700 text-white p-2 rounded animate__animated animate__fadeIn transition duration-300 ease-in-out transform hover:scale-105"
                >
                    Continue Shopping
                </button>
            </div>
		</div>
	);
};

export default PaymentSuccessful;