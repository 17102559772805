import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import SummaryApi from '../common';
import { RiLockPasswordFill } from "react-icons/ri"; // Importing icon for consistency
import './VerifyOtp.css'; // Import the CSS file for additional styles

const VerifyOtp = () => {
    const [otp, setOtp] = useState(Array(6).fill('')); // State for OTP digits
    const navigate = useNavigate();

    const handleChange = (e, index) => {
        const value = e.target.value;

        // Allow only numbers and limit to 1 character
        if (/^[0-9]?$/.test(value)) {
            const newOtp = [...otp];
            newOtp[index] = value;

            // Move to the next input if the current one is filled
            if (value && index < otp.length - 1) {
                document.getElementById(`otp-input-${index + 1}`).focus();
            }

            setOtp(newOtp);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Retrieve the email from local storage
        const email = localStorage.getItem('userEmail');

        const response = await fetch(SummaryApi.verifyOtp.url, {
            method: SummaryApi.verifyOtp.method,
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({ email, otp: otp.join('') }) // Join the OTP array into a string
        });

        const data = await response.json();

        if (data.success) {
            toast.success(data.message);
            navigate('/login'); // Redirect to login after successful verification
        } else {
            toast.error(data.message);
        }
    };

    return (
        <div className='container mx-auto p-4'>
            <div className='p-5 max-w-sm mx-auto rounded-full flex justify-center items-center gap-2'>
                <p className='text-center text-2xl font-bold -mt-4'>Enter the OTP code</p>
            </div>
            <form onSubmit={handleSubmit} className='bg-white p-5 w-full max-w-sm mx-auto shadow-lg rounded-lg'>
                <div className='mb-4 flex justify-center items-center'>
                    <p className='text-center text-lg font-bold'>Enter the OTP code sent to your email address</p>
                </div>
                <div className='mb-4 flex justify-between'>
                    {otp.map((digit, index) => (
                        <input
                            key={index}
                            type='text'
                            value={digit}
                            onChange={(e) => handleChange(e, index)}
                            onFocus={(e) => e.target.select()} // Select the input text on focus
                            id={`otp-input-${index}`} // Unique ID for each input
                            maxLength='1' // Limit input to 1 character
                            className='border border-gray-300 p-2 rounded w-12 text-center transition duration-300 ease-in-out focus:outline-none focus:ring-2 focus:ring-red-600'
                        />
                    ))}
                </div>
                <button type='submit' className='bg-red-600 text-white px-4 py-2 rounded transition duration-300 ease-in-out hover:bg-red-700 w-full shadow-lg mb-6 mt-4 cursor-pointer hover:scale-105'>
                    Verify OTP
                </button>
            </form>
        </div>
    );
};

export default VerifyOtp;
