import React, { useContext, useEffect, useRef, useState } from 'react'
import displayGHSCurrency from '../helpers/displayCurrency'
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import { calculateDiscountPercentage } from '../helpers/discountUtils'
import SummaryApi from '../common'
import { useSelector } from 'react-redux'

const ViewedProductsDisplay = ({ userId, heading }) => {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const loadingList = new Array(13).fill(null)
  const user = useSelector(state => state?.user?.user);
  const [scroll, setScroll] = useState(0)
  const scrollElement = useRef()
  const [showLeftButton, setShowLeftButton] = useState(false);
  const [showRightButton, setShowRightButton] = useState(false);

 

  const fetchViewedProducts = async () => {
    setLoading(true);
    try {
      if (!userId) {
        throw new Error("User ID is null or undefined");
      }
      const response = await fetch(`${SummaryApi.getViewedProducts.url.replace(':userId', userId)}`, {
        method: SummaryApi.getViewedProducts.method,
        credentials: 'include'
      });
      const result = await response.json();
      if (result.success) {
        setData(result.data); // Set the viewed products data
      } else {
        console.error('Failed to fetch viewed products:', result.message);
      }
    } catch (error) {
      console.error('Error fetching viewed products:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchViewedProducts() // Fetch viewed products on mount
  }, [userId])

  const scrollRight = () => {
    scrollElement.current.scrollLeft += 300
  }

  const scrollLeft = () => {
    scrollElement.current.scrollLeft -= 300
  }

  return (
    <div className='container bg-white mx-auto px-4 my-6 relative'>

      <h2 className='text-2xl font-bold py-4'>{heading}</h2>

      <div 
        className='flex items-center gap-4 md:gap-6 overflow-x-scroll scrollbar-none transition-all duration-300' 
        ref={scrollElement}
        onMouseEnter={() => {
          setShowLeftButton(true);
          setShowRightButton(true);
        }}
        onMouseLeave={() => {
          setShowLeftButton(false);
          setShowRightButton(false);
        }}
      >
        {showLeftButton && (
          <button style={{background: 'rgba(141, 142, 142, 0.5)'}} className=' shadow-md rounded-full p-1 absolute left-0 text-lg text-white  h-10 w-10 hidden md:block' onClick={scrollLeft}>
            <FaAngleLeft className='ml-2'/>
          </button>
        )}
        {showRightButton && (
          <button style={{background: 'rgba(141, 142, 142, 0.5)'}} className=' shadow-md rounded-full p-1 absolute right-0 text-lg text-white  h-10 w-10 hidden md:block' onClick={scrollRight}>
            <FaAngleRight className='ml-2'/>
          </button>
        )}

        {
          loading ? (
            loadingList.map((_, index) => (
              <div className='w-full min-w-[120px] md:min-w-40 max-w-[140px] md:max-w-40 bg-white rounded-sm shadow-md mb-4 lg:hover:shadow-2xl transition-all duration-300' key={index}>
                <div className='bg-slate-200 h-32 md:h-32 p-4 md:w-40 min-w-[120px] flex justify-center items-center relative animate-pulse'></div>
                <div className='p-4 grid gap-3'>
                  <h2 className='text-base md:text-lg font-medium text-ellipsis line-clamp-1 text-black p-1 py-2 animate-pulse rounded-full bg-slate-200 w-full'> </h2>
                  <p className='capitalize text-sm text-slate-500 p-1 py-2 animate-pulse rounded-full bg-slate-200 w-full'></p>
                  <div className='flex gap-3'>
                    <p className='text-sm text-slate-500 line-through p-1 py-2 animate-pulse rounded-full bg-slate-200 w-full'></p>
                    <p className='text-red-500 font-medium p-1 py-2 animate-pulse rounded-full bg-slate-200 w-full'></p>
                  </div>
                  <button className='text-white px-3 rounded-full text-sm animate-pulse bg-slate-200 w-full'></button>
                </div>
              </div>
            ))
          ) : (
            data.map((product) => {
              const discountPercentage = calculateDiscountPercentage(product.price, product.sellingPrice);
              return (
                <Link to={`/product/${product?._id}`} className='w-full min-w-[120px] md:min-w-40 max-w-[140px] md:max-w-40 bg-white rounded-sm shadow-md mb-4 lg:hover:shadow-2xl transition-all duration-300' key={product?._id}>
                  <div className='h-32 md:h-32 p-4 md:w-40 min-w-[120px] flex justify-center items-center relative'>
                    <img src={product.productImage[0]} className='object-scale-down h-full hover:scale-110 transition-all mix-blend-multiply' alt="no product" />
                    <div style={{background: 'rgba(255, 255, 255, 0.5)'}} className='p-1 absolute top-2 right-2 rounded-md'>
                      <p className='text-red-500 font-sm text-sm '>-{discountPercentage}%</p>
                    </div>
                    
                  </div>
                  <div className='p-4 grid '>
                    <h2 className='text-base md:text-lg font-medium text-ellipsis line-clamp-1 text-black'>{product?.productName}</h2>
                    <p className='capitalize text-slate-500'>{product?.category}</p>
                    <div className='grid md:grid '>
                      {product?.price && (
                        <p className='text-sm text-slate-500 line-through'>{displayGHSCurrency(product?.price)}</p>
                      )}
                      {product?.sellingPrice && (
                        <p className='text-red-600 md:font-medium'>{displayGHSCurrency(product?.sellingPrice)}</p>
                      )}
                    </div>
                    <button className='text-sm bg-red-600 hover:bg-red-700 text-white md:px-3 md:py-0.5 rounded-sm mt-2'>View Details</button>
                  </div>
                </Link>
              )
            })
          )
        }
      </div>
    </div>
  )
}

export default ViewedProductsDisplay
