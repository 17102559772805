import {createBrowserRouter} from "react-router-dom"
import App from "../App"
import Home from "../pages/Home"
import Login from "../pages/Login"
import ForgotPassword from "../pages/ForgotPassword"
import SignUp from "../pages/SignUp" 
import AdminPanel from "../pages/AdminPanel"
import AllUsers from "../pages/AllUsers"
import AllProducts from "../pages/AllProducts"
import CategoryProduct from "../pages/CategoryProduct"
import ProductDetails from "../pages/ProductDetails"
import Cart from "../pages/Cart"
import SearchProduct from "../pages/SearchProduct"
import UserProfile from "../pages/UserProfile"
import MyOders from "../pages/userProfilePages/MyOders"
import ReturnAndRefund from "../pages/userProfilePages/ReturnAndRefund"
import Profile from "../pages/userProfilePages/Profile"
import InTransit from "../pages/userProfilePages/InTransit"
import Buy from "../pages/Buy"
import CheckOutPage from "../pages/CheckOutPage"
import VerifyOtp from "../pages/VerifyOtp"
import DeliveryAddress from "../components/DeliveryAddress/DeliveryAddress"
import OrdersAll from "../pages/OrdersAll"
import PaymentPage from "../pages/PaymentPage"
import PaymentSuccessful from "../pages/PaymentSuccessful"
import AllBanners from "../pages/AllBanners"
import Recommendations from "../components/Recomdation"
import AllPromotionProducts from "../pages/AllPromotionProducts"
import ChatUs from "../pages/need help/ChatUs"
import HelpCenter from "../pages/need help/HelpCenter"
import ContactUs from "../pages/need help/ContactUs"
import Payment from "../pages/help center/Payment"
import Order from "../pages/help center/Order"
import Delivery from "../pages/help center/Delivery"
import Product from "../pages/help center/Products"
import Return from "../pages/help center/Return"
import Accounts from "../pages/help center/Accounts"
import AddPromoCode from "../pages/AddPromoCode"
import SalesReport from "../components/SalesReport"
import AllProductReviews from "../pages/AllProductReviews"
import AllReturnRequest from "../pages/AllReturnRequest"


const router = createBrowserRouter([

    {
        path: "/",
        element: <App/>,
        children: [
            {
                path: "",
                element: <Home/>
            },
            {
                path: "login",
                element: <Login/>
            },
            {
                path: "sign-up",
                element: <SignUp/>
            },
            {
                path: "product-category",
                element: <CategoryProduct/>
            },
            {
                path: "product/:id",
                element: <ProductDetails/>
            },
            {
                path: "recommendations",
                element: <Recommendations/>
            },
            {
                path: "cart",
                element: <Cart/>
            },
            {
                path: "search",
                element: <SearchProduct/>
            },
            {
                path: "all-promotion-products",
                element: <AllPromotionProducts/>
            },
            {
                path: "chat-us",
                element: <ChatUs/>
            },
            {
                path: "help-center",
                element: <HelpCenter/>,
                children: [
                    {
                        path: "payment-issues",
                        element: <Payment/>
                    },
                    {
                        path: "accounts",
                        element: <Accounts/>
                    },
                    {
                        path: "order-issues",
                        element: <Order/>
                    },
                    {
                        path: "delivery-issues",
                        element: <Delivery/>
                    },
                    {
                        path: "product-issues",
                        element: <Product/>
                    },
                    {
                        path: "return-and-refund",
                        element: <Return/>
                    },
                ]
            },
            {
                path: "contact-us",
                element: <ContactUs/>
            },
            {
                path: "userprofile",
                element: <UserProfile/>,
                children: [
                    {
                        path: "my-profile",
                        element: <Profile/>
                    },
                    {
                        path: "my-orders",
                        element: <MyOders/>
                    },
                    {
                        path: "shipping-address",
                        element: <DeliveryAddress/>
                    },
                    {
                        path: "return-and-refund",
                        element: <ReturnAndRefund/>
                    },
                    {
                        path: "intransit",
                        element: <InTransit/>
                    },
                ]
            },
            {
                path: "buy",
                element: <Buy/>
            },
            {
                path: "payment",
                element: <PaymentPage/>
            },
            {
                path: "verify-otp",
                element: <VerifyOtp/>
            },
            {
                path: "CheckOutPage",
                element: <CheckOutPage/>
            },
            {
                path: "paymentSuccessful",
                element: <PaymentSuccessful/>
            },
            {
                path: "ForgotPassword",
                element: <ForgotPassword/>
            },
            {
                path: "all-product-reviews",
                element: <AllProductReviews/>
            },
            {
                path: "admin-panel",
                element: <AdminPanel/>,
                children: [
                    {
                        path: "all-users",
                        element: <AllUsers/>
                    },
                    {
                        path: "all-products",
                        element: <AllProducts/>
                    },
                    {
                        path: "all-orders",
                        element: <OrdersAll/>
                    },
                    {
                        path: "all-banners",
                        element: <AllBanners/>
                    },
                    {
                        path: "add-promo-code",
                        element: <AddPromoCode/>
                    },
                    {
                        path: "sales-report",
                        element: <SalesReport/>
                    },
                    {
                        path: "all-return-request",
                        element: <AllReturnRequest/>
                    },
                ]
            }
        ]
    },

]);


export default router;
