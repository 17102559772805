import React, { useState, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import SummaryApi from '../common';
import { toast } from 'react-toastify';
import Context from '../context';
import { useDispatch } from 'react-redux';
import { setUserDetails } from '../store/userSlice';
import { FaEye, FaEyeSlash } from "react-icons/fa";
import '../components/test/loginstyle.css'

const Login = () => {
    const [showPassword, setShowPassword] = useState(true);
    const [data, setData] = useState({
        email: "",
        password: ""
    });
    const navigate = useNavigate();
    const { fetchUserDetails, fetchUserAddToCart } = useContext(Context);
    const dispatch = useDispatch();

    const handleOnChange = (e) => {
        const { name, value } = e.target;

        setData((prev) => {
            return {
                ...prev,
                [name]: value
            };
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const dataResponse = await fetch(SummaryApi.signIn.url, {
            method: SummaryApi.signIn.method,
            credentials: "include",
            headers: {
                "content-type": "application/json"
            },
            body: JSON.stringify(data)
        });

        const dataApi = await dataResponse.json();

        if (dataApi.success) {
            toast.success(dataApi.message);
            dispatch(setUserDetails(dataApi.data));
            navigate('/');
            fetchUserDetails();
            fetchUserAddToCart();
        }

        if (dataApi.error) {
            toast.error(dataApi.message);
        }
    };

    return (
        <div id='bg'>
        <section id='login' className=' shadow-lg'>
            <div >
                <div className=''>
                    <div className=''>
                        <form className='' onSubmit={handleSubmit}>
                            <h1>Login</h1>
                            <div className="inputbox flex mt-6">
                                <input
                                    type='email'
                                    required
                                    name='email'
                                    value={data.email}
                                    onChange={handleOnChange}
                                />
                                <label>Email</label>
                            </div>
                            <div className="inputbox flex mt-6">
                                <input
                                    type={showPassword ? 'text' : 'password'}
                                    required
                                    name='password'
                                    value={data.password}
                                    onChange={handleOnChange}
                                />
                                <div className='relative'></div>
                                <label>Password</label>
                                <div className='cursor-pointer text-center justify-center items-center flex text-xl w-10 h-10 right-0 text-white absolute' onClick={() => setShowPassword((prev) => !prev)}>
                                    {showPassword ? <FaEye /> : <FaEyeSlash />}
                                </div>
                            </div>
                            <div className="forget">
                                <label>
                                    <input type="checkbox" /> Remember Me
                                </label>
                                <Link to={'/ForgotPassword'}>Forget Password?</Link>
                            </div>
                            <button>Log in</button>
                            <div className="register">
                                <p>Don't have an account? <Link to={'/sign-up'}>Register</Link></p>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </section>
        </div>
    );
};

export default Login;