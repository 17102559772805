import React from 'react'
import PurchaseReportCharts from './PurchaseReportCharts'

const SalesReport = () => {
  return (
    <div className='px-4 h-[calc(100vh-70px)] overflow-y-scroll'>
      <PurchaseReportCharts />
    </div>
  )
}

export default SalesReport
