import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import SummaryApi from '../common';

const AdminReturnRequests = () => {
    const [returnRequests, setReturnRequests] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchReturnRequests = async () => {
            try {
                const response = await fetch(SummaryApi.getAllReturnRequests.url, {
                    method: 'GET',
                    credentials: 'include',
                });

                const data = await response.json();
                if (data.success) {
                    setReturnRequests(data.returnRequests);
                } else {
                    toast.error(data.message);
                }
            } catch (error) {
                console.error('Error fetching return requests:', error);
                toast.error('Failed to fetch return requests.');
            } finally {
                setLoading(false);
            }
        };

        fetchReturnRequests();
    }, []);

    const updateStatus = async (requestId, newStatus) => {
        try {
            const response = await fetch(`${SummaryApi.updateReturnRequestStatus.url}/${requestId}`, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ status: newStatus }),
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const data = await response.json();
            if (data.success) {
                setReturnRequests(returnRequests.map(request => 
                    request._id === requestId ? { ...request, status: newStatus } : request
                ));
                toast.success('Return request status updated successfully.');
            } else {
                toast.error(data.message);
            }
        } catch (error) {
            console.error('Error updating return request status:', error);
            toast.error('Failed to update return request status.');
        }
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <div className='p-4 container mx-auto bg-white rounded-lg'>
            <h2 className='text-2xl font-bold mb-4'>All Return Requests</h2>
            {returnRequests.map(request => (
                <div key={request._id} className='border border-gray-200 p-4 rounded-lg mb-4'>
                    <p>Order Number: {request.orderNumber}</p>
                    <p>User: {request.userId.name} ({request.userId.email})</p>
                    <p>Reason: {request.reason}</p>
                    <p>Status: {request.status}</p>
                    <p>Refund: {request.refund ? 'Yes' : 'No'}</p>
                    <p>Exchange: {request.exchange ? 'Yes' : 'No'}</p>
                    <h4>Products:</h4>
                    {request.products.map(product => (
                        <div key={product.productId} className='flex border border-gray-200 p-4 rounded-lg mb-4'>
                            <img src={product.productImage} alt={product.productName} className='w-20 h-20 object-cover' />
                            <div>
                                <p>Name: {product.productName}</p>
                                <p>Price: {product.sellingPrice}</p>
                                <p>Quantity: {product.quantity}</p>
                            </div>
                        </div>
                    ))}
                    <div>
                        <button onClick={() => updateStatus(request._id, 'Approved')} className='bg-green-500 text-white px-4 py-2 rounded mr-2'>Approve</button>
                        <button onClick={() => updateStatus(request._id, 'Rejected')} className='bg-red-500 text-white px-4 py-2 rounded'>Reject</button>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default AdminReturnRequests;
