import React from 'react';
import { MdClose } from 'react-icons/md';

const ConfirmationModal = ({ message, onConfirm, onCancel }) => {
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white p-4 rounded-md w-1/3">
        <button onClick={onCancel} className="absolute top-2 right-2">
          <MdClose size={24} />
        </button>
        <h2 className="text-lg font-bold">Confirm Action</h2>
        <p>{message}</p>
        <div className="flex justify-end mt-4">
          <button onClick={onCancel} className="bg-gray-300 text-black p-2 rounded-md mr-2">
            Cancel
          </button>
          <button onClick={onConfirm} className="bg-red-500 text-white p-2 rounded-md">
            Confirm
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationModal;
