const productColor = [
    {id: 1, label: 'White', value: 'white'},
    {id: 2, label: 'Black', value: 'black'},
    {id: 3, label: 'Blue', value: 'blue'},
    {id: 4, label: 'Green', value: 'green'},
    {id: 5, label: 'Pink', value: 'pink'},
    {id: 6, label: 'Orange', value: 'orange'},
    {id: 7, label: 'Yellow', value: 'yellow'},
    {id: 8, label: 'Purple', value: 'purple'},
    {id: 9, label: 'Brown', value: 'brown'},
    {id: 10, label: 'Grey', value: 'grey'},
    {id: 11, label: 'Red', value: 'red'},
    {id: 12, label: 'As seen', value: 'as seen'},
]
export default productColor