import React, { useState } from 'react'
import { GrClose } from "react-icons/gr";
import uploadImage from '../helpers/uploadimage';
import Displayimage from './Displayimage';
import { MdDelete } from "react-icons/md";
import SummaryApi from '../common';
import { toast } from 'react-toastify';
import { MdCloudUpload } from "react-icons/md";
import './UploadBanners.css'; // Import your CSS file for additional styling


const UploadBanners = ({
    onClose,
    fetchData
  }) => {
    const [data, setData] = useState({
      bannerImage: [],
      isPromotion: false, // New field for promotion
      title: '', // New field for title
      ctaText: '', // New field for CTA text
      ctaUrl: '', // New field for CTA URL
    })
    const [openFullScreenImage, setOpenFullScreenImage] = useState(false)
    const [fullScreenImage, setFullScreenImage] = useState("")


  // upload banner
  const handleUploadBanner = async (e) => {
    const file = e.target.files[0]

    const uploadImageCloudinary = await uploadImage(file)

    setData((preve) => {
      return {
        ...preve,
        bannerImage : [...preve.bannerImage,uploadImageCloudinary.url]
      }
    })

  }

  const handleDeleteBannerImage = async (index) => {
    const newBannerImage = [...data.bannerImage]
    newBannerImage.splice(index,1)

    setData((prev)=>{
      return {
        ...prev,
        bannerImage : [...newBannerImage]
      }
    })

  }


  // upload banner 
  const handleSubmit = async (e) => {
    e.preventDefault()

  
    const response = await fetch(SummaryApi.uploadBanner.url,{
      method : SummaryApi.uploadBanner.method,
      credentials : "include",
      headers : {
        "content-type" : "application/json",
      },
      body : JSON.stringify(data)
    })

    const responseData = await response.json()

    if(responseData.success){
      toast.success(responseData?.message)
      onClose()
      fetchData()
    }

    if(responseData.error){
      toast.error(responseData?.message)
    }

  }

  return (
    <div className="modal">
      <div className='bg-white p-4 rounded-md max-w-2xl w-full h-full max-h-[80%] overflow-y-auto'>
        <div className='flex justify-between items-center pb-3'>
          <h2 className='text-lg font-bold'>upload Banner</h2>
          <div className='w-fit ml-auto text-2xl hover:text-red-600 cursor-pointer' onClick={onClose}>
            <GrClose/>
          </div>
        </div>

        <label htmlFor="bannerImage" className='mt-3'>Banner Image : </label>
        <label htmlFor="uploadImageInput" className='cursor-pointer'>
        <div className='p-2 bg-slate-100 border rounded-md h-32 w-full flex justify-center items-center'>
            <div className='text-slate-500 flex flex-col justify-normal items-center gap-2'>
              <span className='text-4xl'><MdCloudUpload /></span>
              <p className='text-sm'>Upload banner image</p>
              <input type="file" id="uploadImageInput" className='hidden' onChange={handleUploadBanner} />
            </div>
          </div>
        </label>
        
        <form className='grid p-4 gap-2 h-10 pb-5' onSubmit={handleSubmit}>
        <div>
          <label>
            <input
              type="checkbox"
              checked={data.isPromotion}
              onChange={(e) => setData({ ...data, isPromotion: e.target.checked })}
            />
            Is this a promotional banner?
          </label>
        </div>
        <div>
          <label htmlFor="bannerTitle">Title:</label>
          <input
            type="text"
            id="bannerTitle"
            value={data.title}
            onChange={(e) => setData({ ...data, title: e.target.value })}
            className='border rounded-md p-2 w-full'
          />
        </div>
        <div>
          {
            data?.bannerImage[0] ? (
              
              <div className='flex items-center gap-2'>
                {data.bannerImage.map((el,index)=>{
                  return(
                    <div className='relative group'>
                  <img 
                  src={el} 
                  alt={el} 
                  width={80} 
                  height={80} 
                  className='bg-slate-100 border cursor-pointer'
                  onClick={()=>{
                    setOpenFullScreenImage(true)
                    setFullScreenImage(el)
                  }}
                  />
                  <div className='absolute top-0 right-0 text-white bg-red-500 p-1 rounded-full cursor-pointer hidden group-hover:block' onClick={()=>handleDeleteBannerImage(index)}>
                  <MdDelete/>
                  </div>
                  </div>

                )
                })}
              </div>
            ) : (
                <p className='text-xs text-red-500'>*Please upload banner image </p>
            )
          }
        </div>
        <div>
          <label htmlFor="ctaText">CTA Text:</label>
          <input
            type="text"
            id="ctaText"
            value={data.ctaText}
            onChange={(e) => setData({ ...data, ctaText: e.target.value })}
            className='border rounded-md p-2 w-full'
          />
        </div>
        <div>
          <label htmlFor="ctaUrl">CTA URL:</label>
          <input
            type="text"
            id="ctaUrl"
            value={data.ctaUrl}
            onChange={(e) => setData({ ...data, ctaUrl: e.target.value })}
            className='border rounded-md p-2 w-full'
          />
        </div>
        <div>
        <button className='px-3 py-2 bg-red-500 text-white mb-10 hover:bg-red-700'>Upload Banner</button>
        </div>

      </form>
        
      </div>

      {/* display image */}
      {
        openFullScreenImage && (
          <Displayimage onClose={()=>setOpenFullScreenImage(false)} imgUrl={fullScreenImage}/>
        )
      }
      
    </div>
  )
}

export default UploadBanners
