import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';

const Buy = () => {
  const location = useLocation();
  const totalAmount = location.state?.totalAmount || 0; // Retrieve total amount from state
  const [phoneNumber, setPhoneNumber] = useState('');
  const [paymentStatus, setPaymentStatus] = useState('');

  const handlePayment = (e) => {
    e.preventDefault();

    // Check if PaystackPop is available
    if (!window.PaystackPop) {
      setPaymentStatus('Payment system is not available. Please try again later.');
      return;
    }

    // Convert amount to pesewas (smallest currency unit in Ghana)
    const amountInPesewas = totalAmount * 100;

    // Paystack payment handler
    const handler = window.PaystackPop.setup({
      key: 'pk_test_a2f241fe026d4ce094f030fbfb4c93e602745541', // Replace with your Paystack public key
      email: 'addaecollins88@gmail.com', // Replace with customer's email
      amount: amountInPesewas,
      currency: 'GHS', // Set currency to Ghana Cedis
      ref: '' + Math.floor(Math.random() * 1000000000 + 1), // Generate a unique reference
      callback: function (response) {
        setPaymentStatus('Payment successful! Reference: ' + response.reference);
      },
      onClose: function () {
        setPaymentStatus('Payment was not completed.');
      },
    });

    handler.openIframe();
  };

  return (
    <div className="container mx-auto p-6 bg-gray-50 rounded-lg shadow-md">
      <h1 className="text-3xl font-bold mb-6 text-center text-gray-800">Buy</h1>
      <form onSubmit={handlePayment} className="space-y-4">
        <div>
          <label className="block text-lg font-semibold text-gray-700">Phone Number:</label>
          <input
            type="tel"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            required
            className="border border-gray-300 p-3 rounded-lg w-full transition duration-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>
        <button
          type="submit"
          className="w-full bg-green-500 text-white p-3 rounded-lg transition duration-300 hover:bg-green-600 shadow-md"
        >
          Pay with Paystack
        </button>
      </form>
      {paymentStatus && <p className="mt-4 text-center text-lg font-semibold text-gray-700">{paymentStatus}</p>}
    </div>
  );
};

export default Buy;