import React, { useState } from 'react'
import { MdEdit } from "react-icons/md";
import { MdDelete } from "react-icons/md"; // Import delete icon
import AdminEditProduct from './AdminEditProduct';
import displayGHSCurrency from '../helpers/displayCurrency';
import SummaryApi from '../common';
import ConfirmationModal from './ConfirmationModal';

const AdminProductCard = (
    {data, fetchdata, isSelected, onSelect}
) => {
    const [editProduct, setEditProduct] = useState(false)
    const [isConfirmOpen, setIsConfirmOpen] = useState(false); 

    // Function to handle product deletion
    const deleteProduct = async (id) => {
        const response = await fetch(SummaryApi.deleteProduct.url, {
            method: SummaryApi.deleteProduct.method,
            credentials: 'include',
            headers: {
                'content-type': 'application/json'
            },
            body: JSON.stringify({
                _id: id
            })
        });

        const responseData = await response.json();

        if (responseData.success) {
            fetchdata(); // Refresh the product list
        } else {
            console.error('Failed to delete the product');
        }
    }

    const handleDeleteClick = () => {
        setIsConfirmOpen(true); // Open confirmation modal
    };

    const handleConfirmDelete = () => {
        deleteProduct(data._id); // Proceed with deletion
        setIsConfirmOpen(false); // Close confirmation modal
    };

    return (
        <div className='bg-white p-4 rounded-md'>
            <input
                type="checkbox"
                checked={isSelected}
                onChange={() => onSelect(data._id)}
                className="mr-2"
            />
            <div className='w-40'>
                <div className='w-32 h-32 flex items-center justify-center'>
                    <img src={data?.productImage[0]} alt='product' className='object-fill mx-auto h-full '/>
                </div>
                <h1 className='text-ellipsis line-clamp-2'>{data.productName}</h1>

                <div>
                    <p className='font-semibold'>
                        { displayGHSCurrency(data.sellingPrice) }
                    </p>

                    <div className='flex justify-between'>
                        <div className='w-fit ml-auto p-2 rounded-full bg-green-200 hover:bg-green-600 hover:text-white cursor-pointer' onClick={() => setEditProduct(true)}>
                            <MdEdit />
                        </div>
                        <div className='w-fit ml-2 p-2 rounded-full bg-red-200 hover:bg-red-600 hover:text-white cursor-pointer' onClick={handleDeleteClick}>
                            <MdDelete />
                        </div>
                    </div>
                </div>
            </div>

            { editProduct && <AdminEditProduct productData={data} onClose={() => setEditProduct(false)} fetchdata={fetchdata}/> }

            {/* Confirmation Modal */}
            {isConfirmOpen && (
                <ConfirmationModal
                    message={`Are you sure you want to delete ${data.productName}?`}
                    onConfirm={handleConfirmDelete}
                    onCancel={() => setIsConfirmOpen(false)}
                />
            )}
        </div>
    )
}

export default AdminProductCard