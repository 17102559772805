import React, { useState, useEffect } from 'react';
import axios from 'axios';
import SummaryApi from '../common';
import UploadBanners from '../components/UploadBanners';
import { MdDelete } from "react-icons/md"; // Import delete icon
import './AllBanners.css'; // Import your CSS file for additional styling
import AdminBannerManager from '../components/AdminBannerManager';

const AllBanners = () => {
    const [openUploadBanner, setOpenUploadBanner] = useState(false);
    const [allBanner, setAllBanner] = useState([]);

    const fetchAllBanner = async () => {
        const response = await fetch(SummaryApi.getBanners.url);
        const dataResponse = await response.json();
        setAllBanner(dataResponse?.data || []);
    };

    const handleDeleteBanner = async (bannerId) => {
        try {
            await axios.delete(`${SummaryApi.deleteBanner.url}/${bannerId}`); // Use DELETE method and append the bannerId
            fetchAllBanner(); // Refresh the banner list after deletion
        } catch (error) {
            console.error('Error deleting banner:', error);
        }
    };

    useEffect(() => {
        fetchAllBanner();
    }, []);

    return (
        <div>
            <div className='bg-white py-2 px-4 flex justify-between items-center'>
                <h2 className='font-bold text-lg'>All Banners</h2>
                <button className='border-2 border-red-600 text-red-600 hover:bg-red-600 hover:text-white transition-all duration-300 ease-in-out px-3 py-1 rounded-md' onClick={() => setOpenUploadBanner(true)}>Upload Banner</button>
            </div>

            {openUploadBanner && (
                <UploadBanners onClose={() => setOpenUploadBanner(false)} fetchData={fetchAllBanner} />
            )}

            <div className="banner-list grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 p-4">
                {allBanner.map((banner) => (
                    <div key={banner._id} className="banner-item relative border rounded-md overflow-hidden shadow-lg">
                        <img src={banner.imageUrl} alt={banner.imageUrl} className="w-full h-32 object-cover" />
                        <div className="absolute top-2 right-2">
                            <button onClick={() => handleDeleteBanner(banner._id)} className="text-white hover:text-red-700">
                                <MdDelete size={24} />
                            </button>
                        </div>
                    </div>
                ))}
            </div>
            {/* <AdminBannerManager /> */}
        </div>
    );
};

export default AllBanners;
