import React, { useState, useEffect } from 'react'
import headphonebanner from '../../assest/products/earphones/boAt Rockerz 510.webp'
import SummaryApi from '../../common'
import { useNavigate } from 'react-router-dom'

const HeadPhoneBanner = () => {
    const [categoryProduct, setCategoryProduct] = useState([])
    const navigate = useNavigate()

    const fetchCategoryProduct = async () => {
        const response = await fetch(SummaryApi.categoryProduct.url)
        const dataResponse = await response.json()
        setCategoryProduct(dataResponse.data)
    }

    useEffect(() => {
        fetchCategoryProduct()
    },[])
  return (
    <div className='container mx-auto min-h-[550px] lg:-mt-20 mt-6 lg:-mb-20 flex justify-center items-center'>
        <div className=' bg-red-500 rounded-lg shadow-md'>
            <div className='grid grid-cols-1 lg:grid-cols-3 gap-6 items-center text-white'>
                <div className='p-8 lg:p-6'>
                    <p data-aos="slide-right" className='text-xl font-bold'>100% Satisfaction Guaranteed</p>
                    <h1 data-aos="fade-up" className='uppercase text-4xl font-bold'>Headphones</h1>
                    
                </div>
                <div data-aos="zoom-in"  className='h-full flex items-center '>
                    <img src={headphonebanner} alt="headphonebanner" className='w-[250px] md:w-[340px] object-cover scale-125 mx-auto drop-shadow-2xl'/>
                </div>
                <div className='flex flex-col gap-4 p-8 lg:p-6'>
                    <p data-aos="fade-down" className='text-xl font-bold'>boAt Rockerz 510</p>
                    <p data-aos="fade-up" className='text-5xl lg:text-4xl font-bold'>Amazing Offers</p>
                    <p data-aos="fade-up" className='text-sm tracking-wide leading-5'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam, quos.</p>
                
                    <div data-aos="fade-up" className='flex justify-center'>
                        <button onClick={() => navigate("/product-category?category="+categoryProduct[1]?.category)} className='bg-white text-red-500 font-bold hover:text-red-700 transition-all duration-300 hover:scale-105 px-4 py-2 rounded-md'>Shop Now</button>
                    </div>
                </div>

            </div>
        </div>
    </div>
  )
}

export default HeadPhoneBanner