const backendDomin = process.env.REACT_APP_BACKEND_DOMAIN

const SummaryApi = {
    signUp : {
        url : `${backendDomin}/api/signup`,
        method : "post"
    },
    signIn : {
        url : `${backendDomin}/api/signin`,
        method : "post"
    },
    current_user : {
        url : `${backendDomin}/api/user-details`,
        method : "get"
    },
    logout_user : {
        url : `${backendDomin}/api/userLogout`,
        method : "get"
    },
    allUser : {
        url : `${backendDomin}/api/all-users`,
        method : "get"
    },
    updateUser : {
        url : `${backendDomin}/api/update-user`,
        method : "post"
    },
    updateUserProfile : {
        url : `${backendDomin}/api/update-user-profile`,
        method : "post"
    },
    uploadProduct : {
        url : `${backendDomin}/api/upload-product`,
        method : "post"
    },
    allProduct : {
        url : `${backendDomin}/api/get-product`,
        method : "get"
    },
    updateProduct : {
        url : `${backendDomin}/api/update-product`,
        method : "post"
    },
    categoryProduct : {
        url : `${backendDomin}/api/get-categoryProduct`,
        method : "get"
    },
    categoryWiseProduct : {
        url : `${backendDomin}/api/category-product`,
        method : "post"
    },
    productDetails : {
        url : `${backendDomin}/api/product-details`,
        method : "post"
    },
    addToCartProduct : {
        url : `${backendDomin}/api/addtocart`,
        method : "post"
    },
    addToCartProductCount : {
        url : `${backendDomin}/api/countAddToCartProduct`,
        method : "get"
    },
    addToCartProductView : {
        url : `${backendDomin}/api/view-card-product`,
        method : "get"
    },
    updateCartProduct : {
        url : `${backendDomin}/api/update-cart-product`,
        method : "post"
    },
    deleteCartProduct : {
        url : `${backendDomin}/api/delete-cart-product`,
        method : "post"
    },
    deleteProduct : {
        url : `${backendDomin}/api/delete-product`,
        method : "post"
    },
    searchProduct : {
        url : `${backendDomin}/api/search`,
        method : "get"
    },
    filterProduct : {
        url : `${backendDomin}/api/filter-product`,
        method : "post"
    },
    verifyOtp : {
        url : `${backendDomin}/api/verify-otp`,
        method : "post"
    },
    sendOtp : {
        url : `${backendDomin}/api/send-otp`,
        method : "post"
    },
    VerifyOtp : {
        url : `${backendDomin}/api/verify-otp`,
        method : "post"
    },
    resetPassword : {
        url : `${backendDomin}/api/reset-password`,
        method : "post"
    },
    addDeliveryAddress : {
        url : `${backendDomin}/api/addDeliveryAddress`,
        method : "post"
    },
    updateDeliveryAddress : {
        url : `${backendDomin}/api/update-delivery-address`,
        method : "post"
    },
    getDeliveryAddress : {
        url : `${backendDomin}/api/getDeliveryAddress`,
        method : "get"
    },
    placeOrder : {
        url : `${backendDomin}/api/place-order`,
        method : "post"
    },
    viewMyOrder : {
        url : `${backendDomin}/api/view-my-order`,
        method : "get"
    },
    deleteOrder : {
        url : `${backendDomin}/api/delete-order`,
        method : "post"
    },
    getCompletedOrders : {
        url : `${backendDomin}/api/get-completed-orders`,
        method : "get"
    },
    updateOrderStatus : {
        url : `${backendDomin}/api/update-order-status`,
        method : "post"
    },
    updateOrderPaymentStatus : {
        url : `${backendDomin}/api/update-order-payment-status`,
        method : "post"
    },
    uploadBanner : {
        url : `${backendDomin}/api/upload-banner`,
        method : "post"
    },
    getBanners : {
        url : `${backendDomin}/api/get-banners`,
        method : "get"
    },
    deleteBanner : {
        url : `${backendDomin}/api/delete-banner`,
        method : "delete"
    },
    addReview : {
        url : `${backendDomin}/api/add-review`,
        method : "post"
    },
    getReviews : {
        url : `${backendDomin}/api/get-reviews`,
        method : "get"
    },
    getPersonalizedRecommendations : {
        url : `${backendDomin}/api/recommendations`,
        method : "get"
    },
    viewProduct : {
        url : `${backendDomin}/api/view-product`,
        method : "post"
    },
    subscribeEmail : {
        url : `${backendDomin}/api/subscribe-email`,
        method : "post"
    },
    getOrderTracking : {
        url : `${backendDomin}/api/order-tracking/:orderId`,
        method : "get"
    },
    updateOrderTrackingStatus : {
        url : `${backendDomin}/api/update-order-status`,
        method : "post"
    },
    getPromotedProducts : {
        url : `${backendDomin}/api/get-promoted-products`,
        method : "get"
    },
    getViewedProducts : {
        url : `${backendDomin}/api/get-viewed-products/:userId`,
        method : "get"
    },
    chatBot : {
        url : `${backendDomin}/api/chat`,
        method : "post"
    },
    getFAQs : {
        url : `${backendDomin}/api/get-faqs`,
        method : "get"
    },
    populateFAQs : {
        url : `${backendDomin}/api/populate-faqs`,
        method : "post"
    },
    sendOrderEmail : {
        url : `${backendDomin}/api/send-order-email`,
        method : "post"
    },
    addPromoCode : {
        url : `${backendDomin}/api/add-promo-code`,
        method : "post"
    },
    getPromoCodes : {
        url : `${backendDomin}/api/get-promo-codes`,
        method : "get"
    },
    validatePromoCode : {
        url : `${backendDomin}/api/validate-promo-code`,
        method : "post"
    },
    updateProductStock : {
        url : `${backendDomin}/api/update-product-stock`,
        method : "post"
    },
    getPurchaseHistory : {
        url : `${backendDomin}/api/get-purchase-history`,
        method : "get"
    },
    getProductsDetailsHistory : {
        url : `${backendDomin}/api/get-products-details-history`,
        method : "get"
    },
    uploadStaticBanner : {
        url : `${backendDomin}/api/upload-static-banner`,
        method : "post"
    },
    createDynamicBanner : {
        url : `${backendDomin}/api/create-dynamic-banner`,
        method : "post"
    },
    requestReturn : {
        url : `${backendDomin}/api/request-return`,
        method : "post"
    },
    getReturnRequests : {
        url : `${backendDomin}/api/get-return-requests`,
        method : "get"
    },
    getAllReturnRequests : {
        url : `${backendDomin}/api/get-all-return-requests`,
        method : "get"
    },
    updateReturnRequestStatus : {
        url : `${backendDomin}/api/update-return-request-status`,
        method : "post"
    }
}

export default SummaryApi