import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import SummaryApi from '../common'; // Adjust the import based on your API setup
import { RiLockPasswordFill } from "react-icons/ri";
import { MdLockPerson } from "react-icons/md";
import { FaEye, FaEyeSlash } from 'react-icons/fa';


const ForgotPassword = () => {
	const [email, setEmail] = useState('');
	const [otp, setOtp] = useState(Array(6).fill('')); // Array for OTP digits
	const [newPassword, setNewPassword] = useState('');
	const [step, setStep] = useState(1); // To track the current step
	const [countdown, setCountdown] = useState(300); // 5 minutes countdown
	const [otpSent, setOtpSent] = useState(false); // Track if OTP has been sent
	const navigate = useNavigate(); // Initialize useNavigate
	const [showPassword, setShowPassword] = useState(true);

	useEffect(() => {
		let timer;
		if (otpSent && countdown > 0) {
			timer = setInterval(() => {
				setCountdown(prev => prev - 1);
			}, 1000);
		} else if (countdown === 0) {
			setOtpSent(false); // Reset OTP sent status after countdown
		}
		return () => clearInterval(timer);
	}, [otpSent, countdown]);

	const handleSendOtp = async (e) => {
		e.preventDefault();
		const response = await fetch(SummaryApi.sendOtp.url, {
			method: SummaryApi.sendOtp.method,
			headers: {
				"Content-Type": "application/json"
			},
			body: JSON.stringify({ email })
		});

		const data = await response.json();
		if (data.success) {
			toast.success(data.message);
			setStep(2); // Move to OTP verification step
			setOtpSent(true); // Mark OTP as sent
			setCountdown(60); // Reset countdown to 5 minutes
		} else {
			toast.error(data.message);
		}
	};

	const handleVerifyOtp = async (e) => {
		e.preventDefault();
		const otpString = otp.join(''); // Join the OTP array into a string
		const response = await fetch(SummaryApi.VerifyOtp.url, {
			method: SummaryApi.VerifyOtp.method,
			headers: {
				"Content-Type": "application/json"
			},
			body: JSON.stringify({ email, otp: otpString })
		});

		const data = await response.json();
		if (data.success) {
			toast.success(data.message);
			setStep(3); // Move to password reset step
		} else {
			toast.error(data.message);
		}
	};

	const handleResetPassword = async (e) => {
		e.preventDefault();
		const response = await fetch(SummaryApi.resetPassword.url, {
			method: SummaryApi.resetPassword.method,
			headers: {
				"Content-Type": "application/json"
			},
			body: JSON.stringify({ email, newPassword })
		});

		const data = await response.json();
		if (data.success) {
			toast.success(data.message);
			navigate('/login'); // Navigate to the sign-in page after resetting the password
		} else {
			toast.error(data.message);
		}
	};

	const handleRegenerateOtp = async () => {
		const response = await fetch(SummaryApi.sendOtp.url, {
			method: SummaryApi.sendOtp.method,
			headers: {
				"Content-Type": "application/json"
			},
			body: JSON.stringify({ email })
		});

		const data = await response.json();
		if (data.success) {
			toast.success(data.message);
			setCountdown(60); // Reset countdown to 5 minutes
			setOtpSent(true); // Mark OTP as sent
		} else {
			toast.error(data.message);
		}
	};

	const handleOtpChange = (e, index) => {
		const value = e.target.value;
		if (/^[0-9]?$/.test(value)) {
			const newOtp = [...otp];
			newOtp[index] = value;
			setOtp(newOtp);

			// Move to the next input if the current one is filled
			if (value && index < otp.length - 1) {
				document.getElementById(`otp-input-${index + 1}`).focus();
			}
		}
	};

	return (
		<div className='container mx-auto p-4'>
			{step === 1 && (
				<div>
					<div className='p-5 max-w-sm mx-auto rounded-full flex justify-center items-center gap-2'>
						<h1 className='bg-white w-10 h-10 text-red-600 text-3xl font-bold mb-6 text-center rounded-full shadow-lg'>1</h1>
						<p className='text-center text-lg font-bold -mt-4'>Enter your email address</p>
					</div>
					<form onSubmit={handleSendOtp} className='bg-white p-5 w-full max-w-sm mx-auto shadow-lg rounded-lg'>
						<div className='w-full h-full mx-auto'>
							<div className='flex justify-center items-center mb-4'>
								<MdLockPerson size={60} className='text-red-600 bg-slate-300 p-2 shadow-lg rounded-full'/>
							</div>
							<p className='text-center text-xl font-bold'>Recover your password</p>
							<p className='text-center text-sm '>You can request a password reset below. We will send an OTP code to the email address, please make sure it is correct.</p>
						</div>
						<div className='mb-4 mt-6'>
							<label className='block text-sm font-medium'>Email:</label>
							<input
								type='email'
								value={email}
								onChange={(e) => setEmail(e.target.value)}
								required
								className='border border-gray-300 p-2 rounded w-full'
							/>
						</div>
						<button type='submit' className='bg-red-600 text-white px-4 py-2 rounded  transition duration-300 ease-in-out hover:bg-red-700 w-full shadow-lg mb-6 mt-4 cursor-pointer hover:scale-105'>
							Request Password Reset
						</button>
					</form>
				</div>
			)}
			{step === 2 && (
				<div>
					<div className='p-5 max-w-sm mx-auto rounded-full flex justify-center items-center gap-2'>
						<h1 className='bg-white w-10 h-10 text-red-600 text-3xl font-bold mb-6 text-center rounded-full shadow-lg'>2</h1>
						<p className='text-center text-lg font-bold -mt-4'>Enter the OTP code</p>
					</div>
					<form onSubmit={handleVerifyOtp} className='bg-white p-5 w-full max-w-sm mx-auto shadow-lg rounded-lg'>
						<div className='mb-4 flex justify-center items-center'>
							<p className='text-center text-lg font-bold'>Enter the OTP code sent to your email address</p>
						</div>
						<div className='mb-4 flex justify-between'>
							{otp.map((digit, index) => (
								<input
									key={index}
									type='text'
									value={digit}
									onChange={(e) => handleOtpChange(e, index)}
									onFocus={(e) => e.target.select()} // Select the input text on focus
									id={`otp-input-${index}`} // Unique ID for each input
									maxLength='1' // Limit input to 1 character
									className='border border-gray-300 p-2 rounded w-12 text-center transition duration-300 ease-in-out focus:outline-none focus:ring-2 focus:ring-red-600'
								/>
							))}
						</div>
						<div className='-mb-4 flex justify-between items-center'>
							<div className='flex mb-4 justify-center items-center gap-2'>
							{countdown > 0 ? (
            <>
                <p className=''>Time remaining: {Math.floor(countdown / 60)}:{(countdown % 60).toString().padStart(2, '0')}</p>
            </>
        ) : (
            <button 
                type='button' 
                onClick={handleRegenerateOtp} 
                className='bg-blue-600 text-white px-4 py-2 rounded transition duration-300 ease-in-out hover:bg-blue-700'
            >
                Regenerate OTP
									</button>
								)}	
							</div>
						
						<button type='submit' className='bg-red-600 text-white px-4 py-2 rounded transition duration-300 ease-in-out hover:bg-red-700 shadow-lg mb-6 mt-4 cursor-pointer hover:scale-105'>
							Verify OTP
						</button></div>
					</form>
				</div>
			)}
			{step === 3 && (
				<div>
					<div className='p-5 max-w-sm mx-auto rounded-full flex justify-center items-center gap-2'>
						<h1 className='bg-white w-10 h-10 text-red-600 text-3xl font-bold mb-6 text-center rounded-full shadow-lg'>3</h1>
						<p className='text-center text-lg font-bold -mt-4'>Create new password</p>
					</div>
					<form onSubmit={handleResetPassword} className='bg-white p-5 w-full max-w-sm mx-auto shadow-lg rounded-lg'>
						<div className='mb-4'>
							<div className='flex justify-center items-center mb-4'>
								<RiLockPasswordFill size={60} className='text-red-600 bg-slate-300 p-2 shadow-lg rounded-full'/>
							</div>
							<p className='text-center text-xl font-bold'>Create new password</p>
							<p className='text-center text-sm mb-4'>Please enter your new password below. Make sure it is correct.</p>
							<label className='block text-sm font-medium'>New Password:</label>
							{/* <input
								type='password'
								value={newPassword}
								onChange={(e) => setNewPassword(e.target.value)}
								required
								className='border border-gray-300 p-2 rounded w-full'
							/> */}
							<div className='bg-slate-100 p-2 rounded-2xl flex focus-within:shadow-md'>
                    <input type={showPassword ? 'text' : 'password'} placeholder='Enter your password' name='password' value={newPassword} onChange={(e) => setNewPassword(e.target.value)} className='w-full h-10 bg-transparent p-2 outline-none rounded-full'/>
                        <div className='cursor-pointer text-center justify-center items-center flex text-xl' onClick={() => setShowPassword((prev) => !prev)}>
                            <span>
                                {
                                    showPassword ? (
                                    <FaEye/>
                                ) 
                                :
                                ( 
                                    <FaEyeSlash/>
                                )
                                }
                            
                            </span>
								</div>
							</div>
						</div>
						<button type='submit' className='bg-red-600 text-white px-4 py-2 rounded  transition duration-300 ease-in-out hover:bg-red-700 w-full shadow-lg mb-6 mt-4 cursor-pointer hover:scale-105 hover:shadow-xl'>
							Reset Password
						</button>
					</form>
				</div>
			)}
		</div>
	);
};

export default ForgotPassword;