import React from 'react'

const FLogo = ({w,h}) => {
  return (
    <svg version="1.1" viewBox="0 0 2048 857" width={w} height={h} xmlns="http://www.w3.org/2000/svg">
<path transform="translate(1705,91)" d="m0 0 6 3-2 10-6 26-5 31-2 13h119l3 4-1 20h-124l-1 14-13 122-1 15v40l3 31 5 23 6 16 6 9 6 5 6 2 7-1 8-6 7-8 10-17 10-23 10-31 7-29 5-27 5-37 9 2 5 4 1 2v10l-3 25 7-21 8-17 10-16 11-13 11-11 14-10 16-8 15-5 16-3h31l15 3 14 5 12 7 11 9 10 12 8 14 6 17 3 13v5l24-2 12-3h3v23l-13 4-20 3h-4v17l-3 33-4 22-7 24-8 20-8 16-10 16-13 15-10 9-14 9-16 7-17 4-8 1h-17l-14-2-15-5-14-8-10-8-8-8-11-15-8-15-7-19-4-16-2-12-2-33-8 34-9 29-11 27-9 17-10 14-9 10-12 8-9 3-12 1-15-3-12-5-10-6-12-11-9-10-9-14-8-16-7-18-7-28-4-27-2-29v-25l2-34 4-33 6-31 7-26 7-20 2-6h-92l-3-3 1-20 1-1 103-1 13-23 12-17 12-14 15-15 13-10zm209 173-10 5-5 4-9 13-8 17-6 19-6 27-4 26-1 12v20l2 20 5 21 5 12 7 12 9 8 8 3h10l10-4 8-8 7-12 7-21 4-20 3-26 2-43-1-29-3-21-6-18-6-9-6-5-8-3z" fill="#FD130B"/>
<path transform="translate(902,229)" d="m0 0h16l13 4 10 5 9 7 8 9 8 15 5 17 3 19 1 17v17l-2 36-5 57-1 29 2 18 4 11 4 5 5 2h8l8-5 3-1 2-4 7-10 9-17 9-24 8-27 8-36 6-35 5-41 3-1 7 2 5 5v14l-6 50-6 40-7 36-8 30-7 20-9 20-9 15-7 9-11 8-13 6-14 3h-18l-17-4-15-8-12-11-9-14-6-16-3-15-1-9v-34l4-38 5-37 2-24v-16l-2-13-4-10-4-6-6-4h-9l-9 6-9 11-10 19-8 22-4 16-3 51-6 113h-70l-3-3v-11l9-170v-30l-3-11-4-7-4-4-10-2-10 3-6 8-5 16-3 26-10 179-1 6h-70l-2-3v-17l14-255 1-11 1-1h68l4 4-1 24 7-12 7-9 5-5 8-7 11-5 8-2h21l12 4 10 6 8 8 6 10 6 14 4 18 12-22 10-14 12-13 12-9 11-5z" fill="#FD130B"/>
<path transform="translate(1090,246)" d="m0 0h70l4 4-2 27-8 105-3 43v16l2 15 4 12 5 5 5 2 6-1 6-4 8-14 7-21 6-27 5-30 15-131 1-1h69l4 4-2 34-10 136-2 27v21l2 6 9 1 8-4 9-10 8-13 10-21 8-22 8-29 6-27 5-32 2-18 1-3 9 2 5 5 1 2-1 14-6 42-7 38-10 40-10 30-11 25-8 14-9 12-11 11-14 7-9 2-14-1-11-3-14-7-12-11-9-13-6-13-5-15-7 21-8 17-8 11-7 7-10 5-9 2-14-1-15-4-13-7-9-7-9-9-10-15-7-16-5-19-2-17v-15l18-181z" fill="#FD130B"/>
<path transform="translate(212,215)" d="m0 0h9l12 3 10 6 7 7 15 23 11 18 11 17 14 22 2 1 2-4 8-7 10-4 4-1h14l9 3 2-5 6-8 8-6 8-4 4-1h15l12 4 5 2 7-8 10-7 6-2 12-1 10 2 10 5 8 8 28 44 13 21 12 23 7 15 9 25 7 26 4 23 2 19 1 19 1 53 1 14-4 6-5 2-7-1-5-4-2-7-1-7-1-51-2-31-4-26-6-24-8-23-8-18-13-25-16-25-21-33-5-4-9-2-8 4-3 6v9l7 12 13 20 5 10-2 7-4 4-2 1h-9l-6-5-12-19-15-23-7-10-6-4h-10l-5 3-4 5-1 3v8l7 12 24 36v9l-5 6-2 1h-8l-5-3-7-10-15-24-13-20-8-5h-8l-7 4-4 8 1 10 14 22 16 25 4 7v8l-3 5-4 2h-9l-6-5-12-19-70-110-12-19-8-12-1-3-5-2-6-2-9 1-9 6-3 6-1 8 4 10 11 17 14 22 16 25 12 19 16 25 56 88 6 10v9l-4 5-2 1h-11l-7-8-15-24-14-22-3-4v-2l-10 6-11 8-4 5-2 6v7l4 10 16 26 12 19 9 11 9 10 10 9 13 9 16 9 23 9 31 9 20 7 10 7 8 8 10 16 2 4-1 9-6 5h-10l-5-4-12-18-9-6-16-5-38-12-21-9-15-8-14-10-15-13-11-12-11-15-28-44-4-8-2-6-1-11 3-14 6-11 5-6 15-11 8-5-2-6-11-17-14-22-24-38-14-22-12-19-8-13-3-11v-11l2-9 5-10 8-9 12-7z" fill="#D41919"/>
<path transform="translate(1431,213)" d="m0 0h19l12 4 11 6 12 11 6 8 4 8 3 12-1 10-4 8-7 6-8 3h-15l-15-5-11-7-12-12-6-10-3-4 2 14 5 13 11 21 20 30 26 36 16 23 13 22 8 16 6 17 4 15 1 7 4-5 9-16 8-19 7-23 7-31 5-33 4-37 1-5 9 2 5 4 1 2v9l-7 55-6 35-6 27-7 24-8 21-10 19-8 11-9 10-9 8-14 8-13 5-14 3-18 1-14-2-18-6-13-7-11-8-10-9-8-8-10-14-9-17-4-13-2-15v-9l3-14 6-8 4-2h5l3 3-1 7-1 4v15l4 12 7 11 8 8 13 8 11 3h11l11-4 4-5 2-7v-8l-4-15-10-24-22-45-14-28-11-26-5-17-2-12v-22l3-15 8-16 9-10 11-7 12-4z" fill="#FD130B"/>
<path transform="translate(1548,684)" d="m0 0h20l16 4 9 5 10 9 7 11 4 14 1 7v16h-85l3 12 5 8 8 5 7 2h11l9-3 7-6 3-5h8l20 3 2 1-3 9-8 11-8 7-12 6-11 3-8 1h-9l-14-2-14-5-10-7-8-8-7-14-3-11-1-8v-10l3-17 3-8-18 3-8 5h-2l-2 5-3 8-1 65-1 1h-32v-114l1-1h29l1 1 1 15 4-5 10-7 11-4 11-1v26h2l2-5 6-7 10-7 8-4zm4 23-9 3-8 7-4 8v4h52l-2-8-6-9-8-4-4-1z" fill="#D41919"/>
<path transform="translate(3,279)" d="m0 0h37l3 4 6 22v3h130l1 1-1 11-14 63-1 1h-93l4 13 87 1 6 3 5 6 2 8-2 7-5 5-7 3-8-1-6-4-4-7v-9l-2 1h-46v7l-4 8-6 4-8 1-8-3-5-7-1-8 1-7-12-42-20-72-30-1-2-2v-6zm73 130-2 2v6l4 3 5-2 2-5-3-4zm80 0-3 4 2 5 5 2 4-3v-6l-2-2z" fill="#D41919"/>
<path transform="translate(1083,684)" d="m0 0h13l16 4 10 6 10 9 7 11 5 16 1 8v12l-3 16-5 12-7 9-10 9-11 5-12 3h-15l-12-3-10-6-5-5-1 67h-32v-171h23l8 1v13h2l2-4 7-6 9-4zm-6 27-11 4-8 7-5 10-2 14 3 13 6 9 8 6 8 3h12l10-4 8-7 5-10 1-4v-16l-4-11-7-8-10-5-4-1z" fill="#D31919"/>
<path transform="translate(773,630)" d="m0 0h32l1 65-1 4 10-8 8-4 13-3h13l13 3 11 7 7 10 4 16v81h-33l-1-76-4-8v-2l-5-2-6-2h-9l-9 3-5 4-4 8-1 3-1 11v60l-1 1h-32z" fill="#D31919"/>
<path transform="translate(1195,630)" d="m0 0h32l1 2v60l-1 7 10-8 8-4 13-3h14l12 3 8 5 5 5 5 7 4 16v81h-33l-1-76-4-8-5-4-6-2h-9l-9 3-5 4-4 8-2 13-1 62h-32z" fill="#D31919"/>
<path transform="translate(1362,684)" d="m0 0h19l16 4 11 6 7 6 7 11 4 10 2 10v19h-85l3 11 5 8 5 4 10 4h11l9-3 8-7 2-4h8l20 3 2 1-1 5-6 11-10 10-16 8-17 3-17-1-15-4-11-6-10-9-6-10-4-10-2-9v-22l4-15 5-10 11-12 10-6 10-4zm3 23-9 3-7 6-5 10v3h52l-2-9-4-6-5-4-8-3z" fill="#D31919"/>
<path transform="translate(944,684)" d="m0 0h16l15 3 12 5 11 8 7 8 7 14 3 15v13l-2 12-5 13-7 9-8 8-16 8-12 3-9 1h-10l-17-3-14-6-10-8-7-8-6-12-3-11-1-13 2-16 4-11 6-10 5-6 13-9 14-5zm1 26-8 3-6 4-6 8-4 11v16l4 11 7 8 8 5 7 2h8l10-3 9-6 6-10 2-6v-18l-4-11-8-9-8-4-4-1z" fill="#D41919"/>
<path transform="translate(710,684)" d="m0 0h15l14 3 10 5 7 6 6 11 1 6-21 5h-5l-3-6-1-3-4-2-6-2h-12l-6 3-2 3v6l1 3 5 2 24 7 16 6 9 7 6 8 3 11v9l-2 9-6 10-10 7-11 4-14 2h-10l-17-3-10-4-9-7-6-9-3-9 7-3 18-5h3l4 8 1 3 4 2 9 3h9l8-3 2-3v-8l-4-5-13-5-20-6-14-7-7-8-3-6-1-5v-11l3-10 6-8 11-7 11-3z" fill="#D41919"/>
</svg>

  )
}

export default FLogo