import React, { useEffect, useState } from 'react'
import { FaRegUserCircle } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import { Link, Outlet, useNavigate } from 'react-router-dom'
import ROLE from '../common/role'
import { FaStore } from "react-icons/fa";
import SummaryApi from '../common'
import { toast } from 'react-toastify'
import { setUserDetails } from '../store/userSlice'
import { FaShippingFast } from "react-icons/fa";
import { RiRefund2Fill } from "react-icons/ri";
import { ImProfile } from "react-icons/im";
import { MdLocationPin } from "react-icons/md";
import { FaArrowRight, FaArrowLeft } from "react-icons/fa";




const UserProfile = () => {
    const dispatch = useDispatch()
    const user = useSelector(state => state?.user?.user)
    const navigate = useNavigate()
    const [isMobile, setIsMobile] = useState(false)
    const [currentPage, setCurrentPage] = useState('profile') // Track the current page
    const [loading, setLoading] = useState(true); // Add loading state

    const handleLogout = async() => {
        const fetchData = await fetch(SummaryApi.logout_user.url,{
          method : SummaryApi.logout_user.method,
          credentials : "include"
        })
    
        const data = await fetchData.json()
    
        if(data.success){
          toast.success(data.message)
          dispatch(setUserDetails(null))
          navigate("/")
          
        }
    
        if(data.error){
          toast.error(data.message)
        }
    
      }

    useEffect(() => {
        // Check if user is defined and loading is complete
        if (user) {
            if (user.role !== ROLE.GENERAL) {
                navigate("/");
            }
            setLoading(false); // Set loading to false once user is checked
        } else {
            setLoading(false); // Set loading to false if user is not found
        }

        // Check for mobile screen size
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768); // Adjust the breakpoint as needed
        };
        window.addEventListener('resize', handleResize);
        handleResize(); // Initial check
        return () => window.removeEventListener('resize', handleResize);
    }, [user, navigate])

    const handleNavigation = (page) => {
        setCurrentPage(page);
        navigate(page); // Navigate to the corresponding route
    };

    const renderMobileView = () => {
        if (currentPage === 'profile') {
            return (
              <div className='bg-white min-h-48 w-full -mb-72 max-w-96 items-center justify-center customShadow'>
                <div className="grid font-bold text-lg  gap-6 p-8 ">
                    <div onClick={() => handleNavigation('my-profile')} className='flex gap-8'>
                      <ImProfile className=' ml-4' />
                    <button className="-ml-6 px-2 -mt-2 hover:bg-slate-100 rounded-md">My Profile</button>
                      <FaArrowRight className='ml-28'/>
                    </div>
                    <div onClick={() => handleNavigation('my-orders')} className='flex gap-8'>
                      <FaStore className='  ml-4' />
                    <button  className="-ml-6  px-2 -mt-2 hover:bg-slate-100 rounded-md">My Orders</button>
                      <FaArrowRight className='ml-28'/>
                    </div>
                    <div onClick={() => handleNavigation('intransit')} className='flex gap-8'>
                      <FaShippingFast className='  ml-4' />
                    <button className="-ml-6  px-2 -mt-2 hover:bg-slate-100 rounded-md">In Transit</button>
                      <FaArrowRight className='ml-32'/>
                    </div>
                    <div onClick={() => handleNavigation('shipping-address')} className='flex gap-8'>
                      <MdLocationPin className='  ml-4' />
                    <button className="-ml-6  px-2 -mt-2 hover:bg-slate-100 rounded-md">Delivery Address</button>
                      <FaArrowRight className='ml-14'/>
                    </div>
                    <div onClick={() => handleNavigation('return-and-refund')} className='flex gap-8'>
                      <RiRefund2Fill className='  ml-4' />
                    <button className="-ml-6  px-2 -mt-1 hover:bg-slate-100 rounded-md">Return & Refund</button>
                      <FaArrowRight className='ml-14'/>
                    </div>
                    <button onClick={handleLogout} className="my-2 p-2 bg-red-600 text-white rounded">Logout</button>
                </div>
              </div>
            );
        } else {
            return (
                <div className="flex flex-col bg-white py-6 justify-center">
                    <button onClick={() => setCurrentPage('profile')} className="my-2 p-2 ml-4 w-10 bg-gray-500 text-white rounded"><FaArrowLeft />
                    </button>
                    <Outlet />
                </div>
            );
        }
    };

    // Show a loading message or spinner while loading
    if (loading) {
        return <div>Loading...</div>; // You can replace this with a spinner or loading component
    }

  return (
    <div className='container mx-auto min-h-[calc(100vh-110px)] md:flex grid-cols-1'>
        <div className="container mx-auto px-4 ml-9 static">  
        <div className='bg-white min-h-48  mt-2 max-w-80 customShadow hidden md:block'>
            <div className='h-32 flex bg-slate-100 justify-center items-center flex-col'>
                <div className='text-5xl cursor-pointer relative flex justify-center'>
                {
                    user?.profilePic ? (
                    <img src={user?.profilePic} alt={user?.name} className='w-20 h-20 rounded-full' />
                    ) : (
                    <FaRegUserCircle/>
                    )
                }

                </div>
                <div>
                    <p className='capitalize text-lg font-semibold'>{user?.name}</p>
                    <p className='text-sm'>{user?.role}</p>
                </div>
            </div>

                {/* navigation */}
            <div>
                <nav className='grid font-bold text-lg  gap-5 p-8 ml-4'>
                    <Link to={"my-profile"} className='px-2 py-1 hover:bg-slate-100 rounded-md'>
                    <ImProfile className='absolute m-1 -ml-8' />
                    My Profile</Link>
                    {/* <Link to={"my-profile"} className='px-2 py-1 hover:bg-slate-100 rounded-md'>
                    <ImProfile className='absolute m-1 -ml-8' />
                    My Wishlist</Link> */}
                    <Link to={"my-orders"} className='px-2 py-1 hover:bg-slate-100 rounded-md'>
                    <FaStore className='absolute m-1 -ml-8' />
                    My Orders</Link>
                    <Link to={"intransit"} className='px-2 py-1 hover:bg-slate-100 rounded-md'>
                    <FaShippingFast className='absolute m-1 -ml-8' />
                    In Transit</Link>
                    <Link to={"shipping-address"} className='px-2 py-1 hover:bg-slate-100 rounded-md'>
                    <MdLocationPin className='absolute m-1 -ml-8'/>Delivery Address</Link>
                    <Link to={"return-and-refund"} className='px-2 py-1 hover:bg-slate-100 rounded-md'>
                    <RiRefund2Fill className='absolute m-1 -ml-8'/>Return & Refund</Link>
                    <button onClick={handleLogout} className='bg-red-600 hover:bg-red-700 text-white px-3 py-1 rounded-full'>Logout</button>
                </nav>
            </div>
        </div>

        
        </div>
        <main className='w-full h-full p-2'>
            {isMobile ? renderMobileView() : <Outlet />}
        </main>
    </div>
  )
}

export default UserProfile
