import React, { useState } from 'react'
import { IoMdArrowDropdown } from "react-icons/io";


const Accounts = () => {
  // State to manage which question is expanded
  const [expandedQuestion, setExpandedQuestion] = useState(null);

  // Function to toggle the expanded question
  const toggleQuestion = (question) => {
    setExpandedQuestion(expandedQuestion === question ? null : question);
  };

  return (
    <div className='bg-white rounded-md w-full h-full p-6 shadow-md'>
        <div className='border border-gray-300 p-4 rounded-md'>
            {/* Question 1 */}
            <div className='border-b border-gray-300 my-3 pb-2'>
                <div className='flex justify-between cursor-pointer' onClick={() => toggleQuestion('register')}>
                    <button className='text-black text-lg font-semibold'>How do I register for an account?</button>
                    <IoMdArrowDropdown className='text-black text-xl' />
                </div>
                {expandedQuestion === 'register' && (
                    <div className='flex flex-col gap-2 p-3 mt-2'>
                        <p>To register for an account, follow these steps:</p>
                        <ul>
                            <li>Go to the registration page.</li>
                            <li>Enter your personal information.</li>
                            <li>Create a secure password.</li>
                            <li>Click on the registration button.</li>
                        </ul>
                    </div>
                )}
            </div>

            {/* Question 2 */}
            <div className='border-b border-gray-300 my-3 pb-2'>
                <div className='flex justify-between cursor-pointer' onClick={() => toggleQuestion('update')}>
                    <button className='text-black text-lg font-semibold'>How can I update my account details?</button>
                    <IoMdArrowDropdown className='text-black text-xl' />
                </div>
                {expandedQuestion === 'update' && (
                    <div className='flex flex-col gap-2 p-3 mt-2'>
                        <p>To update your account details, follow these steps:</p>
                        <ul>
                            <li>Step 1: Access your account and log in.</li>
                            <li>Step 2: Go to the "My Account" page and select "Edit Profile".</li>
                            <li>Step 3: Update your personal information.</li>
                            <li>Step 4: Save the changes.</li>
                        </ul>
                    </div>
                )}
            </div>

            {/* Question 3 */}
            <div className='border-b border-gray-300 my-3 pb-2'>
                <div className='flex justify-between cursor-pointer' onClick={() => toggleQuestion('subscribe')}>
                    <button className='text-black text-lg font-semibold'>How do I subscribe to the newsletter?</button>
                    <IoMdArrowDropdown className='text-black text-xl' />
                </div>
                {expandedQuestion === 'subscribe' && (
                    <div className='flex flex-col gap-2 p-3 mt-2'>
                        <p>To subscribe to the newsletter, follow these steps:</p>
                        <ul>
                            <li>Step 1: Go to the website.</li>
                            <li>Step 2: Scroll to the bottom of the page and look for the "Subscribe to our Newsletter" section.</li>
                            <li>Step 3: Enter your email address in the provided field.</li>
                            <li>Step 4: Click the "Subscribe" button.</li>
                        </ul>
                    </div>
                )}
            </div>

            {/* Question 4 */}
            <div className='border-b border-gray-300 my-3 pb-2'>
                <div className='flex justify-between cursor-pointer' onClick={() => toggleQuestion('changePassword')}>
                    <button className='text-black text-lg font-semibold'>How can I change my password?</button>
                    <IoMdArrowDropdown className='text-black text-xl' />
                </div>
                {expandedQuestion === 'changePassword' && (
                    <div className='flex flex-col gap-2 p-3 mt-2'>
                        <p>To change your password, follow these steps:</p>
                        <ul>
                            <li>Step 1: To begin, visit the website and select the "Sign in" option.</li>
                            <li>Step 2: Click on the "Forgot Password" link.</li>
                            <li>Step 3: Enter your email address and click on the "Reset Password" button.</li>
                            <li>Step 4: Your email will receive a 6-digit verification code.</li>
                            <li>Step 5: Enter the verification code and create a new password.</li>
                            <li>Step 6: Save the changes and you can now log in with your new password.</li>
                        </ul>
                    </div>
                )}
            </div>
        </div>
    </div>
  )
}

export default Accounts
