import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import SummaryApi from '../common';
import noOrdersImage from '../assest/packs.png';
import { useNavigate } from 'react-router-dom';


const MyReturnRequests = () => {
    const [returnRequests, setReturnRequests] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 5; // Set items per page
    const navigate = useNavigate();


    useEffect(() => {
        const fetchReturnRequests = async () => {
            try {
                const response = await fetch(SummaryApi.getReturnRequests.url, {
                    method: SummaryApi.getReturnRequests.method,
                    credentials: 'include',
                });

                const data = await response.json();
                if (data.success) {
                    setReturnRequests(data.returnRequests);
                } else {
                    toast.error(data.message);
                }
            } catch (error) {
                console.error('Error fetching return requests:', error);
                toast.error('Failed to fetch return requests.');
            }
        };

        fetchReturnRequests();
    }, []);

    // Calculate total pages
    const totalPages = Math.ceil(returnRequests.length / itemsPerPage);

    // Get current items for the current page
    const indexOfLastRequest = currentPage * itemsPerPage;
    const indexOfFirstRequest = indexOfLastRequest - itemsPerPage;
    const currentRequests = returnRequests.slice(indexOfFirstRequest, indexOfLastRequest);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    return (
        <div className=' bg-white rounded-lg'>
            <h2>My Return Requests</h2>
            <div>
                {currentRequests.length === 0 ? (
                    <div className="text-center bg-white p-4 rounded-lg items-center flex flex-col justify-center">
                    <img src={noOrdersImage} alt="no orders" className='w-20 h-20 bg-slate-200 rounded-full '/>
                    <p>You have placed no return requests yet!</p>
                    <p>All your return requests will be saved here for you to access their state anytime.</p>
                    <button 
                      onClick={() => navigate('/')} // Navigate to homepage
                      className="mt-4 bg-red-500 text-white px-4 py-2 rounded transition duration-300 ease-in-out transform hover:scale-105 hover:bg-red-700"
                    >
                      Continue Shopping
                    </button>
                  </div>
                ) : (
                    currentRequests.map((request) => (
                <div key={request._id} className='shadow-md border border-gray-200 p-4 rounded-lg mb-4'>
                    <p>Order Number: {request.orderNumber}</p>
                    <p>Reason: {request.reason}</p>
                    <p>Status: {request.status}</p>
                    <h4>Products:</h4>
                    <div className=''>
                        {request.products.map(product => (
                            <div key={product.productId} className='flex border border-gray-200 p-4 rounded-lg mb-4'>
                                <img src={product.productImage} alt={product.productName} className='w-20 h-20 object-cover' />
                                <div>
                                    <p>Name: {product.productName}</p>
                                    <p>Price: {product.sellingPrice}</p>
                                    <p>Quantity: {product.quantity}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                        </div>
                    ))
                )}
            </div>
            {/* Pagination Controls */}
            <div className='flex justify-center mt-4'>
                <button onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1} className='border border-red-700 p-1.5 rounded-md hover:bg-red-600 hover:text-white cursor-pointer'>Previous</button>
                {Array.from({ length: totalPages }, (_, index) => (
                    <button
                        key={index + 1}
                        onClick={() => handlePageChange(index + 1)}
                        className={`border border-red-700 p-1.5 rounded-md mx-1 ${currentPage === index + 1 ? 'bg-red-600 text-white' : 'hover:bg-red-600 hover:text-white'}`}
                    >
                        {index + 1}
                    </button>
                ))}
                <button onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages} className='border border-red-700 p-1.5 rounded-md hover:bg-red-600 hover:text-white cursor-pointer'>Next</button>
            </div>
        </div>
    );
};

export default MyReturnRequests;
