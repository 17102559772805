import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import SummaryApi from '../../common';
import { leapfrog } from 'ldrs'
import { useSelector } from 'react-redux';
import { MdNavigateNext } from "react-icons/md";
import { GrFormPrevious } from "react-icons/gr";

const InTransit = () => {
    const [orders, setOrders] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 5; // Set items per page
    leapfrog.register()
    const user = useSelector(state => state.user);

    const userId = user?.user?._id;

    useEffect(() => {
        const fetchInTransitOrders = async () => {
            try {
                const response = await fetch(`${SummaryApi.viewMyOrder.url}?userId=${userId}&status=InTransit`, {
                    credentials: "include",
                });
                const data = await response.json();
                if (data.success) {
                    setOrders(data.data);
                } else {
                    toast.error(data.message);
                }
            } catch (error) {
                console.error('Error fetching in-transit orders:', error);
                toast.error('Failed to fetch in-transit orders.');
            } finally {
                setLoading(false);
            }
        };

        fetchInTransitOrders();
    }, [userId]);

    // Calculate paginated orders
    const indexOfLastOrder = currentPage * itemsPerPage;
    const indexOfFirstOrder = indexOfLastOrder - itemsPerPage;
    const currentOrders = orders.slice(indexOfFirstOrder, indexOfLastOrder);
    const totalPages = Math.ceil(orders.length / itemsPerPage);

    const handlePageChange = (page) => {
        if (page > 0 && page <= totalPages) {
            setCurrentPage(page);
        }
    };

    if (loading) return <div className='flex justify-center items-center mt-16 lg:-ml-64 lg:mr-40'>
    <l-leapfrog
      size="40"
      speed="1.5" 
      color="black"
    ></l-leapfrog></div>;

    return (
        <div className="mr-9 lg:-ml-64 lg:max-w-7xl px-4 bg-white p-6 max-w-full mt-2">
            <h1 className="text-2xl font-bold mb-4">Orders In Transit</h1>
            {currentOrders.length === 0 ? (
                <p>No orders are currently in transit.</p>
            ) : (
                <div className="flex flex-col md:grid flex-wrap gap-4">
                    {currentOrders.map(order => (
                        <div key={order._id} style={{ backgroundColor: '#f9f9f9' }} className=" rounded-lg p-4 w-full lg:max-w-6xl md:grid ">
                            <h2 className="text-lg font-semibold">Order Number: {order.orderNumber}</h2>
                            <p className="text-gray-600">Status: {order.orderStatus}</p>
                            <h3 className="text-md font-semibold mt-2">Products:</h3>
                            <div className=''>
                              <ul className="list-disc list-inside flex flex-col gap-2">
                                  {order.products.map(product => (
                                      <li key={`${order._id}-${product.productId._id}`} className="flex items-center justify-between border-b-2 border-gray-200 pb-2">
                                          <img 
                                              src={product.productImage} // Assuming productImage is available
                                              alt={product.productName}
                                              className="w-16 h-16 object-cover mr-2 rounded" // Adjust size and styling as needed
                                          />
                                          <div className="flex-grow">
                                              <span className="block">{product.productName}</span>
                                              <span className="text-gray-500">Qty: {product.quantity}</span>
                                          </div>
                                      </li>
                                  ))}
                              </ul>
                            </div>
                            <h3 className="text-md font-semibold mt-2">Delivery Address:</h3>
                            <p>{order.shippingAddress[0].streetAddress}, {order.shippingAddress[0].town}, {order.shippingAddress[0].district}, {order.shippingAddress[0].region}</p>
                        </div>
                    ))}
                </div>
            )}
            {/* Pagination Controls */}
            <div className='flex justify-center mt-4'>
                <button onClick={() => handlePageChange(1)} disabled={currentPage === 1} className='border border-red-700 p-1.5 rounded-md hover:bg-red-600 hover:text-white cursor-pointer'>《</button>
                <button onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1} className='border border-red-700 p-1.5 rounded-md hover:bg-red-600 hover:text-white ml-1 cursor-pointer'><GrFormPrevious size={24} /></button>

                {/* Numbered Pages */}
                {Array.from({ length: totalPages }, (_, index) => (
                    <button
                        key={index + 1}
                        onClick={() => handlePageChange(index + 1)}
                        className={`border border-red-700 p-1.5 rounded-md mx-1 ${currentPage === index + 1 ? 'bg-red-600 text-white' : 'hover:bg-red-600 hover:text-white'}`}
                    >
                        {index + 1}
                    </button>
                ))}

                <button onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages} className='border border-red-700 p-1.5 rounded-md hover:bg-red-600 hover:text-white mr-1 cursor-pointer'><MdNavigateNext size={24} /></button>
                <button onClick={() => handlePageChange(totalPages)} disabled={currentPage === totalPages} className='border border-red-700 p-1.5 rounded-md hover:bg-red-600 hover:text-white cursor-pointer'>》</button>
            </div>
        </div>
    );
};

export default InTransit;
