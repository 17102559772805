import React from 'react'

const Logo = ({w,h}) => {
  return (
<svg version="1.1" viewBox="0 0 2048 857" width={w} height={h} xmlns="http://www.w3.org/2000/svg">
<path transform="translate(1705,91)" d="m0 0 6 3-2 10-6 26-5 31-2 13h119l3 4-1 20h-124l-1 14-13 122-1 15v40l3 31 5 23 6 16 6 9 6 5 6 2 7-1 8-6 7-8 10-17 10-23 10-31 7-29 5-27 5-37 9 2 5 4 1 2v10l-3 25 7-21 8-17 10-16 11-13 11-11 14-10 16-8 15-5 16-3h31l15 3 14 5 12 7 11 9 10 12 8 14 6 17 3 13v5l24-2 12-3h3v23l-13 4-20 3h-4v17l-3 33-4 22-7 24-8 20-8 16-10 16-13 15-10 9-14 9-16 7-17 4-8 1h-17l-14-2-15-5-14-8-10-8-8-8-11-15-8-15-7-19-4-16-2-12-2-33-8 34-9 29-11 27-9 17-10 14-9 10-12 8-9 3-12 1-15-3-12-5-10-6-12-11-9-10-9-14-8-16-7-18-7-28-4-27-2-29v-25l2-34 4-33 6-31 7-26 7-20 2-6h-92l-3-3 1-20 1-1 103-1 13-23 12-17 12-14 15-15 13-10zm209 173-10 5-5 4-9 13-8 17-6 19-6 27-4 26-1 12v20l2 20 5 21 5 12 7 12 9 8 8 3h10l10-4 8-8 7-12 7-21 4-20 3-26 2-43-1-29-3-21-6-18-6-9-6-5-8-3z" fill="#2A2A2A"/>
<path transform="translate(902,229)" d="m0 0h16l13 4 10 5 9 7 8 9 8 15 5 17 3 19 1 17v17l-2 36-5 57-1 29 2 18 4 11 4 5 5 2h8l8-5 3-1 2-4 7-10 9-17 9-24 8-27 8-36 6-35 5-41 3-1 7 2 5 5v14l-6 50-6 40-7 36-8 30-7 20-9 20-9 15-7 9-11 8-13 6-14 3h-18l-17-4-15-8-12-11-9-14-6-16-3-15-1-9v-34l4-38 5-37 2-24v-16l-2-13-4-10-4-6-6-4h-9l-9 6-9 11-10 19-8 22-4 16-3 51-6 113h-70l-3-3v-11l9-170v-30l-3-11-4-7-4-4-10-2-10 3-6 8-5 16-3 26-10 179-1 6h-70l-2-3v-17l14-255 1-11 1-1h68l4 4-1 24 7-12 7-9 5-5 8-7 11-5 8-2h21l12 4 10 6 8 8 6 10 6 14 4 18 12-22 10-14 12-13 12-9 11-5z" fill="#2A2A2A"/>
<path transform="translate(1090,246)" d="m0 0h70l4 4-2 27-8 105-3 43v16l2 15 4 12 5 5 5 2 6-1 6-4 8-14 7-21 6-27 5-30 15-131 1-1h69l4 4-2 34-10 136-2 27v21l2 6 9 1 8-4 9-10 8-13 10-21 8-22 8-29 6-27 5-32 2-18 1-3 9 2 5 5 1 2-1 14-6 42-7 38-10 40-10 30-11 25-8 14-9 12-11 11-14 7-9 2-14-1-11-3-14-7-12-11-9-13-6-13-5-15-7 21-8 17-8 11-7 7-10 5-9 2-14-1-15-4-13-7-9-7-9-9-10-15-7-16-5-19-2-17v-15l18-181z" fill="#2A2A2A"/>
<path transform="translate(210,215)" d="m0 0h13l13 4 11 8 10 14 11 18 11 17 12 19 10 16 5-5 9-6 11-4h17l7 1 6-9 9-8 12-5h17l11 4 5 3 2-4 5-5 8-6 7-3 5-1h10l11 3 8 5 5 4 10 15 12 19 14 22 14 24 11 23 9 24 7 25 4 20 3 24 1 15 1 58 1 8v9l-4 5-4 2-9-1-5-6-2-9-2-73-3-26-5-24-7-24-8-20-11-23-12-20-16-25-12-19-6-8-10-3-7 3-4 5-1 3v8l11 18 13 20 1 6-3 7-4 3-8 1-6-3-15-23-11-17-10-15-7-4h-9l-6 4-4 8 1 9 17 26 13 20 1 7-4 7-4 2h-9l-6-5-28-44-6-9-5-3-9-1-6 3h-2l-2 4-2 6 1 8 11 18 16 25 7 11 1 6-3 7-4 3h-11l-5-5-11-17-70-110-12-19-9-14-6-5-3-1h-11l-6 3-5 5-2 5v11l9 15 11 17 28 44 16 25 70 110v10l-4 5-4 2-9-1-5-5-11-17-14-22-9-14h-3l-11 7-9 7-3 6v13l5 10 13 21 12 19 10 13 12 13 17 13 16 9 18 8 26 8 17 5 16 6 9 6 6 5 10 14 5 9v8l-3 5-7 3-7-1-6-5-9-14-8-7-7-3-13-4-25-8-20-7-16-7-17-10-13-10-13-12-11-12-12-17-15-24-11-17-5-12-1-6v-8l3-12 5-10 8-9 19-13 2-1-2-5-15-24-28-44-12-19-14-22-12-19-4-10-1-4v-14l4-12 6-9 9-8 13-6z" fill="#2A2A2A"/>
<path transform="translate(1431,213)" d="m0 0h19l12 4 11 6 12 11 6 8 4 8 3 12-1 10-4 8-7 6-8 3h-15l-15-5-11-7-12-12-6-10-3-4 2 14 5 13 11 21 20 30 26 36 16 23 13 22 8 16 6 17 4 15 1 7 4-5 9-16 8-19 7-23 7-31 5-33 4-37 1-5 9 2 5 4 1 2v9l-7 55-6 35-6 27-7 24-8 21-10 19-8 11-9 10-9 8-14 8-13 5-14 3-18 1-14-2-18-6-13-7-11-8-10-9-8-8-10-14-9-17-4-13-2-15v-9l3-14 6-8 4-2h5l3 3-1 7-1 4v15l4 12 7 11 8 8 13 8 11 3h11l11-4 4-5 2-7v-8l-4-15-10-24-22-45-14-28-11-26-5-17-2-12v-22l3-15 8-16 9-10 11-7 12-4z" fill="#2A2A2A"/>
<path transform="translate(1556,683)" d="m0 0 19 2 11 4 10 6 5 4 7 10 4 9 3 14v18l-84 1 1 8 4 8 7 6 7 3 12 1 10-3 6-4 5-8 23 3 7 2-3 9-6 9-7 7-10 6-11 4-11 2h-14l-15-3-12-5-8-6-5-5-5-6-5-10-3-10-1-7v-16l3-14 3-8-17 3-8 4-5 6-3 10-1 64h-33v-115h31v16h2l2-4 9-7 12-5 5-1h8l1 24 8-10 11-8 12-5zm-3 24-10 3-8 7-4 9 1 3h50l-1-8-4-6-6-5-9-3z" fill="#2A2A2A"/>
<path transform="translate(2,279)" d="m0 0h39l3 6 6 22 129 1 1 1v7l-14 64-1 4-2 1-91 1 3 11 88 1 8 5 4 8-1 9-4 6-8 4h-7l-6-3-5-6-1-3v-8h-48v8l-3 6-6 5-3 1h-8l-6-3-4-5-2-6 1-10-18-64-14-51-30-1-2-2v-7zm74 130-2 3 1 6 1 1h6l2-2v-6l-2-2zm80 0-2 2v6l2 2h6l2-3-1-6z" fill="#2A2A2A"/>
<path transform="translate(1082,684)" d="m0 0h15l13 3 11 6 10 9 7 10 5 13 2 10v18l-4 17-7 13-6 7-10 7-8 4-11 3h-19l-13-4-10-7-3-3v67h-33v-170l1-1h31l1 13 8-8 10-5zm-3 27-8 2-7 4-5 5-5 8-2 7v14l3 10 7 9 8 5 8 2h8l10-3 8-6 5-7 3-10v-14l-4-11-6-8-6-4-5-2z" fill="#2A2A2A"/>
<path transform="translate(1195,630)" d="m0 0h33l1 67 9-7 9-4 10-2h16l11 3 9 5 7 8 4 9 2 10v82h-33l-1-75-3-8-6-5-3-1h-16l-9 5-5 9-1 4-1 71h-33z" fill="#2A2A2A"/>
<path transform="translate(773,630)" d="m0 0h33l1 67 9-7 9-4 10-2h16l11 3 9 5 7 8 4 9 2 10v82h-33l-1-75-3-8-6-5-3-1h-16l-9 5-5 9-1 4-1 71h-33z" fill="#2A2A2A"/>
<path transform="translate(1369,683)" d="m0 0 19 2 12 4 11 7 8 9 6 12 3 12 1 14-1 7-85 1 4 12 6 8 10 5 12 1 11-3 7-6 4-6 28 4 1 4-6 12-12 12-12 6-11 3-6 1h-15l-15-3-12-5-9-7-8-9-6-12-3-12v-25l5-16 7-11 4-5 13-9 10-4zm-3 24-11 4-5 4-6 12v2h51l1-3-4-9-4-5-8-4-4-1z" fill="#2A2A2A"/>
<path transform="translate(942,684)" d="m0 0h19l15 3 16 8 10 9 6 9 5 12 2 10v17l-3 14-7 14-8 9-10 7-12 5-16 3h-15l-16-3-16-8-8-7-7-8-6-13-2-8-1-16 2-15 5-13 8-11 8-7 16-8zm4 26-11 4-6 5-6 10-2 9v12l4 13 8 9 7 4 4 1h15l8-3 10-9 4-9 1-4v-16l-3-10-7-9-8-5-7-2z" fill="#2A2A2A"/>
<path transform="translate(708,684)" d="m0 0h18l14 3 11 6 8 9 4 10v3l-16 4-10 1-5-8-6-4-4-1h-9l-8 3-2 3 1 7 8 5 25 7 12 5 8 6 6 7 3 7 1 5v11l-4 13-7 8-14 7-15 3h-16l-15-3-14-7-7-7-5-10-1-6 24-7h4l4 8 7 6 12 2 10-2 4-4v-8l-5-5-13-5-20-6-12-6-8-8-4-10v-14l4-11 7-8 14-7z" fill="#2A2A2A"/>
</svg>

  )
}

export default Logo