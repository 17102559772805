import SummaryApi from "../common";

const fetchRecommendations = async (userId) => {
    const response = await fetch(SummaryApi.getPersonalizedRecommendations.url, {
        method: SummaryApi.getPersonalizedRecommendations.method,
        credentials: "include",
        headers: {
            "Content-Type": "application/json",
        }
    });
    const data = await response.json();
    if (!data.success) {
        throw new Error(data.message);
    }
    return data.data; // Return the recommendations
};

export default fetchRecommendations;

