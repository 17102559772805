import React, { useEffect, useState } from 'react';
import axios from 'axios';
import SummaryApi from '../common';

const ProductDetailModal = ({ productId, onClose }) => {
    const [productDetails, setProductDetails] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchProductDetails = async () => {
            try {
                const validProductId = productId; // Replace with the actual ObjectId
                const response = await axios.get(`${SummaryApi.getProductsDetailsHistory.url}?productId=${validProductId}`);
                setProductDetails(response.data.purchases); // Assuming the response contains the purchases array
                setLoading(false);
            } catch (error) {
                console.error("Error fetching product details:", error);
                setLoading(false);
            }
        };

        if (productId) {
            fetchProductDetails();
        }
    }, [productId]);

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <div className="modal">
            <div className="modal-content">
                <span className="close" onClick={onClose}>&times;</span>
                <h2>Product Details</h2>
                <table>
                    <thead>
                        <tr>
                            <th>Date</th>
                            <th>Quantity</th>
                            <th>Total Amount</th>
                        </tr>
                    </thead>
                    <tbody>
                        {productDetails.map((detail, index) => (
                            <tr key={index}>
                                <td>{new Date(detail.purchaseDate).toLocaleDateString()}</td>
                                <td>{detail.quantity}</td>
                                <td>{detail.totalAmount}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default ProductDetailModal;
