import React, { useEffect, useState } from 'react'
import UploadProduct from '../components/UploadProduct'
import SummaryApi from '../common'
import AdminProductCard from '../components/AdminProductCard'
import ProductDetailsModal from '../components/ProductDetailsModal'; // Import the modal component
import { MdNavigateNext } from "react-icons/md";
import { GrFormPrevious } from "react-icons/gr";
import productCategory from '../helpers/productCategory';

const AllProducts = () => {

  const [openUploadProduct, setOpenUploadProduct] = useState(false)
  const [allProduct, setAllProduct] = useState([])
  const [selectedProducts, setSelectedProducts] = useState([]); // State to track selected products
  const [selectedProduct, setSelectedProduct] = useState(null); // State to track the selected product for editing
  const [isModalOpen, setIsModalOpen] = useState(false); // State to manage modal visibility

  // Pagination state
  const [currentPage, setCurrentPage] = useState(1)
  const [itemsPerPage, setItemsPerPage] = useState(10); // Default items per page

  const [searchTerm, setSearchTerm] = useState('');
  const [categoryFilter, setCategoryFilter] = useState('');
  const [sortOption, setSortOption] = useState(''); // State to track sorting option

  const fetchAllProduct = async () => {
    const response = await fetch(SummaryApi.allProduct.url)
    const dataResponse = await response.json()

    setAllProduct(dataResponse?.data || [])
  }

  useEffect(() => {
    fetchAllProduct()
  }, [])

  // Calculate filtered products based on search and category filter
  const filteredProducts = allProduct.filter(product => {
    const matchesSearch = product.productName.toLowerCase().includes(searchTerm.toLowerCase());
    const matchesCategory = categoryFilter ? product.category === categoryFilter : true;
    return matchesSearch && matchesCategory;
  });

  // Sorting logic
  let sortedProducts = filteredProducts;

  if (sortOption === 'priceAsc') {
    sortedProducts = filteredProducts.sort((a, b) => a.sellingPrice - b.sellingPrice);
  } else if (sortOption === 'priceDesc') {
    sortedProducts = filteredProducts.sort((a, b) => b.sellingPrice - a.sellingPrice);
  } else if (sortOption === 'nameAsc') {
    sortedProducts = filteredProducts.sort((a, b) => a.productName.localeCompare(b.productName));
  } else if (sortOption === 'nameDesc') {
    sortedProducts = filteredProducts.sort((a, b) => b.productName.localeCompare(a.productName));
  } else if (sortOption === 'dateAsc') {
    sortedProducts = filteredProducts.sort((a, b) => new Date(a.dateAdded) - new Date(b.dateAdded));
  } else if (sortOption === 'dateDesc') {
    sortedProducts = filteredProducts.sort((a, b) => new Date(b.dateAdded) - new Date(a.dateAdded));
  }

  // Calculate paginated products
  const indexOfLastProduct = currentPage * itemsPerPage
  const indexOfFirstProduct = indexOfLastProduct - itemsPerPage
  const currentProducts = sortedProducts.slice(indexOfFirstProduct, indexOfLastProduct)
  const totalPages = Math.ceil(filteredProducts.length / itemsPerPage); // Calculate total pages based on filtered products

  // Pagination controls
  const handlePageChange = (page) => {
    if (page > 0 && page <= totalPages) {
      setCurrentPage(page)
    }
  }

  // Calculate the range of pages to display
  const getPaginationRange = () => {
    const range = 5; // Number of pages to show
    const start = Math.max(1, currentPage - Math.floor(range / 2));
    const end = Math.min(totalPages, start + range - 1);
    return Array.from({ length: end - start + 1 }, (_, i) => start + i);
  }

  const handleSelectProduct = (id) => {
    setSelectedProducts(prevSelected => {
      if (prevSelected.includes(id)) {
        return prevSelected.filter(productId => productId !== id);
      } else {
        return [...prevSelected, id];
      }
    });
  };

  const handleDeleteSelected = async () => {
    const response = await fetch(SummaryApi.deleteProduct.url, {
      method: SummaryApi.deleteProduct.method,
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ ids: selectedProducts }),
    });

    const responseData = await response.json();
    if (responseData.success) {
      fetchAllProduct(); // Refresh the product list
      setSelectedProducts([]); // Clear selected products
    } else {
      console.error('Failed to delete selected products');
    }
  };

  const handleProductClick = (product) => {
    setSelectedProduct(product); // Set the selected product
    setIsModalOpen(true); // Open the modal
  };

  return (
    <div>
      <div className='bg-white py-2 px-4 flex justify-between items-center'>
        <h2 className='font-bold text-2xl'>All Products</h2>
        {/* Total Products Count */}
        <p className="text-gray-600 mb-2">Total Products: {filteredProducts.length}</p>
        <button className='border-2 border-red-600 text-red-600 hover:bg-red-600 hover:text-white transition-all duration-300 ease-in-out h px-3 py-1 rounded-md' onClick={() => setOpenUploadProduct(true)}>Upload Product</button>
      </div>

      {/* Search and Filter Inputs */}
      <div className="flex mb-4 justify-between items-center bg-white p-4 rounded-md">
        <div>
        <input
          type="text"
          placeholder="Search Products"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="border p-2 mr-2"
        />
        <select
          value={categoryFilter}
          onChange={(e) => setCategoryFilter(e.target.value)}
          className="border p-2"
        >
          <option value="">All Categories</option>
          {productCategory.map((category) => (
            <option key={category.id} value={category.value}>
              {category.label}
            </option>
          ))}
        </select>
        </div>
        {/* Sorting Options */}
        <select
          value={sortOption}
          onChange={(e) => setSortOption(e.target.value)}
          className="border p-2 ml-2"
        >
          <option value="">Sort By</option>
          <option value="priceAsc">Price: Low to High</option>
          <option value="priceDesc">Price: High to Low</option>
          <option value="nameAsc">Name: A-Z</option>
          <option value="nameDesc">Name: Z-A</option>
          <option value="dateAsc">Date: Oldest First</option>
          <option value="dateDesc">Date: Newest First</option>
        </select>
      

        {/* Items per page selection */}
        <div className="flex mb-4 items-center">
          <label className="mr-2">Items per page:</label>
          <select
            value={itemsPerPage}
            onChange={(e) => setItemsPerPage(Number(e.target.value))}
            className="border p-2"
          >
            <option value={10}>10</option>
            <option value={20}>20</option>
            <option value={50}>50</option>
          </select>
        </div>

        {/* Bulk Actions */}
      <div className="flex mb-4">
        <button
          onClick={handleDeleteSelected}
          disabled={selectedProducts.length === 0}
          className="border-2 border-red-600 text-red-600 hover:bg-red-600 hover:text-white transition-all duration-300 ease-in-out h px-3 py-1 rounded-md mr-2"
        >
          Delete Selected
        </button>
        
      </div>

      </div>

      {/* All Products component */}
      <div className='flex items-center flex-wrap gap-5 py-4 h-[calc(100vh-190px)] overflow-y-scroll'>
        {currentProducts.map((product, index) => (
          <AdminProductCard
            data={product}
            key={index + "allProduct"}
            fetchdata={fetchAllProduct}
            isSelected={selectedProducts.includes(product._id)}
            onSelect={handleSelectProduct}
            onClick={() => handleProductClick(product)} // Ensure this is correctly set
          />
        ))}
      </div>

      {/* Pagination Controls */}
      <div className='flex justify-center mt-4'>
        <button onClick={() => handlePageChange(1)} disabled={currentPage === 1} className='border border-red-700 p-1.5 rounded-md hover:bg-red-600 hover:text-white'>First</button>
        <button onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1} className='border border-red-700 p-1.5 rounded-md hover:bg-red-600 hover:text-white ml-1'><GrFormPrevious /></button>

        {/* Numbered Pages */}
        {getPaginationRange().map((page) => (
          <button
            key={page}
            onClick={() => handlePageChange(page)}
            className={`border border-red-700 p-1.5 rounded-md mx-1 ${currentPage === page ? 'bg-red-600 text-white' : 'hover:bg-red-600 hover:text-white'}`}
          >
            {page}
          </button>
        ))}

        <button onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages} className='border border-red-700 p-1.5 rounded-md hover:bg-red-600 hover:text-white mr-1'><MdNavigateNext /></button>
        <button onClick={() => handlePageChange(totalPages)} disabled={currentPage === totalPages} className='border border-red-700 p-1.5 rounded-md hover:bg-red-600 hover:text-white'>Last</button>
      </div>

      {/* Upload Products component */}
      {
        openUploadProduct && (
          <UploadProduct
            onClose={() => {
              setOpenUploadProduct(false);
              setSelectedProduct(null); // Reset selected product
            }}
            fetchData={fetchAllProduct}
            productData={selectedProduct} // Pass product data for editing
          />
        )
      }
    </div>
  )
}

export default AllProducts