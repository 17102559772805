import React, { useState } from 'react'
import { FaMailBulk } from "react-icons/fa";
import { FaFacebook, FaInstagram } from 'react-icons/fa';
import { IoLogoWhatsapp } from "react-icons/io";
import { FaPhoneAlt } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import emailjs from 'emailjs-com'; // Import EmailJS
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';

const ChatUs = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    const templateParams = {
      name,
      email,
      message,
    };

    emailjs.send('service_ee7emjj', 'template_hjczqwe', templateParams, '38MNyUR-CljtUkDCx')
      .then((response) => {
        toast.success('Email sent successfully!');
        setSuccess(true);
        setName('');
        setEmail('');
        setMessage('');
      })
      .catch((err) => {
        console.error('Failed to send email. Error:', err);
        setError('Failed to send message');
      });
  };

  return (
    <div className='container mx-auto p-5'>
      <div className='w-full h-24 bg-red-300 flex justify-center items-center'>
        <h1 className='text-4xl font-bold'>Chat Us</h1>
      </div>

      <div className=' lg:flex grid p-2 gap-6 lg:gap-2'>
        <div className='bg-blue-300 w-full h-full p-2 justify-center items-center rounded-md shadow-md'>
          <h1 className='text-4xl font-bold'>Send Your Message</h1>
          <p className='text-sm text-gray-500'>We will be in touch with you within a day if you complete our form.</p>
          <form onSubmit={handleSubmit} className='w-full h-96 lg:gap-5 p-2 grid  items-center'>
            <label htmlFor="name" className='text-md font-bold -mb-4 text-red-500'>*Name</label>
            <input type="text" required value={name} onChange={(e) => setName(e.target.value)} placeholder='Enter your name' className='w-full h-10 p-2 rounded-md shadow-md focus-within:shadow-2xl outline-none' />
            <label htmlFor="email" className='text-md font-bold -mb-4 text-red-500'>*Email</label>
            <input type="email" required value={email} onChange={(e) => setEmail(e.target.value)} placeholder='Enter your email' className='w-full h-10 p-2 rounded-md shadow-md focus-within:shadow-2xl outline-none' />
            <label htmlFor="message" className='text-md font-bold -mb-4 text-red-500'>*Message</label>
            <textarea required value={message} onChange={(e) => setMessage(e.target.value)} placeholder='Enter your message' className='w-full  lg:h-24 resize-none scrollbar-none p-2 rounded-md shadow-md focus-within:shadow-2xl outline-none' />
            <button type='submit' className='w-36 h-full p-2 rounded-md shadow-md bg-blue-500 text-white'>Send</button>
            {success && <p className='text-white font-'>We will be in touch with you within a day</p>}
            {error && <p className='text-red-500'>{error}</p>}
          </form> 
        </div>

        <div className='bg-white w-full lg:w-2/4 h-full gap-4 grid justify-center items-center rounded-md shadow-md'>
        <div className='w-full h-full grid justify-center items-center text-center p-2' >
          <div className='grid justify-center items-center gap-2'>
            <FaMailBulk className='text-4xl font-bold text-blue-500 ml-10' />
            <h1 className='text-xl font-bold'>Send Us a Mail</h1>
            
          </div>
          <p className='text-sm text-gray-500'>We will be in touch with you within a day if you complete our form.</p>
          <a href="mailto:mustophones9@gmail.com?subject=Message from Musto Phones&body=Hello, I am [Your Name]. I would like to [Your Message]" className='text-sm text-blue-500'>info@musto-phones.com</a>

        </div>


        <div className='w-full h-full grid justify-center gap-2 items-center text-center p-2' >
          <div className='grid justify-center items-center gap-2'>
            <h1 className='text-xl font-bold'>Follow Us</h1>
            
          </div>
          <p className='text-sm text-gray-500'>Follow us on social media to get the latest news and updates.</p>
          <div className='flex justify-center items-center gap-2'>
            <Link to='https://www.facebook.com/p/Musto-Phones-and-Accessories-100065346486646/' target='_blank'><FaFacebook className='text-2xl font-bold text-blue-500 hover:text-red-500 transition duration-300 ease-in-out transform hover:scale-105 cursor-pointer' /></Link>
            <Link to='https://www.instagram.com/mustophones?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw==' target='_blank'><FaInstagram className='text-2xl font-bold text-blue-500 hover:text-red-500 transition duration-300 ease-in-out transform hover:scale-105 cursor-pointer' /></Link>
            <Link to='https://www.twitter.com/musto.phones.9212' target='_blank'><FaXTwitter className='text-2xl font-bold text-blue-500 hover:text-red-500 transition duration-300 ease-in-out transform hover:scale-105 cursor-pointer' /></Link>
            <Link to='https://wa.me/233553105219' target='_blank'><IoLogoWhatsapp className='text-2xl font-bold text-blue-500 hover:text-red-500 transition duration-300 ease-in-out transform hover:scale-105 cursor-pointer' /></Link>
          </div>
        </div>
        <div className='w-full h-full grid justify-center items-center text-center p-2' >
          <div className='flex justify-center items-center gap-2'>
            <FaPhoneAlt className='text-xl font-bold text-blue-500 ml-10' />
            <h1 className='text-xl font-bold'>Call Us</h1>
            
          </div>
          <p className='text-sm text-gray-500'>Call us on the number below to get the latest news and updates.</p>
          <a href="tel:+233553105219" className='text-sm text-gray-500'>+233 55 310 5219</a>
        </div>
          
        </div>
      </div>

    </div>
  )
}

export default ChatUs
