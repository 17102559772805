import React, { useState } from 'react'
import { IoMdArrowDropdown } from "react-icons/io";


const Payment = () => {
  // State to manage which question is expanded
  const [expandedQuestion, setExpandedQuestion] = useState(null);

  // Function to toggle the expanded question
  const toggleQuestion = (question) => {
    setExpandedQuestion(expandedQuestion === question ? null : question);
  };

  return (
    <div className='bg-white rounded-md w-full h-full p-6 shadow-md'>
        <div className='border border-gray-300 p-4 rounded-md'>
            {/* Question 1 */}
            <div className='border-b border-gray-300 my-3 pb-2'>
                <div className='flex justify-between cursor-pointer' onClick={() => toggleQuestion('payment')}>
                    <button className='text-black text-lg font-semibold'>How do I make a payment?</button>
                    <IoMdArrowDropdown className='text-black text-xl' />
                </div>
                {expandedQuestion === 'payment' && (
                    <div className='flex flex-col gap-2 p-3 mt-2'>
                        <p>To make a payment, follow these steps:</p>
                        <ul>
                            <li>Go to the payment page.</li>
                            <li>Enter your personal information.</li>
                            <li>Click on the payment button.</li>
                        </ul>
                    </div>
                )}
            </div>

            {/* Question 2 */}
            <div className='border-b border-gray-300 my-3 pb-2'>
                <div className='flex justify-between cursor-pointer' onClick={() => toggleQuestion('updatePaymentDetails')}>
                    <button className='text-black text-lg font-semibold'>How can I update my payment details?</button>
                    <IoMdArrowDropdown className='text-black text-xl' />
                </div>
                {expandedQuestion === 'updatePaymentDetails' && (
                    <div className='flex flex-col gap-2 p-3 mt-2'>
                        <p>To update your payment details, follow these steps:</p>
                        <ul>
                            <li>Step 1: Access your account and log in.</li>
                            <li>Step 2: Go to the "My Account" page and select "Edit Payment Details".</li>
                            <li>Step 3: Update your payment information.</li>
                            <li>Step 4: Save the changes.</li>
                        </ul>
                    </div>
                )}
            </div>

            {/* Question 3 */}
            <div className='border-b border-gray-300 my-3 pb-2'>
                <div className='flex justify-between cursor-pointer' onClick={() => toggleQuestion('availablePayment')}>
                    <button className='text-black text-lg font-semibold'>Which forms of payment are available on this website?</button>
                    <IoMdArrowDropdown className='text-black text-xl' />
                </div>
                {expandedQuestion === 'availablePayment' && (
                    <div className='flex flex-col gap-2 p-3 mt-2'>
                        <p>The following forms of payment are available on this website:</p>
                        <ul>
                            <li>Credit Card</li>
                            <li>Debit Card</li>
                            <li>Mobile Money</li>
                            <li>Bank Transfer</li>
                        </ul>
                    </div>
                )}
            </div>

            {/* Question 4 */}
            <div className='border-b border-gray-300 my-3 pb-2'>
                <div className='flex justify-between cursor-pointer' onClick={() => toggleQuestion('paymentProcessingTime')}>
                    <button className='text-black text-lg font-semibold'>How long does it take to process my payment?</button>
                    <IoMdArrowDropdown className='text-black text-xl' />
                </div>
                {expandedQuestion === 'paymentProcessingTime' && (
                    <div className='flex flex-col gap-2 p-3 mt-2'>
                        <p>The time it takes to process a payment depends on the type of payment method you are using:</p>
                        <p>To ensure quick processing, double-check all payment details before finalizing your order</p>
                        <p>If you encounter any issues with your payment, feel free to contact our customer service team for assistance.</p>
                        
                    </div>
                )}
            </div>

            {/* Question 5 */}
            <div className='border-b border-gray-300 my-3 pb-2'>
                <div className='flex justify-between cursor-pointer' onClick={() => toggleQuestion('paymentNotProcessed')}>
                    <button className='text-black text-lg font-semibold'>What should I do if my payment is not processed?</button>
                    <IoMdArrowDropdown className='text-black text-xl' />
                </div>
                {expandedQuestion === 'paymentNotProcessed' && (
                    <div className='flex flex-col gap-2 p-3 mt-2'>
                        <p>If your payment is not processed, follow these steps:</p>
                        <ul>
                            <li>Step 1: Verify your payment details.</li>
                            <li>Step 2: Check your account balance.</li>
                            <li>Step 3: Contact our customer service team for assistance.</li>
                        </ul>
                        
                    </div>
                )}
            </div>

            {/* Question 6 */}
            <div className='border-b border-gray-300 my-3 pb-2'>
                <div className='flex justify-between cursor-pointer' onClick={() => toggleQuestion('paymentStatus')}>
                    <button className='text-black text-lg font-semibold'>How can I check if the payment that I made was accepted?</button>
                    <IoMdArrowDropdown className='text-black text-xl' />
                </div>
                {expandedQuestion === 'paymentStatus' && (
                    <div className='flex flex-col gap-2 p-3 mt-2'>
                        <p>To check if your payment was accepted, follow these steps:</p>
                        <ul>
                            <li>Step 1: Go to the "Orders" section in your account</li>
                            <li>Step 2: Locate the order and check its status. If the payment was successful, the status will show "completed."</li>
                            <li>Step 3: If you have any concerns, contact our customer service team for assistance.</li>
                        </ul>
                        
                    </div>
                )}
            </div>


        </div>
    </div>
  )
}

export default Payment
