import React from 'react'
import { useNavigate } from 'react-router-dom'
import support from '../../assest/conversation.gif'
import { FaMailBulk, FaFacebook, FaInstagram,  FaPhoneAlt } from 'react-icons/fa'
import { IoLogoWhatsapp } from 'react-icons/io'
import { Link } from 'react-router-dom'
import { FaXTwitter } from "react-icons/fa6";

const ContactUs = () => {
  const navigate = useNavigate()
  return (
    <div className='container mx-auto p-4'>
        <div className='bg-red-500 h-80 text-lg p-10 flex gap-10 rounded-tl-3xl rounded-br-3xl shadow-lg'>
            <div className='flex flex-col gap-2'>
                <h1 className='text-white text-4xl font-extrabold '>Contact Us</h1>
                <h1 className='text-white text-xl font-bold '>Looking to reach out? We'd be happy to hear from you. Here’s how you can get in touch with us.</h1>
            </div>
            <div className=' text-lg '>
                <img src={support} alt='support' className='border-2 hidden lg:block border-red-500 h-56 w-70 rounded-tl-3xl rounded-br-3xl shadow-lg' />
            </div>
        </div>

        <div className='lg:w-full mx-auto lg:flex gap-10 -mt-10 px-6'>
        <div className='bg-white w-full lg:w-2/4 h-full gap-4 grid justify-center items-center rounded-tl-3xl rounded-br-3xl shadow-lg'>
        <div className='w-full h-full grid justify-center items-center text-center p-2' >
          <div className='grid justify-center items-center gap-2'>
            <FaMailBulk className='text-4xl font-bold text-blue-500 ml-10' />
            <h1 className='text-xl font-bold'>Send Us a Mail</h1>
            
          </div>
          <p className='text-sm text-gray-500'>We will be in touch with you within a day if you complete our form.</p>
          <a href="mailto:mustophones9@gmail.com?subject=Message from Musto Phones&body=Hello, I am [Your Name]. I would like to [Your Message]" className='text-sm text-blue-500'>info@musto-phones.com</a>

        </div>


        <div className='w-full h-full grid justify-center gap-2 items-center text-center p-2' >
          <div className='grid justify-center items-center gap-2'>
            <h1 className='text-xl font-bold'>Follow Us</h1>
            
          </div>
          <p className='text-sm text-gray-500'>Follow us on social media to get the latest news and updates.</p>
          <div className='flex justify-center items-center gap-2'>
            <Link to='https://www.facebook.com/p/Musto-Phones-and-Accessories-100065346486646/' target='_blank'><FaFacebook className='text-2xl font-bold text-blue-500 hover:text-red-500 transition duration-300 ease-in-out transform hover:scale-105 cursor-pointer' /></Link>
            <Link to='https://www.instagram.com/mustophones?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw==' target='_blank'><FaInstagram className='text-2xl font-bold text-blue-500 hover:text-red-500 transition duration-300 ease-in-out transform hover:scale-105 cursor-pointer' /></Link>
            <Link to='https://www.twitter.com/musto.phones.9212' target='_blank'><FaXTwitter className='text-2xl font-bold text-blue-500 hover:text-red-500 transition duration-300 ease-in-out transform hover:scale-105 cursor-pointer' /></Link>
            <Link to='https://wa.me/233553105219' target='_blank'><IoLogoWhatsapp className='text-2xl font-bold text-blue-500 hover:text-red-500 transition duration-300 ease-in-out transform hover:scale-105 cursor-pointer' /></Link>
          </div>
        </div>
        <div className='w-full h-full grid justify-center items-center text-center p-2' >
          <div className='flex justify-center items-center gap-2'>
            <FaPhoneAlt className='text-xl font-bold text-blue-500 ml-10' />
            <h1 className='text-xl font-bold'>Call Us</h1>
            
          </div>
          <p className='text-sm text-gray-500'>Call us on the number below to get the latest news and updates.</p>
          <a href="tel:+233553105219" className='text-sm text-gray-500'>+233 55 310 5219</a>
        </div>
          
        </div>
            <div className='bg-white gap-4 mt-2 p-10 lg:flex justify-between items-center text-center text-lg rounded-tl-3xl rounded-br-3xl shadow-lg lg:w-full mx-auto'> 
                <div className='flex flex-col gap-2 w-full'>
                    <h1 className='text-black text-sm '>Do not hesitate to connect with us if you need help or have questions. We are open Monday through Friday from 8 a.m. to 8 p.m. And on Saturday and Sunday from 9 a.m. to 8 p.m.</h1>
                    <h1 className='text-black text-sm '>We can also be reached at +233 55 310 5219, Monday through Friday, 8 a.m. to 5 p.m., and on holidays, 9 a.m. to 5 p.m.</h1>
                    <h1 className='text-black text-sm '>You can also send us an email at info@musto-phones.com</h1>
                    <div>
                        <button onClick={() => navigate('/chat-us')} className='bg-red-500 hover:bg-red-600 text-white px-4 py-2 rounded-tl-3xl rounded-br-3xl hover:scale-105 transition duration-300 ease-in-out transform'>Chat With Us</button>
                    </div>
                </div>



            </div>
        </div>
        <div className='lg:w-full mx-auto lg:flex gap-10 px-6'>
            <div className='bg-white gap-4 mt-2 p-10 lg:flex justify-between items-center text-center text-lg rounded-tl-3xl rounded-br-3xl shadow-lg lg:w-full mx-auto'> 
                <div className='flex flex-col gap-2 border-2 border-red-500 h-full'>
                    <h1 className='text-black text-sm'>Find us at the University of Cape Coast, Cape Coast, Ghana</h1>
                </div>
                <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15895.93400072082!2d-1.2964325!3d5.1063594!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xfddf94a60e2ac17%3A0xe46cbe8c61a88d67!2sMUSTOPHONES%20AND%20ACCESSORIES!5e0!3m2!1sen!2sgh!4v1729401309832!5m2!1sen!2sgh" width="100%" height="450" style={{border:0}} allowfullscreen="" loading="lazy" title='Musto-Phones' referrerpolicy="no-referrer-when-downgrade"></iframe>
                
            </div>
            
        </div>
    </div>
  )
}

export default ContactUs