import axios from 'axios';
import SummaryApi from '../common';

export const addPromoCode = async (promoCodeData) => {
    const response = await fetch(SummaryApi.addPromoCode.url, {
        method : SummaryApi.addPromoCode.method,
        credentials : "include",
        headers: {
            'Content-Type': 'application/json',
        },body : JSON.stringify(promoCodeData)
    });
    return response.json();
};