import React, { useState } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { addPromoCode } from '../helpers/promoCodes'; // API call to add promo code

const AddPromoCode = () => {
    const [code, setCode] = useState('');
    const [discount, setDiscount] = useState('');
    const [expirationDate, setExpirationDate] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await addPromoCode({ code, discount, expirationDate });
            if (response.success) {
                toast.success('Promo code added successfully!');
                setCode('');
                setDiscount('');
                setExpirationDate('');
            } else {
                toast.error(response.message);
            }
        } catch (error) {
            toast.error('Error adding promo code');
        }
    };

    return (
        <div className="container mx-auto p-4">
            <ToastContainer />
            <h1 className="text-2xl font-bold mb-4">Add Promo Code</h1>
            <form onSubmit={handleSubmit}>
                <div className="mb-4">
                    <label className="block mb-2">Promo Code</label>
                    <input
                        type="text"
                        value={code}
                        onChange={(e) => setCode(e.target.value)}
                        className="border p-2 w-full"
                        required
                    />
                </div>
                <div className="mb-4">
                    <label className="block mb-2">Discount (%)</label>
                    <input
                        type="number"
                        value={discount}
                        onChange={(e) => setDiscount(e.target.value)}
                        className="border p-2 w-full"
                        required
                    />
                </div>
                <div className="mb-4">
                    <label className="block mb-2">Expiration Date</label>
                    <input
                        type="date"
                        value={expirationDate}
                        onChange={(e) => setExpirationDate(e.target.value)}
                        className="border p-2 w-full"
                        required
                    />
                </div>
                <button type="submit" className="bg-blue-600 hover:bg-blue-700 text-white p-2 rounded">
                    Add Promo Code
                </button>
            </form>
        </div>
    );
};

export default AddPromoCode;