import React from 'react'
import watchbanner from '../../assest/category/watchanime.gif'

const WatchesBanner = () => {
  return (
    <div className='container mx-auto min-h-[550px] lg:-mt-20 lg:-mb-20 flex justify-center items-center'>
        <div style={{background: '#35e0fe'}} className='rounded-lg shadow-md'>
            <div className='grid grid-cols-1 lg:grid-cols-3 gap-6 items-center text-white'>
                <div className='p-8 lg:p-6'>
                    <p data-aos="slide-right" className='text-xl font-bold'>100% Satisfaction Guaranteed</p>
                    <h1 data-aos="fade-up" className='uppercase text-4xl font-bold'>Watches</h1>
                    
                </div>
                <div data-aos="zoom-in" className='h-full flex items-center '>
                    <img src={watchbanner} alt="watchbanner" className='w-[250px] md:w-[340px] object-cover scale-125 mx-auto drop-shadow-2xl'/>
                </div>
                <div className='flex flex-col gap-4 p-8 lg:p-6'>
                    <p data-aos="fade-down" className='text-xl font-bold'>boAt Wave Beat 4</p>
                    <p data-aos="fade-up" className='text-5xl lg:text-3xl font-bold'>Back To School Offer</p>
                    <p data-aos="fade-up" className='text-sm tracking-wide leading-5'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam, quos.</p>
                
                    <div data-aos="fade-up" className='flex justify-center'>
                        <button style={{color: '#35e0fe'}} className='bg-white font-bold hover:text-red-700 transition-all duration-300 hover:scale-105 px-4 py-2 rounded-md'>Shop Now</button>
                    </div>
                </div>

            </div>
        </div>
    </div>
  )
}

export default WatchesBanner