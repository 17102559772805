import React, { useState } from 'react'
import { IoMdArrowDropdown } from "react-icons/io";


const Products = () => {
  // State to manage which question is expanded
  const [expandedQuestion, setExpandedQuestion] = useState(null);

  // Function to toggle the expanded question
  const toggleQuestion = (question) => {
    setExpandedQuestion(expandedQuestion === question ? null : question);
  };

  return (
    <div className='bg-white rounded-md w-full h-full p-6 shadow-md'>
        <div className='border border-gray-300 p-4 rounded-md'>
            {/* Question 1 */}
            <div className='border-b border-gray-300 my-3 pb-2'>
                <div className='flex justify-between cursor-pointer' onClick={() => toggleQuestion('payment')}>
                    <button className='text-black text-lg font-semibold'>What types of products does Musto offer?</button>
                    <IoMdArrowDropdown className='text-black text-xl' />
                </div>
                {expandedQuestion === 'payment' && (
                    <div className='flex flex-col gap-2 p-3 mt-2'>
                        <p>Musto is Ghana's top online store, offering a wide range of products across various categories.</p>
                        <p>Their selection includes 'airpods & cases', 'camera', 'earphones & headphones', 'mobiles', 'mouse & keyboard', 'phone cases', 'power banks', 'printers', 'processors', 'refrigerators', 'speakers', 'television', 'trimmers', 'watches', Musto provides something for everyone.</p>

                    </div>
                )}
            </div>

            {/* Question 2 */}
            <div className='border-b border-gray-300 my-3 pb-2'>
                <div className='flex justify-between cursor-pointer' onClick={() => toggleQuestion('updatePaymentDetails')}>
                    <button className='text-black text-lg font-semibold'>How can I view the specifications and features of the product?</button>
                    <IoMdArrowDropdown className='text-black text-xl' />
                </div>
                {expandedQuestion === 'updatePaymentDetails' && (
                    <div className='flex flex-col gap-2 p-3 mt-2'>
                        <p>To view the specifications and features of a product, follow these steps:</p>
                        <ul>
                            <li>Step 1: Access the product page.</li>
                            <li>Step 2: Scroll down to view the product details.</li>
                            <li>Step 3: Check the specifications and features.</li>
                        </ul>
                    </div>
                )}
            </div>

            {/* Question 3 */}
            <div className='border-b border-gray-300 my-3 pb-2'>
                <div className='flex justify-between cursor-pointer' onClick={() => toggleQuestion('availablePayment')}>
                    <button className='text-black text-lg font-semibold'>How can I submit a product review or offer feedback?</button>
                    <IoMdArrowDropdown className='text-black text-xl' />
                </div>
                {expandedQuestion === 'availablePayment' && (
                    <div className='flex flex-col gap-2 p-3 mt-2'>
                        <p>To submit a product review or offer feedback, follow these steps:</p>
                        <ul>
                            <li>Step 1: Access the product page.</li>
                            <li>Step 2: Scroll down to view the product details.</li>
                            <li>Step 3: Check the specifications and features.</li>
                            <li>Step 4: Submit your review or feedback.</li>
                        </ul>
                        
                    </div>
                )}
            </div>

            {/* Question 4 */}
            <div className='border-b border-gray-300 my-3 pb-2'>
                <div className='flex justify-between cursor-pointer' onClick={() => toggleQuestion('paymentProcessingTime')}>
                    <button className='text-black text-lg font-semibold'>I'm experiencing issues adding items to my shopping cart. How should I proceed?</button>
                    <IoMdArrowDropdown className='text-black text-xl' />
                </div>
                {expandedQuestion === 'paymentProcessingTime' && (
                    <div className='flex flex-col gap-2 p-3 mt-2'>
                        <p>If you're experiencing issues adding items to your shopping cart, follow these steps:</p>
                        <ul>
                            <li>Step 1: Verify your internet connection.</li>
                            <li>Step 2: Clear your browser cache.</li>
                            <li>Step 3: Restart your device.</li>
                            <li>Step 4: Try adding the item again.</li>
                        </ul>
                        <p>If the issue persists, contact our customer service team for further assistance.</p>
                        
                    </div>
                )}
            </div>

            {/* Question 5 */}
            <div className='border-b border-gray-300 my-3 pb-2'>
                <div className='flex justify-between cursor-pointer' onClick={() => toggleQuestion('paymentNotProcessed')}>
                    <button className='text-black text-lg font-semibold'>How can I find out if a product is available?</button>
                    <IoMdArrowDropdown className='text-black text-xl' />
                </div>
                {expandedQuestion === 'paymentNotProcessed' && (
                    <div className='flex flex-col gap-2 p-3 mt-2'>
                        <p>To find out if a product is available, follow these steps:</p>
                        <ul>
                            <li>Step 1: Access the product page.</li>
                            <li>Step 2: Check the product availability.</li>
                            <li>Step 3: If the product is available, add it to your cart.</li>
                        </ul>
                        
                    </div>
                )}
            </div>

            {/* Question 6 */}
            <div className='border-b border-gray-300 my-3 pb-2'>
                <div className='flex justify-between cursor-pointer' onClick={() => toggleQuestion('paymentStatus')}>
                    <button className='text-black text-lg font-semibold'>How can I check if the payment that I made was accepted?</button>
                    <IoMdArrowDropdown className='text-black text-xl' />
                </div>
                {expandedQuestion === 'paymentStatus' && (
                    <div className='flex flex-col gap-2 p-3 mt-2'>
                        <p>To check if your payment was accepted, follow these steps:</p>
                        <ul>
                            <li>Step 1: Go to the "Orders" section in your account</li>
                            <li>Step 2: Locate the order and check its status. If the payment was successful, the status will show "completed."</li>
                            <li>Step 3: If you have any concerns, contact our customer service team for assistance.</li>
                        </ul>
                        
                    </div>
                )}
            </div>


        </div>
    </div>
  )
}

export default Products
