import React from 'react';
import CountdownTimer from './CountdownTimer';
import { useNavigate } from 'react-router-dom';
import PromotionProducts from './PromotionProducts';

const CountDown = ({ promotedProducts }) => {
  const navigate = useNavigate();

  // Calculate the earliest end date
  const getEarliestEndDate = () => {
    if (promotedProducts?.length === 0) return null;
    return promotedProducts?.reduce((earliest, product) => {
      const endDate = new Date(product.promotion.endDate);
      return endDate < earliest ? endDate : earliest;
    }, new Date(promotedProducts[0]?.promotion?.endDate));
  };

  const earliestEndDate = getEarliestEndDate();

  // Check if the sale has ended
  if (earliestEndDate && new Date() >= earliestEndDate) {
    return null; // Hide the entire component when the sale has ended
  }

  // Function to get the highest promotion value
  const getHighestPromotionValue = () => {
    if (promotedProducts?.length === 0) return 0; // Return 0 if there are no promoted products
    return Math.max(...promotedProducts.map(product => product.promotion.value));
  };

  return (
    <div style={{ backgroundColor: '#f9f9f9' }} className='h-full relative mx-auto px-4 my-4'>
      <div className='grid md:flex md:flex-row lg:flex lg:flex-row justify-between'>
        <div className='bg-red-500 w-80 lg:w-60 lg:h-80 p-4 rounded-md my-2 shadow-md'>
          <div className='justify-between items-center justify-items-center gap-2 md:gap-5 lg:gap-5 grid'>
            <h1 className='text-4xl font-bold text-white hidden md:block lg:block'>Flash Sale</h1>
            <p className='text-white text-2xl md:block lg:block'>Up to {getHighestPromotionValue()}% Off</p>
            <p className='text-white text-2xl hidden md:block lg:block'>Ends in</p>
            <div className='flex flex-col gap-2'> 
              {earliestEndDate && (
                <div className="product mr-2">
                  <CountdownTimer endDate={earliestEndDate} />
                </div>
              )}
            </div>
            <button onClick={() => navigate('/all-promotion-products')} className='text-white w-full bg-blue-500 rounded-md p-2 hidden md:block lg:block mr-4 hover:bg-blue-600 hover:shadow-md'>View All</button>
          </div>
        </div>
        <div className='w-10/12 h-80 p-4 lg:p-0 my-2 lg:mr-96'>
          <div className='lg:w-full w-96 sm:mr-96'>
            {/* Pass the entire promotedProducts array to PromotionProducts */}
            <PromotionProducts category={promotedProducts[0]?.category} heading={promotedProducts[0]?.promotion.heading} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CountDown;
