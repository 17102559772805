import React, { useEffect, useState } from 'react';
import fetchRecommendations from '../helpers/fetchRecommendations';
import VerticalCardRecomendation from './VerticalCardRecomendation'; // Assume you have a ProductCard component

const Recommendations = ({ userId }) => {
    const [recommendations, setRecommendations] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const getRecommendations = async () => {
            try {
                const data = await fetchRecommendations(userId);
                setRecommendations(data);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        getRecommendations();
    }, [userId]);

    if (loading) return <p>Loading recommendations...</p>;
    if (error) return <p>Error: {error}</p>;

    return (
        <div className="recommendations">
            <h2>Personalized Recommendations</h2>
            <div className="grid grid-cols-3 gap-4">
                {recommendations.map(product => (
                    <VerticalCardRecomendation key={product._id} product={product} />
                ))}
            </div>
        </div>
    );
};

export default Recommendations;

