import React, { useState } from 'react'
import loginIcons from '../assest/signin.gif'
import { FaEye } from "react-icons/fa";
import { FaEyeSlash } from "react-icons/fa";
import { Link, useNavigate } from 'react-router-dom';
import imageTobase64 from '../helpers/imageTobase64';
import SummaryApi from '../common';
import { toast } from 'react-toastify';
import '../components/test/signupstyle.css'

const SignUp = () => {
  
    const [showPassword, setShowPassword] = useState(true);
    const [showConfirmPassword, setShowConfirmPassword] = useState(true);
    const [data, setData] = useState({
        email: "",
        number: "",
        password: "",
        name: "",
        confirmPassword: "",
        profilePic : ""
    })
    const navigate = useNavigate()

    const handleOnChange = (e) => {
        const {name, value} = e.target

        setData((preve)=>{
            return{
                ...preve,
                [name]: value
            }
        })
    }

    const handleUploadPic = async(e) => {
        const file = e.target.files[0]

        const imagePic = await imageTobase64(file)

        setData((preve)=>{
            return{
                ...preve,
                profilePic : imagePic
            }
        })
    }

    const handleSubmit = async(e) => {
        e.preventDefault()

        if(data.password === data.confirmPassword){
            const dataResponse = await fetch(SummaryApi.signUp.url, {
                method : SummaryApi.signUp.method,
                headers : {
                    "content-type" : "application/json"
                },
                body : JSON.stringify(data)
            })

            const dataApi = await dataResponse.json()

            if(dataApi.success){
                localStorage.setItem('userEmail', data.email);
                toast.success("Verification email sent! Please check your inbox.")
                navigate('/verify-otp') 
            }

            if(dataApi.error){
                toast.error(dataApi.message)
            }

        }else{
            toast.error("Password and Confirm Password do not match")
        }

    }


  return (
    <div id='bg' className='w-full h-full py-10'>
   <section id='signup' className=' shadow-lg'>
    <div className='mx-auto container p-4'>
        <div className=''>
        <div className=''>
            {/* <div className='w-20 h-20 mx-auto relative overflow-hidden rounded-full'>
                <div>
                    <img src={data.profilePic || loginIcons} alt='login icons'/>
                </div>
                <form>
                    <label>
                        <div className='text-xs bg-opacity-80 bg-slate-200 pb-4 pt-2 text-center cursor-pointer absolute bottom-0 left-0        w-full'>
                         Upload Photo
                        </div>
                        <input type='file' name='profilePic' onChange={handleUploadPic} className='hidden'/>
                    </label>
                    
                </form>
            </div> */}
            <p className='text-center text-white text-4xl font-bold'>Sign Up</p>
            <form className='' onSubmit={handleSubmit}>
                <div className='grid'>
                    
                    <div className='inputbox'>
                        <input type='text' name='name' value={data.name} onChange={handleOnChange} required /><label>Name :</label>
                    </div>
                </div>
                <div className='grid'>
                <div className="inputbox">
                                <input
                        type='email'
                        required
                        name='email'
                        value={data.email}
                        onChange={handleOnChange}
                    />
                    <label>Email</label>
                </div>
                </div>
                <div className='grid'>
                    
                    <div className='inputbox'>
                        <input type='text'  name='number' value={data.number} onChange={handleOnChange} required /><label>Phone Number :</label>
                    </div>
                </div>

                <div>
                    
                    <div className='inputbox flex mt-6'>
                        <input type={showPassword ? 'text' : 'password'}  name='password' value={data.password} onChange={handleOnChange} required /><label>Password :</label>
                        <div className='cursor-pointer text-xl flex items-center justify-center text-white w-10 h-10 right-0 absolute ' onClick={() => setShowPassword((prev) => !prev)}>
                            <span>
                                {
                                    showPassword ? (
                                    <FaEye/>
                                ) 
                                :
                                ( 
                                    <FaEyeSlash/>
                                )
                                }
                            
                            </span>
                        </div>
                    </div>
                </div>
                <div>
                    
                    <div className='inputbox flex mt-6'>
                        <input type={showConfirmPassword ? 'text' : 'password'} name='confirmPassword' value={data.confirmPassword} onChange={handleOnChange} required /><label>Confirm Password :</label>
                        <div className='cursor-pointer text-xl flex items-center justify-center text-white w-10 h-10 right-0  ' onClick={() => setShowConfirmPassword((prev) => !prev)}>
                            <span>
                                {
                                    showConfirmPassword ? (
                                    <FaEye/>
                                ) 
                                :
                                ( 
                                    <FaEyeSlash/>
                                )
                                }
                            
                            </span>
                        </div>
                    </div>
                </div>

                <button className='bg-red-600 hover:bg-red-700 text-white px-6 py-2 w-full max-w-[150px] rounded-full hover:scale-110 transition-all mx-auto block m-1'>Sign Up</button>
            </form>
       

            <p className='mt-5 text-white'>Already have an account ? <Link to={'/login'} className='text-white font-bold hover:underline  '>Login</Link></p>
        </div>   
            
        </div>
    </div>
   </section>
   </div>
  )
}

export default SignUp